"use client";

import { Link } from "@natera/navigation";
import classnames from "classnames";
import * as React from "react";
import { NavLinkProps, useMatch } from "react-router-dom";
import { IconProps } from "@natera/material/lib/icon";
import { TabItem } from "./";

export interface TabLinkProps extends NavLinkProps {
  icon?: React.ReactNode;
  materialIcon?: string | IconProps;
  className?: string;
  to: string;
  exact?: boolean;
  children?: React.ReactNode;
}

const TabLink: React.FunctionComponent<TabLinkProps> = ({
  className,
  to,
  icon,
  materialIcon,
  exact = true,
  children,
  ...rest
}) => {
  const match = useMatch({
    path: to,
    end: exact,
  });

  return (
    <Link {...rest} to={to} className="link-tab" tabIndex={-1}>
      <TabItem
        icon={icon}
        materialIcon={!icon ? materialIcon : undefined}
        active={Boolean(match)}
        className={classnames("tab-link", className)}
      >
        {children}
      </TabItem>
    </Link>
  );
};

export default TabLink;
