import R from "ramda";
import * as React from "react";
import { BehaviorSubject, Observable } from "rxjs";
import { withLatestFrom } from "rxjs/operators";
import { RecordIndex, StorageController, useStorage } from "../useStorage";

export interface SerializedRecords {
  [key: string]: string | string[] | undefined;
}

export interface Proxy<T> {
  load: () => T;
  save: (data: T) => void;
  asObservable: () => Observable<T>;
}

export interface ProxyProps<T> {
  serialize: (record: T) => SerializedRecords;
  deserialize: (data: SerializedRecords) => T;
}

interface StorageProxyProps<T> {
  initialRecords?: T[];
  recordIndex?: RecordIndex<T>;
  serialize: (record: T[]) => SerializedRecords;
  deserialize: (data: SerializedRecords) => T[];
  proxy: (proxyProps: ProxyProps<T[]>) => Proxy<T[]>;
}

export const useStorageProxy = <T>({
  initialRecords = [],
  recordIndex,
  serialize,
  deserialize,
  proxy: useProxy,
}: StorageProxyProps<T>): StorageController<T> => {
  const settingsStorage = useStorage<T>({
    recordIndex,
  });

  const proxy = useProxy({
    serialize,
    deserialize,
  });

  const storageSubject = React.useMemo(
    () => new BehaviorSubject(settingsStorage),
    [],
  );

  React.useLayoutEffect(() => {
    const proxyRecords = proxy.load();
    settingsStorage.addRecords([...initialRecords, ...proxyRecords]);
  }, []);

  React.useLayoutEffect(() => {
    storageSubject.next(settingsStorage);
  }, [settingsStorage]);

  React.useLayoutEffect(() => {
    const proxySubscription = proxy
      .asObservable()
      .pipe(withLatestFrom(storageSubject))
      .subscribe(([proxyRecords, storage$]) => {
        const records = storage$.getRecords();
        const diff = R.symmetricDifference(proxyRecords, records);

        if (
          !(R.length(proxyRecords) === R.length(records) && R.isEmpty(diff))
        ) {
          const recordsToAdd = R.difference(proxyRecords, records);

          const storageIndexes = records.map(storage$.recordIndex);
          const proxyIndexes = proxyRecords.map(storage$.recordIndex);
          const indexesToRemove = R.difference(storageIndexes, proxyIndexes);

          if (!R.isEmpty(recordsToAdd)) {
            storage$.addRecords(recordsToAdd);
          }

          if (!R.isEmpty(indexesToRemove)) {
            storage$.removeRecords(indexesToRemove);
          }
        }
      });

    const recordsSubscription = storageSubject.subscribe((storage) =>
      proxy.save(storage.getRecords()),
    );

    return () => {
      proxySubscription.unsubscribe();
      recordsSubscription.unsubscribe();
    };
  }, []);

  return React.useMemo<StorageController<T>>(
    () => settingsStorage,
    [settingsStorage.getRecords],
  );
};
