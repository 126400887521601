"use client";

import {
  Corner,
  MenuController,
  MenuWrapper,
  MenuWidth,
} from "@natera/material/lib/menu";
import classnames from "classnames";
import * as R from "ramda";
import * as React from "react";
import { getTypeAheadContext } from "@natera/form";

import "./typeAhead.scss";

export interface TypeAheadMenuProps
  extends Omit<React.HTMLProps<HTMLDivElement>, "children" | "ref"> {
  className?: string;
  anchorClassName?: string;
  anchorElement: (controller: MenuController) => React.ReactNode;
  onMenuOpen?: () => void;
  onMenuClose?: () => void;
  dropdownWidth?: MenuWidth;
  dropdownTwoLines?: boolean;
  dense?: boolean;
  corner?: Corner;
  floating?: boolean;
  fixed?: boolean;
  menuButtonRef?: React.RefObject<HTMLInputElement>;
  disableAutoOpen?: boolean;
}

export const TypeAheadMenu = React.forwardRef<
  HTMLDivElement,
  React.PropsWithChildren<TypeAheadMenuProps>
>(
  (
    {
      className,
      anchorClassName,
      children,
      anchorElement,
      dropdownTwoLines = false,
      dropdownWidth = MenuWidth.FLOATING_WIDTH,
      onMenuOpen = R.always(undefined),
      onMenuClose = R.always(undefined),
      dense = false,
      corner = Corner.BOTTOM_START,
      disableAutoOpen = false,
      ...props
    },
    ref: React.MutableRefObject<HTMLDivElement>,
  ) => {
    const menuRef = React.useRef<HTMLDivElement>();
    const typeaheadController = React.useContext(getTypeAheadContext());
    const optionsCollection = typeaheadController.getOptionsCollection();
    const hasItems = Boolean(optionsCollection.getItems().length);
    const [isOpen, setIsOpen] = React.useState(false);

    const menuOpenHandler = React.useCallback(() => {
      onMenuOpen();
      if (!hasItems) {
        optionsCollection.load();
      }
      setIsOpen(true);
    }, [hasItems, onMenuOpen]);

    const createMenuRef = React.useCallback((element: HTMLDivElement) => {
      menuRef.current = element;
      if (ref) {
        if (ref instanceof Function) {
          ref(element);
        } else {
          ref.current = element;
        }
      }
    }, []);

    const menuCloseHandler = React.useCallback(() => {
      onMenuClose();
      typeaheadController.forceValue();
      setIsOpen(false);
    }, [typeaheadController]);

    return (
      <MenuWrapper
        autoFocus={false}
        corner={corner}
        className={classnames(className, "typeahead-menu")}
        anchorClassName={anchorClassName}
        onOpen={menuOpenHandler}
        onClose={menuCloseHandler}
        ref={createMenuRef}
        twoLines={dropdownTwoLines}
        dense={dense}
        children={anchorElement}
        menu={children}
        aria-hidden={!isOpen}
        disableAutoOpen={disableAutoOpen}
        menuWidth={dropdownWidth}
        {...props}
      />
    );
  },
);
