"use client";

import { HttpError } from "@natera/platform/lib/service/httpError";
import * as React from "react";
import { defineMessages, useIntl } from "react-intl";
import Error from "./error";
import NotFoundError from "./notFound";
import ServerError from "./serverError";

interface Props extends React.HTMLProps<HTMLDivElement> {
  error: HttpError;
  children?: React.ReactNode;
}

export const messages = defineMessages({
  httpError: {
    id: "platform.httpError.title",
    defaultMessage: "HTTP Error &mdash; {errorCode}",
  },
});

// TODO: use resource context provider in order to identify resource not found mgs
const HttpErrorDisplay: React.FC<Props> = ({ error, ...rest }) => {
  if (error.code === 404) {
    return <NotFoundError {...rest} error={error} />;
  }

  if (error.code === 500) {
    return <ServerError {...rest} error={error} />;
  }
  const intl = useIntl();

  const title = (
    <>{intl.formatMessage(messages.httpError, { errorCode: error.code })}</>
  );

  return (
    <Error
      {...rest}
      className="http-error"
      title={title}
      subtitle={error.message}
    >
      {
        <pre style={{ whiteSpace: "pre-wrap" }}>
          {JSON.stringify(error.body)}
        </pre>
      }
    </Error>
  );
};

export default HttpErrorDisplay;
