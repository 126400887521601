"use client";

import MoreVertIcon from "@natera/material/assets/svg/vertical-dots.svg";
import { IconButton, IconButtonProps } from "@natera/material/lib/button";
import classnames from "classnames";
import * as React from "react";
import { Corner, Menu } from "../";
import { IconProps } from "@natera/material/lib/icon";

import "./menuButton.scss";

export interface MenuButtonProps extends Omit<IconButtonProps, "children"> {
  menu: React.ReactNode;
  className?: string;
  disabled?: boolean;
  virtualized?: boolean;
  dense?: boolean;
  children?: React.ReactNode;
  materialIcon?: string | IconProps;
  floating?: boolean;
}

export const MenuButton: React.FunctionComponent<MenuButtonProps> = ({
  menu,
  className,
  disabled,
  virtualized = false,
  dense = false,
  materialIcon,
  floating,
  ...rest
}) => {
  return (
    <Menu
      corner={Corner.TOP_START}
      menu={menu}
      virtualized={virtualized}
      dense={dense}
      floating={floating}
    >
      {(ctrl) => (
        <IconButton
          {...rest}
          disabled={disabled}
          onClick={ctrl.toggleMenu}
          className={classnames("menu-button__icon", className)}
          materialIcon={materialIcon}
        >
          {MoreVertIcon}
        </IconButton>
      )}
    </Menu>
  );
};

export default MenuButton;
