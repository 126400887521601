"use client";

import { useComponentState } from "@natera/material/lib/hooks";
import { DayPickerWrapper } from "../";
import { BaseDateInputProps, DateInputMask } from "./inputMask";
import {
  CustomComponents,
  DateFormatter,
  DayPickerProps,
} from "react-day-picker";

import * as React from "react";
import { IconProps } from "@natera/material/lib/icon";
import { MenuController } from "../../menu";
import TrailingIcon from "./trailingIcon";
import { getMaterialTrailingIcon } from "./materialTrailingIcon";

export interface DateInputProps extends BaseDateInputProps {
  enableOutsideDaysClick?: boolean;
  date?: Date;
  defaultDate?: Date;
  onDateChange?: (date: Date | undefined) => void;
  bottomMenuElement?: React.ReactNode;
  modifiers?: DayPickerProps["modifiers"];
  modifiersStyles?: DayPickerProps["modifiersStyles"];
  defaultMonthYear?: Date;
  showOutsideDays?: boolean;
  dayPickerComponents?: CustomComponents;
  formatWeekdayName?: DateFormatter;
  formatMonthTitle?: DateFormatter;
  formatMonthInMenu?: (date: Date) => string;
  materialArrowIcon?: string | IconProps;
  materialDropdownIcon?: string | IconProps;
  floating?: boolean;
}

export const DateInput = React.forwardRef<HTMLInputElement, DateInputProps>(
  (
    {
      enableOutsideDaysClick = false,
      showOutsideDays = false,
      date,
      defaultDate,
      maxDate,
      minDate,
      onDateChange,
      disabled,
      bottomMenuElement,
      modifiers,
      modifiersStyles,
      defaultMonthYear,
      dayPickerComponents,
      formatWeekdayName,
      formatMonthTitle,
      formatMonthInMenu,
      trailingIcon,
      materialTrailingIcon,
      materialArrowIcon,
      materialDropdownIcon,
      floating,
      ...props
    },
    ref: React.RefObject<HTMLInputElement>,
  ) => {
    const [day, setDay] = useComponentState<Date>({
      controlledValue: date,
      defaultValue: defaultDate,
      onChange: (date$) => {
        if (onDateChange) {
          onDateChange(date$);
        }
      },
    });

    const defaultMinDate = React.useMemo(() => {
      const currentDate = new Date();
      currentDate.setFullYear(currentDate.getFullYear() - 100);
      return currentDate;
    }, []);

    const defaultMaxDate = React.useMemo(() => {
      const currentDate = new Date();
      currentDate.setFullYear(currentDate.getFullYear() + 1);
      return currentDate;
    }, []);

    const trailingIcon$ = (
      menu: MenuController,
    ): React.ReactNode | undefined => {
      return trailingIcon ? (
        <TrailingIcon trailingIcon={trailingIcon} menu={menu} />
      ) : undefined;
    };

    const materialTrailingIcon$ = (menu: MenuController) =>
      getMaterialTrailingIcon(materialTrailingIcon, props.label, menu);

    return (
      <DayPickerWrapper
        enableOutsideDaysClick={enableOutsideDaysClick}
        showOutsideDays={showOutsideDays}
        minDate={minDate || defaultMinDate}
        maxDate={maxDate || defaultMaxDate}
        date={day}
        onDateChange={setDay}
        bottomElement={bottomMenuElement}
        modifiers={modifiers}
        modifiersStyles={modifiersStyles}
        defaultMonthYear={defaultMonthYear}
        dayPickerComponents={dayPickerComponents}
        formatWeekdayName={formatWeekdayName}
        formatMonthTitle={formatMonthTitle}
        formatMonthInMenu={formatMonthInMenu}
        materialArrowIcon={materialArrowIcon}
        materialDropdownIcon={materialDropdownIcon}
        floating={floating}
      >
        {(menu) => (
          <DateInputMask
            {...props}
            disabled={disabled}
            ref={ref}
            minDate={minDate || defaultMinDate}
            maxDate={maxDate || defaultMaxDate}
            onClick={!disabled ? menu.openMenu : undefined}
            date={day}
            onMaskComplete={setDay}
            forceActiveFocus={menu.isOpen()}
            trailingIcon={trailingIcon$(menu)}
            materialTrailingIcon={materialTrailingIcon$(menu)}
          />
        )}
      </DayPickerWrapper>
    );
  },
);

export default DateInput;
