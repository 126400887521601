"use client";

import {
  Switch as MaterialSwitch,
  SwitchProps,
} from "@natera/material/lib/switch";
import * as React from "react";
import { FormFieldContext } from "@natera/form";

export const Switch: React.FunctionComponent<SwitchProps> = ({
  disabled,
  ...props
}) => {
  const { isEditable, isDisabled } = React.useContext(FormFieldContext);
  const disabled$ = disabled || isDisabled() || !isEditable();

  return <MaterialSwitch disabled={disabled$} {...props} />;
};

Switch.displayName = "SwitchComponent";

export default Switch;
