"use client";

import { MenuContext } from "@natera/material/lib/menu";
import classnames from "classnames";
import * as R from "ramda";
import * as React from "react";
import {
  Textfield,
  TextfieldV2,
  TextfieldProps,
  TextfieldV2Props,
  getTypeAheadContext,
} from "@natera/form";
import { useKeydownHandler } from "./inputKeydownHandler";

type BaseTextfieldProps = TextfieldProps & TextfieldV2Props;
export interface TypeAheadInputProps extends BaseTextfieldProps {
  allowType?: boolean;
  minQuery?: number;
  onEnterPress?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
}

export const TypeAheadInput = React.forwardRef<
  HTMLInputElement,
  TypeAheadInputProps
>(
  (
    {
      disabled,
      allowType = true,
      onChange = R.always(undefined),
      onClear = R.always(undefined),
      onEnterPress,
      selectable = true,
      readOnly = false,
      clearable = true,
      minQuery,
      ...props
    },
    ref,
  ) => {
    const menuController = React.useContext(MenuContext);
    const typeahead = React.useContext(getTypeAheadContext());

    const changeHandler: React.ChangeEventHandler<HTMLInputElement> =
      React.useCallback(
        (event) => {
          if (!allowType) {
            return undefined;
          }

          onChange(event);

          typeahead.setInputValue(event.currentTarget.value);
        },
        [allowType, onChange, typeahead],
      );

    const clearHandler = React.useCallback(() => {
      onClear();
      typeahead.selectOption(undefined);
      typeahead.clear();
    }, [onClear, typeahead]);

    const keydownHandler = useKeydownHandler(
      allowType,
      R.always(onkeydown),
      typeahead,
      menuController,
      minQuery,
      onEnterPress,
    );

    const TextfieldComponent = props.label ? TextfieldV2 : Textfield;

    const textfieldComponentProps = {
      [props.label ? "forceActiveFocus" : "dropDownActive"]:
        menuController.isOpen(),
    };

    return (
      <TextfieldComponent
        className={classnames("typeahead-input", {
          "typeahead-input--open": menuController.isOpen(),
        })}
        ref={ref}
        selectable={selectable}
        value={typeahead.getInputValue()}
        onChange={changeHandler}
        onKeyDown={
          typeahead.getOptionsCollection().isLoading()
            ? undefined
            : keydownHandler
        }
        disabled={disabled}
        clearable={clearable}
        onClear={clearHandler}
        readOnly={!allowType || readOnly}
        {...textfieldComponentProps}
        {...props}
      />
    );
  },
);
