"use client";

import SearchIcon from "@natera/platform/assets/svg/icons/round-search-24px.svg";
import { MenuController } from "@natera/platform/lib/components/menu";
import classnames from "classnames";
import * as R from "ramda";
import * as React from "react";
import { defineMessages, useIntl } from "react-intl";
import {
  FormContext,
  MultiSelectActions,
  MultiSelectContextProps,
  MultiSelectController,
  MultiSelectProvider,
  MultiSelectMenu,
  MultiSelectGroupProvider,
} from "@natera/form";
import {
  DisplayValue,
  TypeAheadContextProps,
  TypeAheadInput,
  TypeAheadInputProps,
  TypeAheadMenuProps,
  TypeAheadProvider,
  TypeAheadResultList,
  TypeAheadResultListProps,
  IsOptionDisabled,
} from "../typeAhead";
import { IconProps } from "@natera/material/lib/icon";
import { CountOfSelected } from "./group/countOfSelected";
import RenderOption from "./group/renderOption";
import {
  RenderGroup as RenderGroupType,
  RenderOption as RenderOptionType,
} from "./types";
import "./multiSelect.scss";

type ResultListProps<T> = Pick<
  TypeAheadResultListProps<T>,
  "notFoundText" | "lastText"
>;

type InputProps = Omit<
  TypeAheadInputProps,
  "onSelect" | "children" | "value" | "onChange"
> & {
  searchPlaceholder?: string;
};

type MenuProps<T> = Pick<
  TypeAheadMenuProps,
  "dense" | "dropdownWidth" | "dropdownTwoLines" | "onMenuOpen" | "floating"
> & {
  menuClassName?: string;
  anchorElement?: (
    menu: MenuController,
    multiselect: MultiSelectController<T>,
  ) => React.ReactNode;
};

type TypeaheadProps<T> = Pick<
  TypeAheadContextProps<T>,
  "delayTime" | "pageLimit" | "getOptions" | "getInitialOptions"
>;

type ContextProps<T> = Pick<
  MultiSelectContextProps<T>,
  "recordIndex" | "activeSelection"
>;

export enum ActionButtonsPosition {
  TOP,
  BOTTOM,
}

export interface MultiSelectProps<T>
  extends ResultListProps<T>,
    InputProps,
    MenuProps<T>,
    TypeaheadProps<T>,
    ContextProps<T> {
  className?: string;
  value?: T[];
  onChange?: (option: T[] | undefined) => void;
  onReset?: () => void;
  displayValue?: DisplayValue<T[]>;
  secondaryValue?: DisplayValue<T[]>;
  displaySearch?: boolean;
  displayActions?: boolean;
  displayFastClear?: boolean;
  displayCountOfSelected?: boolean;
  groupBy?: (item: T) => string | undefined;
  isOptionDisabled?: IsOptionDisabled<T>;
  renderGroup?: RenderGroupType;
  actionButtonsPosition?: ActionButtonsPosition;
  renderOption?: RenderOptionType<T>;
  sortGroups?: (a: string, b: string) => number;
  sortItems?: (a: T, b: T) => number;
  children?: (option: T[], search?: string) => React.ReactNode;
  openOnFocus?: boolean;
  disableAutoOpen?: boolean;
  materialSearchIcon?: string | IconProps;
}

export const messages = defineMessages({
  searchPlaceholder: {
    id: "platform.multiselect.searchPlaceholder",
    defaultMessage: "Search...",
  },
});

export const MultiSelect = <T extends object>({
  id,
  className,
  value = [],
  onChange = R.always(undefined),
  onReset = R.always(undefined),
  displayValue,
  secondaryValue,

  displaySearch = true,
  displayActions = true,
  displayFastClear = true,
  floating,
  displayCountOfSelected = false,

  // Context Props
  recordIndex,
  activeSelection,

  // Typeahead Props
  getOptions,
  getInitialOptions,
  pageLimit,
  delayTime,

  // Menu props
  dense,
  dropdownWidth,
  dropdownTwoLines,
  menuClassName,
  anchorElement,
  onMenuOpen,
  disableAutoOpen,

  // Result List Props
  lastText,
  notFoundText,
  groupBy,
  renderGroup,
  actionButtonsPosition = ActionButtonsPosition.BOTTOM,
  renderOption,
  sortGroups,
  sortItems,
  isOptionDisabled,
  children,
  materialSearchIcon,

  // Input Props
  disabled,
  searchPlaceholder,
  openOnFocus = true,
  ...props
}: MultiSelectProps<T>) => {
  const intl = useIntl();
  const inputRef = React.useRef<HTMLInputElement>(null);
  const menuRef = React.useRef<HTMLDivElement>(null);
  const searchRef = React.useRef<HTMLDivElement | null>(null);

  const getDisplayValue: DisplayValue<T[]> = React.useCallback(
    (option) => {
      if (!option) {
        return "";
      }

      if (!displayValue) {
        return "";
      }

      return displayValue(option);
    },
    [displayValue],
  );

  const { setFormChanged } = React.useContext(FormContext);

  const onSelectOption = React.useCallback(
    (option: T[] | undefined) => {
      onChange(option);
      setFormChanged();
      inputRef.current?.blur();
    },
    [onChange],
  );

  const handleOpen = React.useCallback(() => {
    if (onMenuOpen) {
      onMenuOpen();
    }

    if (displaySearch) {
      searchRef.current?.focus();
      return;
    }

    menuRef.current?.focus();
  }, [onMenuOpen]);

  const clearHandler =
    (menu: MenuController, multiselect: MultiSelectController<T>) => () => {
      onReset();
      multiselect.remove();
      menu.closeMenu();
    };

  const secondaryValue$ = secondaryValue && value && secondaryValue(value);

  const getAnchorElement = (
    menu: MenuController,
    multiselect: MultiSelectController<T>,
  ) => (
    <div
      id={id}
      className={classnames(className, "multiselect", {
        "multiselect--with-meta-value": Boolean(secondaryValue$),
      })}
    >
      <TypeAheadInput
        value={getDisplayValue(value)}
        disabled={disabled}
        ref={inputRef}
        onClear={clearHandler(menu, multiselect)}
        allowType={false}
        onMouseDown={(event) => {
          event.preventDefault();
          menu.toggleMenu();
        }}
        onFocus={() => {
          if (openOnFocus) {
            menu.openMenu();
          }
        }}
        clearable={displayFastClear}
        dense={dense}
        {...props}
      />
      {Boolean(secondaryValue$) && (
        <div
          className={classnames("multiselect-secondary-value", {
            "multiselect-secondary-value--disabled": disabled,
          })}
        >
          {secondaryValue$}
        </div>
      )}
    </div>
  );
  return (
    <TypeAheadProvider
      getOptions={getOptions}
      getInitialOptions={getInitialOptions}
      pageLimit={pageLimit}
      delayTime={delayTime}
      isOptionDisabled={isOptionDisabled}
    >
      <MultiSelectProvider
        initialSelection={value}
        onSelect={onSelectOption}
        activeSelection={activeSelection}
        recordIndex={recordIndex}
      >
        <MultiSelectGroupProvider<T>
          groupBy={groupBy}
          sortGroups={sortGroups}
          sortItems={sortItems}
        >
          <MultiSelectMenu
            dense={dense}
            tabIndex={0}
            ref={menuRef}
            dropdownWidth={dropdownWidth}
            dropdownTwoLines={dropdownTwoLines}
            anchorElement={anchorElement || getAnchorElement}
            className={classnames("multiselect__menu", menuClassName)}
            floating={floating}
            disableAutoOpen={disableAutoOpen}
            onMenuOpen={handleOpen}
          >
            {displayActions &&
              actionButtonsPosition === ActionButtonsPosition.TOP && (
                <MultiSelectActions divided />
              )}
            {displaySearch && (
              <TypeAheadInput
                ref={(ref) => (searchRef.current = ref)}
                className={classnames("multiselect__search", {
                  "multiselect__search--transparent":
                    actionButtonsPosition === ActionButtonsPosition.TOP,
                })}
                name="multiselect-search"
                clearable={true}
                selectable={false}
                placeholder={
                  searchPlaceholder ||
                  intl.formatMessage(messages.searchPlaceholder)
                }
                leadIcon={!materialSearchIcon ? SearchIcon : undefined}
                materialLeadIcon={materialSearchIcon}
                tabIndex={0}
              />
            )}
            {displayCountOfSelected && <CountOfSelected />}
            <TypeAheadResultList
              className={classnames("multiselect__result-list", {
                "multiselect__result-list--grouped": renderGroup,
              })}
              lastText={lastText}
              notFoundText={notFoundText}
            >
              {children
                ? children
                : (items: T[]) => (
                    <RenderOption
                      items={items}
                      groupBy={groupBy}
                      renderGroup={renderGroup}
                      renderOption={renderOption}
                    />
                  )}
            </TypeAheadResultList>
            {displayActions &&
              actionButtonsPosition === ActionButtonsPosition.BOTTOM && (
                <MultiSelectActions />
              )}
          </MultiSelectMenu>
        </MultiSelectGroupProvider>
      </MultiSelectProvider>
    </TypeAheadProvider>
  );
};
