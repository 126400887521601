import {
  HttpError,
  HttpResourceError,
} from "@natera/platform/lib/service/httpError";
import * as React from "react";
import ErrorLayout from "./error";
import HttpErrorDisplay from "./httpError";
import ResourceNotFound from "./resourceNotFound";

import "./error.scss";

interface Props extends React.HTMLProps<HTMLDivElement> {
  error: Error | string;
  renderLink: (options: {
    to: string;
    children: React.ReactNode;
  }) => React.ReactNode;
  children?: React.ReactNode;
}

export const DisplayError: React.FC<Props> = ({
  error,
  renderLink,
  ...rest
}) => {
  if (error instanceof HttpError) {
    return <HttpErrorDisplay {...rest} error={error} />;
  }

  if (error instanceof HttpResourceError) {
    return <ResourceNotFound renderLink={renderLink} {...rest} error={error} />;
  }

  return (
    <ErrorLayout {...rest} className="internal-error" title="Internal error">
      {String(error)}
      {error instanceof Error && (
        <pre style={{ whiteSpace: "pre-wrap" }}>{error.stack}</pre>
      )}
    </ErrorLayout>
  );
};

export default DisplayError;
