import classnames from "classnames";
import * as React from "react";
import "./linear.scss";

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  indeterminate?: boolean;
  progress?: number;
}

const LinearProgress: React.FunctionComponent<Props> = ({
  className,
  indeterminate = true,
  progress,
  ...props
}) => (
  <div
    {...props}
    role="progressbar"
    className={classnames(
      "mdc-linear-progress",
      "mdc-linear-progress--animation-ready",
      className,
      {
        "mdc-linear-progress--indeterminate": indeterminate,
      },
    )}
  >
    <div className="mdc-linear-progress__buffer">
      <div className="mdc-linear-progress__buffer-bar" />
      <div className="mdc-linear-progress__buffer-dots" />
    </div>
    <div
      className="mdc-linear-progress__bar mdc-linear-progress__primary-bar"
      style={{ transform: progress ? `scaleX(${progress / 100})` : undefined }}
    >
      <span className="mdc-linear-progress__bar-inner" />
    </div>
    <div className="mdc-linear-progress__bar mdc-linear-progress__secondary-bar">
      <span className="mdc-linear-progress__bar-inner" />
    </div>
  </div>
);

export default LinearProgress;
