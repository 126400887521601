import * as React from "react";
import classnames from "classnames";

export const SearchbarItemInfo: React.FunctionComponent<
  React.HTMLProps<HTMLDivElement>
> = ({ children, className, ...props }) => {
  return (
    <div className={classnames(className, "searchbar-item__info")} {...props}>
      {children}
    </div>
  );
};

export default SearchbarItemInfo;
