"use client";

import React from "react";
import classnames from "classnames";
import { IconButton } from "@natera/material/lib/button";
import ClearIcon from "@natera/material/assets/svg/clear.svg";
import { CssClasses } from "@material/tooltip";
import { TooltipContentProps } from "./types";

import "./tooltip.scss";

export const TooltipContent = React.forwardRef<
  HTMLDivElement,
  TooltipContentProps
>(
  (
    {
      id,
      rich,
      persistent,
      inverse,
      showCloseIcon,
      materialCloseIcon,
      close,
      title,
      content,
      actions,
      className,
      ...props
    },
    ref,
  ) => {
    return (
      <div
        {...props}
        id={id}
        ref={ref}
        tabIndex={persistent ? -1 : undefined}
        data-mdc-tooltip-persistent={!rich || persistent}
        className={classnames(
          "mdc-tooltip",
          {
            [CssClasses.RICH]: rich,
            "mdc-tooltip--inverse": inverse,
          },
          className,
        )}
      >
        <div
          className={classnames(
            CssClasses.SURFACE,
            CssClasses.SURFACE_ANIMATION,
            { "mdc-tooltip--with-close-icon": showCloseIcon },
          )}
        >
          {rich ? (
            <>
              {title && <h2 className="mdc-tooltip__title">{title}</h2>}
              <div className="mdc-tooltip__content">{content}</div>
              {actions && (
                <div className="mdc-tooltip--rich-actions" onClick={close}>
                  {actions}
                </div>
              )}
              {showCloseIcon && (
                <IconButton
                  className="mdc-tooltip__close-icon"
                  onClick={close}
                  materialIcon={materialCloseIcon}
                >
                  {ClearIcon}
                </IconButton>
              )}
            </>
          ) : (
            <>{content || title}</>
          )}
        </div>
      </div>
    );
  },
);

export default TooltipContent;
