"use client";

import * as React from "react";
import classnames from "classnames";
import { DisplayField, FormFieldContext } from "@natera/form";
import {
  TextfieldV2 as BaseTextfield,
  TextfieldV2Props,
} from "@natera/material/lib/textfield";

export { TextfieldV2Props };

export const TextfieldV2 = React.forwardRef<
  HTMLInputElement | HTMLTextAreaElement,
  TextfieldV2Props
>(
  (
    { value, defaultValue, disabled, validate, required, className, ...props },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    ref,
  ) => {
    const fieldContext = React.useContext(FormFieldContext);
    const isDisabled = disabled || fieldContext.isDisabled();
    const isRequired =
      required === false ? false : required || fieldContext.isRequired();

    if (!fieldContext.isEditable()) {
      return <DisplayField value={value || defaultValue || <>&mdash;</>} />;
    }

    return (
      <BaseTextfield
        value={value}
        defaultValue={defaultValue}
        disabled={isDisabled}
        required={isRequired}
        className={classnames(className, "mdc-form-field")}
        validate={(value) => {
          if (fieldContext.hasError()) {
            return false;
          }

          if (validate) {
            return validate(value);
          }

          return true;
        }}
        {...props}
      />
    );
  },
);

export default TextfieldV2;
