import * as React from "react";

import "./input.scss";

export type InputProps = React.InputHTMLAttributes<HTMLInputElement>;

export const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ type, ...props }, ref: React.RefObject<HTMLInputElement>) => (
    <input ref={ref} type={type || "text"} autoComplete="off" {...props} />
  ),
);

export default Input;
