"use client";

import {
  ListItem,
  ListItemGraphic,
  ListItemMeta,
  ListItemProps,
} from "@natera/material/lib/list";
import Svg from "@natera/material/lib/svg";
import * as R from "ramda";
import * as React from "react";
import { MenuContext } from "./controller";
import { IconProps } from "@natera/material/lib/icon";

export interface MenuItemProps extends ListItemProps {
  icon?: string;
  materialIcon?: string | IconProps;
  trailingIcon?: string;
  materialTrailingIcon?: string | IconProps;
  arrowIcon?: string;
  materialArrowIcon?: string | IconProps;
  onItemSelect?: (element: HTMLLIElement) => void;
  closeOnSelect?: boolean;
}

export const MenuItem: React.FunctionComponent<
  React.PropsWithChildren<MenuItemProps>
> = ({
  children,
  icon,
  materialIcon,
  className,
  trailingIcon,
  materialTrailingIcon,
  arrowIcon,
  materialArrowIcon,
  disabled,
  closeOnSelect = true,
  onItemSelect = R.always(undefined),
  ...props
}) => {
  const menuItemRef = React.useRef<HTMLLIElement>(null);
  const menuController = React.useContext(MenuContext);

  React.useEffect(() => {
    const element = menuItemRef.current;

    if (element) {
      menuController.registerItem({
        getElement: () => element,
        handleItemSelect: () => onItemSelect(element),
      });
    }
  }, [onItemSelect]);

  const handleItemAction = React.useCallback(
    (item: HTMLLIElement) => {
      menuController.onItemAction(item, closeOnSelect);
    },
    [menuController, closeOnSelect],
  );

  const keyHandler = React.useCallback(
    (event: React.KeyboardEvent) => {
      if (event.key === "Escape") {
        menuController.closeMenu();
      }
    },
    [menuController],
  );

  return (
    <ListItem
      className={className}
      role="menuitem"
      onItemAction={handleItemAction}
      disabled={disabled}
      onKeyDown={keyHandler}
      {...props}
      ref={menuItemRef}
    >
      {(icon || materialIcon) && (
        <ListItemGraphic materialIcon={materialIcon}>
          {icon ? <Svg content={icon} /> : null}
        </ListItemGraphic>
      )}
      {children}
      {(trailingIcon || materialTrailingIcon) && (
        <ListItemMeta materialIcon={materialTrailingIcon}>
          {trailingIcon ? <Svg content={trailingIcon} /> : null}
        </ListItemMeta>
      )}
      {(arrowIcon || materialArrowIcon) && (
        <ListItemMeta materialIcon={materialArrowIcon}>
          {arrowIcon ? <Svg content={arrowIcon} /> : null}
        </ListItemMeta>
      )}
    </ListItem>
  );
};

export default MenuItem;
