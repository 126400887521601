import { OktaIdentity } from "@natera/platform/lib/service/session";
import * as R from "ramda";
import * as React from "react";

export interface AppUser extends OktaIdentity {
  firstName?: string;
  lastName?: string;
  role: string;
}

interface UserProviderProps<U extends AppUser> {
  user?: U;
}

type GetCurrentUser<U extends OktaIdentity> = () => U | undefined;
type IsCurrentUser<U extends OktaIdentity> = (user: U) => boolean;

export interface UserController<U extends AppUser> {
  getCurrentUser: GetCurrentUser<U>;
  isCurrentUser: IsCurrentUser<U>;
}

const UserContext$ = React.createContext<unknown>({
  getCurrentUser: () => undefined,
  isCurrentUser: () => false,
});

export function getUserContext<U extends AppUser>(): React.Context<
  UserController<U>
> {
  return UserContext$ as React.Context<UserController<U>>;
}

export function useUserContext<U extends AppUser>(): UserController<U> {
  return React.useContext(getUserContext<U>());
}

export const UserProvider = <U extends AppUser>({
  user,
  children,
}: React.PropsWithChildren<
  UserProviderProps<U>
>): React.ReactElement | null => {
  const UserContext = getUserContext<U>();
  const getCurrentUser: GetCurrentUser<U> = R.always(user);
  const isCurrentUser: IsCurrentUser<U> = (user$) =>
    Boolean(user$ && user ? user.uid === user$.uid : user);

  return (
    <UserContext.Provider
      value={{
        getCurrentUser,
        isCurrentUser,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
