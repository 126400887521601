"use client";

import React, { FC } from "react";
import { useIntl } from "react-intl";
import { DateRange } from "../../definitions";
import { getChipTime } from "../selected-slots/SelectedSlotTime";

interface SlotChipTimeProps {
  slot: DateRange;
}

const SlotChipTime: FC<SlotChipTimeProps> = ({ slot }) => {
  const intl = useIntl();

  const chipTime = getChipTime(slot, intl);
  return <>{chipTime}</>;
};

export default SlotChipTime;
