"use client";

import * as React from "react";
import classnames from "classnames";
import { v4 as uuidv4 } from "uuid";

import "./card.scss";

export enum MEDIA_SIZES {
  WIDE = "16-9",
  SQUARE = "square",
}

interface BaseElementProps {
  title?: React.ReactNode;
  content?: React.ReactNode;
  bordered?: boolean;
}

interface HeadProps extends BaseElementProps {
  action?: React.ReactNode;
}

interface BodyProps extends BaseElementProps {
  subtitle?: React.ReactNode;
}

interface FooterProps extends BaseElementProps {
  actions?: React.ReactElement[];
  icons?: React.ReactElement[];
  reversed?: boolean;
}

export interface CardProps extends React.HTMLAttributes<HTMLDivElement> {
  outlined?: boolean;
  header?: React.ReactNode;
  head?: HeadProps;
  body?: BodyProps;
  footer?: FooterProps;
  media?: {
    url: string;
    title?: React.ReactNode;
    size?: MEDIA_SIZES;
  };
  filled?: boolean;
  ripple?: boolean;
}

const classes = {
  bordered: "mdc-card--bordered",
  headline: "mdc-card--headline",
  subtitle: "mdc-card--subtitle",
  caption: "mdc-card--caption",
};

export const Card = React.forwardRef<HTMLDivElement, CardProps>(
  (
    {
      children,
      className,
      header,
      outlined = false,
      head,
      body,
      footer,
      media,
      filled,
      onClick,
      ...props
    },
    ref: React.RefObject<HTMLDivElement>,
  ) => {
    const isLegacy = !!children || !!header;

    return (
      <div
        ref={ref}
        {...props}
        onClick={isLegacy ? onClick : undefined}
        className={classnames("mdc-card", className, {
          "mdc-card--outlined": outlined,
          "mdc-card--filled": filled,
        })}
      >
        {isLegacy && (
          <>
            {header && <div className="card-title-container">{header}</div>}
            {children}
          </>
        )}

        {!isLegacy && (
          <div
            tabIndex={onClick ? 0 : undefined}
            className={classnames("mdc-card__content", {
              "mdc-card__primary-action": onClick,
            })}
            onClick={onClick}
          >
            {head && (
              <div
                className={classnames("mdc-card__head", {
                  [classes.bordered]: head.bordered,
                })}
              >
                {(head.title || head.content) && (
                  <div>
                    {head.title && (
                      <div className={classes.headline}>{head.title}</div>
                    )}
                    {head.content && (
                      <div className={classes.caption}>{head.content}</div>
                    )}
                  </div>
                )}
                {head.action && (
                  <div className="mdc-card__action">{head.action}</div>
                )}
              </div>
            )}

            {media && (
              <div
                style={{ backgroundImage: `url("${media.url}")` }}
                className={classnames(
                  "mdc-card__media",
                  `mdc-card__media--${media.size || MEDIA_SIZES.WIDE}`,
                )}
              >
                {media.title && (
                  <div className="mdc-card__media-content">{media.title}</div>
                )}
              </div>
            )}

            {body && (
              <div
                className={classnames("mdc-card__body", {
                  [classes.bordered]: body.bordered,
                })}
              >
                {body.title && (
                  <div className={classes.headline}>{body.title}</div>
                )}
                {body.subtitle && (
                  <div className={classes.subtitle}>{body.subtitle}</div>
                )}
                {body.content && (
                  <div className={classes.caption}>{body.content}</div>
                )}
              </div>
            )}

            {footer && (
              <>
                <div
                  className={classnames("mdc-card__footer", {
                    [classes.bordered]: footer.bordered,
                  })}
                >
                  {footer.title && (
                    <div className={classes.subtitle}>{footer.title}</div>
                  )}
                  {footer.content && (
                    <div className={classes.caption}>{footer.content}</div>
                  )}
                </div>
                {(footer.actions || footer.icons) && (
                  <div
                    className={classnames("mdc-card__actions", {
                      "mdc-card__actions--reversed": footer.reversed,
                    })}
                  >
                    {footer.actions && (
                      <div className="mdc-card__action-buttons">
                        {footer.actions.map((element) =>
                          React.cloneElement(element, {
                            className: classnames(
                              element.props.className,
                              "mdc-card__action",
                            ),
                            key: uuidv4(),
                          }),
                        )}
                      </div>
                    )}
                    {footer.icons && (
                      <div className="mdc-card__action-icons">
                        {footer.icons.map((element) =>
                          React.cloneElement(element, {
                            className: classnames(
                              element.props.className,
                              "mdc-card__action",
                            ),
                            key: uuidv4(),
                          }),
                        )}
                      </div>
                    )}
                  </div>
                )}
              </>
            )}
            {onClick && <div className="mdc-card__ripple" />}
          </div>
        )}
      </div>
    );
  },
);

export default Card;
