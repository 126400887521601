"use client";

import React from "react";
import {
  ListItem,
  ListItemGraphic,
  ListItemText,
} from "@natera/material/lib/list";
import { Spinner } from "@natera/material/lib/progress";
import { ScrollContext } from "@natera/platform/lib/components/scrollbar";
import { HighlightedTextProvider } from "@natera/platform/lib/provider/highlightedText";
import { defineMessages, useIntl } from "react-intl";
import { getTypeAheadContext } from "@natera/form";
import Svg from "@natera/material/lib/svg";
import InfoIcon from "../../assets/svg/infoIcon.svg";
import SearchbarError from "./searchbarError";
import { IconProps } from "@natera/material/lib/icon";

export interface SearchbarResultListProps<T> {
  minQuery: number;
  renderResult: (option: T[], search?: string) => React.ReactNode;
  renderRecent?: (option: T[], search?: string) => React.ReactNode;
  className?: string;
  notFoundText?: React.ReactNode;
  maxResults?: number;
  materialExclamationIcon?: string | IconProps;
  materialInfoIcon?: string | IconProps;
}

export const messages = defineMessages({
  notFoundText: {
    id: "form.searchbar.notFoundText",
    defaultMessage: "No matches found",
  },
  lettersLimitAlert: {
    id: "form.searchbar.lettersLimitAlert",
    defaultMessage: `Enter at least two characters to see quick search results
    appear here.`,
  },
  errorDescription: {
    id: "form.searchbar.errorDescription",
    defaultMessage: "Please contact support if the problem persists.",
  },
  menuMessage: {
    id: "form.searchbar.menuMessage",
    defaultMessage: "Showing first {max} results",
  },
  recentsTitle: {
    id: "form.searchbar.recentsTitle",
    defaultMessage: "Recent",
  },
});

export const SearchbarResultList = <T extends object>({
  className,
  renderResult,
  renderRecent,
  notFoundText,
  minQuery,
  maxResults = 30,
  materialExclamationIcon,
  materialInfoIcon,
}: SearchbarResultListProps<T>): React.ReactElement | null => {
  const ulRef = React.useRef<HTMLUListElement>(null);
  const intl = useIntl();
  const searchbarController = React.useContext(getTypeAheadContext<T>());
  const optionsCollection = searchbarController.getOptionsCollection();
  const error = searchbarController.getError();
  const searchValue = searchbarController.getInputValue();
  const ulClassName = "searchbar-menu__items";
  const [showHint, setShowHint] = React.useState(true);

  React.useEffect(() => {
    if (searchValue.length < minQuery) {
      setShowHint(true);
    } else {
      setShowHint(false);
    }
  }, [searchValue]);

  const handleFocus = (event: React.FocusEvent<HTMLUListElement>) => {
    if (event.target?.className === ulClassName) {
      const item = ulRef.current?.querySelector("li");
      item?.focus();
    }
  };

  const resultItems = renderResult(
    optionsCollection.getItems().slice(0, maxResults),
    searchValue,
  );
  const resultItemsLength = React.Children.count(resultItems);

  const recentItems = renderRecent
    ? renderRecent(optionsCollection.getItems(), searchValue)
    : undefined;
  const recentItemsLength = React.Children.count(recentItems);

  return (
    <>
      <ScrollContext component="menu" className={className} isStatic={false}>
        <ul className={ulClassName} onFocus={handleFocus} ref={ulRef}>
          {error && searchValue.length >= minQuery ? (
            <SearchbarError
              message={error}
              description={intl.formatMessage(messages.errorDescription)}
              materialExclamationIcon={materialExclamationIcon}
            />
          ) : (
            <>
              {(!optionsCollection.getResource().hasResource() ||
                optionsCollection.isLoading()) &&
                !showHint && (
                  <ListItem className="searchbar-item__spinner" tabIndex={-1}>
                    <ListItemGraphic>
                      <Spinner />
                    </ListItemGraphic>
                  </ListItem>
                )}
              {showHint && recentItemsLength === 0 && (
                <ListItem className="searchbar-item__hint" tabIndex={-1}>
                  <ListItemGraphic materialIcon={materialInfoIcon}>
                    <Svg content={InfoIcon} />
                  </ListItemGraphic>
                  <ListItemText>
                    {intl.formatMessage(messages.lettersLimitAlert)}
                  </ListItemText>
                </ListItem>
              )}
              {optionsCollection.getResource().hasResource() &&
                !optionsCollection.isLoading() &&
                Boolean(optionsCollection.getItems().length) && (
                  <>
                    {recentItemsLength > 0 && !searchValue ? (
                      <>
                        <ListItemText className="searchbar-recent-text">
                          {intl.formatMessage(messages.recentsTitle)}
                        </ListItemText>
                        <HighlightedTextProvider text={searchValue}>
                          {recentItems}
                        </HighlightedTextProvider>
                      </>
                    ) : (
                      !showHint && (
                        <HighlightedTextProvider text={searchValue}>
                          {resultItems}
                        </HighlightedTextProvider>
                      )
                    )}
                  </>
                )}
              {optionsCollection.getResource().hasResource() &&
                !optionsCollection.isLoading() &&
                !optionsCollection.getItems().length &&
                searchValue.length >= minQuery &&
                !showHint && (
                  <ListItem className="searchbar-item__hint" tabIndex={-1}>
                    <ListItemText>
                      {notFoundText ||
                        intl.formatMessage(messages.notFoundText)}
                    </ListItemText>
                  </ListItem>
                )}
            </>
          )}
        </ul>
      </ScrollContext>
      {optionsCollection.getResource().hasResource() &&
        !optionsCollection.isLoading() &&
        !showHint &&
        resultItemsLength >= maxResults && (
          <div className="searchbar-menu__message">
            {intl.formatMessage(messages.menuMessage, { max: maxResults })}
          </div>
        )}
    </>
  );
};
