"use client";

import ArrowLeft from "@natera/material/assets/svg/arrow_leftward.svg";
import ArrowRight from "@natera/material/assets/svg/arrow_rightward.svg";
import { Button } from "@natera/material/lib/button";
import { IconProps } from "@natera/material/lib/icon";
import React, { FC } from "react";
import { defineMessages, FormattedMessage } from "react-intl";
import { addDays } from "../../helpers";
import { useTimeslots } from "../timeslots/TimeslotsContext";

interface WeekControlsProps {
  startWeek: Date;
  endWeek: Date;
  materialArrowIcon?: string | IconProps;
}

export const messages = defineMessages({
  previousWeekButton: {
    id: "mobilePhlebotomy.timeslots.previousWeekButton",
    defaultMessage: "Previous week",
    description: "Previous week button text",
  },
  nextWeekButton: {
    id: "mobilePhlebotomy.timeslots.nextWeekButton",
    defaultMessage: "Next week",
    description: "Next Week button text",
  },
});

export const WeekControls: FC<WeekControlsProps> = ({
  endWeek,
  startWeek,
  materialArrowIcon,
}) => {
  const { focusWeek, updateWeek } = useTimeslots();
  return (
    <div className={"mobile-phlebotomy__timeslots__controls"}>
      <Button
        type="button"
        icon={!materialArrowIcon ? ArrowLeft : undefined}
        materialIcon={materialArrowIcon}
        style={{
          visibility:
            Number(focusWeek) === Number(startWeek) ? "hidden" : undefined,
        }}
        onClick={() => updateWeek(addDays(focusWeek, -7))}
      >
        <FormattedMessage {...messages.previousWeekButton} />
      </Button>
      <Button
        type="button"
        trailingIcon={!materialArrowIcon ? ArrowRight : undefined}
        materialTrailingIcon={materialArrowIcon}
        style={{
          visibility:
            Number(focusWeek) === Number(endWeek) ? "hidden" : undefined,
        }}
        onClick={() => updateWeek(addDays(focusWeek, 7))}
      >
        <FormattedMessage {...messages.nextWeekButton} />
      </Button>
    </div>
  );
};
