import classnames from "classnames";
import * as React from "react";
import { IconProps, MaterialIconRenderer } from "@natera/material/lib/icon";

type Props = React.HTMLProps<HTMLSpanElement> & {
  materialIcon?: string | IconProps;
};

export const ListItemGraphic: React.FunctionComponent<Props> = ({
  children,
  className,
  materialIcon,
  ...props
}) => {
  return (
    <span
      className={classnames(className, "mdc-deprecated-list-item__graphic")}
      {...props}
    >
      {materialIcon ? (
        <MaterialIconRenderer materialIcon={materialIcon} />
      ) : (
        children
      )}
    </span>
  );
};

export default ListItemGraphic;
