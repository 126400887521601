"use client";

import { MDCTextFieldFoundation } from "@material/textfield";
import classnames from "classnames";
import * as React from "react";
import { FormFieldContext, DisplayField } from "@natera/form";

import { useFocus } from "@natera/material/lib/hooks";
import "./textarea.scss";

export interface TextareaProps
  extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  editable?: boolean;
  counter?: string;
  outline?: boolean;
}

export const Textarea = React.forwardRef<HTMLTextAreaElement, TextareaProps>(
  (
    {
      className,
      disabled,
      editable,
      counter,
      outline,
      onFocus,
      onBlur,
      ...props
    },
    ref: React.RefObject<HTMLTextAreaElement>,
  ) => {
    const { focus, handleFocus, handleBlur } = useFocus({
      onFocus,
      onBlur,
    });

    const { isDisabled, isEditable, hasError, hasLabel } =
      React.useContext(FormFieldContext);
    const disabled$ = disabled || isDisabled();
    const editable$ = editable || isEditable();

    if (!editable$) {
      return (
        <DisplayField
          id={props.id}
          value={props.value || props.defaultValue || <>&mdash;</>}
        />
      );
    }

    return (
      <>
        <div
          className={classnames(
            className,
            MDCTextFieldFoundation.cssClasses.ROOT,
            {
              [MDCTextFieldFoundation.cssClasses.DISABLED]: disabled$,
              [MDCTextFieldFoundation.cssClasses.FOCUSED]: focus,
              [MDCTextFieldFoundation.cssClasses.INVALID]: hasError(),
              [MDCTextFieldFoundation.cssClasses.NO_LABEL]: !hasLabel,
              [MDCTextFieldFoundation.cssClasses.OUTLINED]: outline,
            },
            "mdc-form-field",
            "mdc-text-field",
            "mdc-text-field--textarea",
          )}
        >
          {outline && (
            <div className="mdc-notched-outline">
              <div className="mdc-notched-outline__leading" />
              <div className="mdc-notched-outline__trailing" />
            </div>
          )}
          <textarea
            ref={ref}
            autoComplete="off"
            className={classnames(className, "mdc-text-field__input")}
            disabled={disabled$}
            onFocus={handleFocus}
            onBlur={handleBlur}
            {...props}
          />
        </div>
        {counter && (
          <div className="mdc-text-field-helper-line">
            <div className="mdc-text-field-character-counter">{counter}</div>
          </div>
        )}
      </>
    );
  },
);

export default Textarea;
