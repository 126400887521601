"use client";

import * as React from "react";
import { defineMessages, useIntl } from "react-intl";
import { getMultiSelectContext } from "@natera/form";

export const messages = defineMessages({
  countOfSelectedMessage: {
    id: "platform.multiselect.countOfSelectedMessage",
    defaultMessage: "{countOfSelected} Selected",
    description: "Count of selected message",
  },
  noneMessage: {
    id: "platform.multiselect.noneMessage",
    defaultMessage: "None Selected",
    description: "None selected message",
  },
});

export const CountOfSelected: React.FC = () => {
  const multiSelectContext = React.useContext(getMultiSelectContext());
  const intl = useIntl();

  return (
    <div className="multiselect__selected-items">
      {multiSelectContext.getSelectedItems().length
        ? intl.formatMessage(messages.countOfSelectedMessage, {
            countOfSelected: multiSelectContext.getSelectedItems().length,
          })
        : intl.formatMessage(messages.noneMessage)}
    </div>
  );
};
