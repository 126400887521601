"use client";

import { IconButton } from "@natera/material/lib/button";
import { IconProps } from "@natera/material/lib/icon";
import cn from "classnames";
import React, { FC } from "react";
import { FormattedDateTimeRange, useIntl } from "react-intl";
import ArrowLeft from "../../../assets/svg/icons/arrow_left.svg";
import ArrowRight from "../../../assets/svg/icons/arrow_right.svg";
import { addDays } from "../../helpers";
import { useTimeslots } from "../timeslots/TimeslotsContext";
import { messages } from "../week-controls/WeekControls";

import "./weekTitle.scss";

interface Props {
  mobile?: boolean;
  startWeek: Date;
  endWeek: Date;
  materialArrowIcon?: string | IconProps;
}

export const WeekTitle: FC<Props> = ({
  mobile,
  startWeek,
  endWeek,
  materialArrowIcon,
}) => {
  const { focusWeek, updateWeek } = useTimeslots();
  const intl = useIntl();
  return (
    <div
      className={cn(
        "timeslots__week-title",
        mobile && "timeslots__week-title--mobile",
      )}
    >
      {mobile && (
        <IconButton
          aria-label={intl.formatMessage(messages.previousWeekButton)}
          style={{
            visibility:
              Number(focusWeek) === Number(startWeek) ? "hidden" : undefined,
          }}
          onClick={() => updateWeek(addDays(focusWeek, -7))}
          materialIcon={materialArrowIcon}
        >
          {ArrowLeft}
        </IconButton>
      )}
      <h3>
        <FormattedDateTimeRange
          from={focusWeek}
          to={addDays(focusWeek, 6)}
          day="numeric"
          month="long"
          timeZone="UTC"
        />
      </h3>
      {mobile && (
        <IconButton
          aria-label={intl.formatMessage(messages.nextWeekButton)}
          style={{
            visibility:
              Number(focusWeek) === Number(endWeek) ? "hidden" : undefined,
          }}
          onClick={() => updateWeek(addDays(focusWeek, 7))}
          materialIcon={materialArrowIcon}
        >
          {ArrowRight}
        </IconButton>
      )}
    </div>
  );
};
