(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("@natera/platform/lib/provider/security"), require("classnames"), require("react"), require("react-router-dom"), require("@natera/material/lib/tab"));
	else if(typeof define === 'function' && define.amd)
		define(["@natera/platform/lib/provider/security", "classnames", "react", "react-router-dom", "@natera/material/lib/tab"], factory);
	else {
		var a = typeof exports === 'object' ? factory(require("@natera/platform/lib/provider/security"), require("classnames"), require("react"), require("react-router-dom"), require("@natera/material/lib/tab")) : factory(root["@natera/platform/lib/provider/security"], root["classnames"], root["react"], root["react-router-dom"], root["@natera/material/lib/tab"]);
		for(var i in a) (typeof exports === 'object' ? exports : root)[i] = a[i];
	}
})(this, function(__WEBPACK_EXTERNAL_MODULE__5600__, __WEBPACK_EXTERNAL_MODULE__8310__, __WEBPACK_EXTERNAL_MODULE__8156__, __WEBPACK_EXTERNAL_MODULE__6128__, __WEBPACK_EXTERNAL_MODULE__7957__) {
return 