import React from "react";

const isTouchEvent = (
  event: React.MouseEvent | React.TouchEvent,
): event is React.TouchEvent => {
  return Boolean((event as React.TouchEvent).touches);
};

export const getNormalizedXCoordinate = (
  event: React.MouseEvent | React.TouchEvent,
): number => {
  const targetClientRect = event.currentTarget.getBoundingClientRect();
  const xCoordinate = isTouchEvent(event)
    ? event.touches[0].clientX
    : event.clientX;
  return xCoordinate - targetClientRect.left;
};
