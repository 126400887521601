interface HttpResourceErrorInterface {
  cause: Error;
  resourceName: string;
  resourceId: string;
}

class HttpResourceError extends Error implements HttpResourceErrorInterface {
  constructor(
    readonly cause: Error,
    readonly resourceName: string,
    readonly resourceId: string,
  ) {
    super(cause.message);
    // Set the prototype explicitly.
    Object.setPrototypeOf(this, HttpResourceError.prototype);
  }
}

export default HttpResourceError;
