"use client";

import { util } from "@natera/material/lib/common";
import classnames from "classnames";
import * as React from "react";
import { IconProps, IconRenderer } from "@natera/material/lib/icon";

import "./iconButton.scss";

export interface IconButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  children?: string;
  className?: string;
  materialIcon?: string | IconProps;
}

export const IconButton = React.forwardRef<HTMLButtonElement, IconButtonProps>(
  ({ children, className, disabled, materialIcon, ...props }, ref) => {
    const [isIE, setIsIE] = React.useState<boolean>(false);

    React.useEffect(() => {
      setIsIE(util.isIE());
    }, []);

    return (
      <button
        type={props.type || "button"}
        ref={ref}
        className={classnames("mdc-icon-button", className, {
          "mdc-icon-button--ie-support-fix": isIE,
          "mdc-icon-button--disabled": disabled,
        })}
        disabled={disabled}
        {...props}
      >
        <div className="mdc-icon-button__ripple"></div>
        <IconRenderer
          icon={!materialIcon ? children : undefined}
          materialIcon={materialIcon}
          className="mdc-icon-button__icon"
        />
      </button>
    );
  },
);

export default IconButton;
