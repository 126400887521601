"use client";

import {
  DateRangeInput as BaseDateRangeInput,
  DateRangeInputProps,
  DateRange,
  Utils,
} from "@natera/material/lib/date";
import { FormContext, DisplayField, FormFieldContext } from "@natera/form";
import * as R from "ramda";

import classnames from "classnames";
import * as React from "react";

export { DateRangeInputProps } from "@natera/material/lib/date";

export const DateRangeInput: React.FunctionComponent<DateRangeInputProps> = ({
  range,
  defaultRange,
  onDateRangeChange = R.always(undefined),
  disabled,
  invalid,
  required,
  className,
  ...props
}) => {
  const fieldContext = React.useContext(FormFieldContext);
  const { setFormChanged } = React.useContext(FormContext);

  const isDisabled = disabled || fieldContext.isDisabled();
  const hasError = invalid || fieldContext.hasError();
  const isRequired =
    required === false ? false : required || fieldContext.isRequired();

  const onDateRangeChange$ = (range$?: DateRange) => {
    onDateRangeChange(range$);
    setFormChanged();
  };

  // TODO: use intl instead
  const anyValue = range || defaultRange;
  const displayValue = anyValue && Utils.getUSLocaleDateRange(anyValue);

  return fieldContext.isEditable() ? (
    <BaseDateRangeInput
      className={classnames(className, "mdc-form-field")}
      range={range}
      defaultRange={defaultRange}
      onDateRangeChange={onDateRangeChange$}
      disabled={isDisabled}
      required={isRequired}
      invalid={hasError}
      {...props}
    />
  ) : (
    <DisplayField value={displayValue || <>&mdash;</>} />
  );
};

export default DateRangeInput;
