"use client";

import * as React from "react";
import classnames from "classnames";
import { ListItem } from "@natera/material/lib/list";
import { Checkbox } from "@natera/material/lib/checkbox";
import { v4 as uuidv4 } from "uuid";
import { getMultiSelectContext } from "@natera/form";
import { getTypeAheadContext } from "@natera/form/typeAhead";
import { RenderOption as RenderOptionType } from "../types";

interface Props<T> {
  item: T;
  nested?: boolean;
  className?: string;
  ungrouped?: boolean;
  renderOption: RenderOptionType<T>;
  groupExpanded?: boolean;
}

export const GroupOption = <T extends object>({
  item,
  nested,
  ungrouped,
  className,
  renderOption,
  groupExpanded,
}: Props<T>): React.ReactElement => {
  const multiSelectContext = React.useContext(getMultiSelectContext());
  const typeAHeadContext = React.useContext(getTypeAheadContext());
  const disabled = typeAHeadContext.isOptionDisabled(item);

  return (
    <ListItem
      aria-hidden={ungrouped || groupExpanded ? "false" : "true"}
      onItemAction={() => multiSelectContext.handleSelect(item)}
      disabled={disabled}
      className={classnames(
        className,
        "multiselect__option",
        nested && "multiselect__option--nested",
      )}
      key={item.toString()}
    >
      <Checkbox
        readOnly
        id={uuidv4()}
        disabled={disabled}
        label={renderOption(item)}
        onLabelClick={(event) => event.preventDefault()}
        checked={Boolean(multiSelectContext.getSelection().isSelected(item))}
        tabIndex={-1}
      />
    </ListItem>
  );
};
