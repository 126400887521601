"use client";

import { useComponentState } from "@natera/platform/lib/hooks";
import React from "react";
import NumberFormat, { NumberFormatValues } from "react-number-format";
import {
  DisplayField,
  FormFieldContext,
  TextfieldProps,
  Textfield,
  TextfieldV2,
  TextfieldV2Props,
} from "@natera/form";

const US_COUNTRY_CODE = "1";

const appendDefaultUSCountryCode = (phone: string) => {
  return phone && String(phone).length === 10 ? US_COUNTRY_CODE + phone : phone;
};

export const getFormPhone = (value: string): string => {
  const phone = value.replace(/\D/g, "");
  if (phone.match(/^\d?$/)) {
    return "";
  }

  return phone;
};

export const formatPhoneValue = (value?: string): string | undefined => {
  if (!value) {
    return value;
  }

  const match = getFormPhone(appendDefaultUSCountryCode(String(value))).match(
    /^([\d*])([\d*]{3})([\d*]{3})([\d*]+)$/,
  );

  if (match) {
    return `+${match[1]} (${match[2]}) ${match[3]}-${match[4]}`;
  }

  return undefined;
};

type InputProps = TextfieldProps & TextfieldV2Props;

export interface PhoneFieldProps extends InputProps {
  defaultValue?: string;
  onPhoneChange?: (phone: string | undefined) => void;
  value?: string;
}

export const PhoneField = React.forwardRef<HTMLInputElement, PhoneFieldProps>(
  (
    { defaultValue, name, value, placeholder, onPhoneChange, ...props },
    ref: React.RefObject<HTMLInputElement>,
  ) => {
    const isChanged = React.useRef(false);
    const customInput: React.ComponentType = React.useMemo(
      () => (props.label ? TextfieldV2 : Textfield),
      [props.label],
    );

    const { isEditable } = React.useContext(FormFieldContext);

    const [phone, setPhone] = useComponentState({
      controlledValue: value,
      defaultValue,
      onChange: onPhoneChange,
    });

    React.useEffect(() => {
      if (
        isChanged.current &&
        phone &&
        phone !== "" &&
        phone.length < 11 &&
        !phone.startsWith(US_COUNTRY_CODE)
      ) {
        setPhone(`${US_COUNTRY_CODE}${phone}`);
      }
    }, [phone]);

    const handleChange = (values: NumberFormatValues) => {
      isChanged.current = true;
      setPhone(values.value);
    };

    if (!isEditable()) {
      return (
        <DisplayField
          {...props}
          value={formatPhoneValue(phone) || <>&mdash;</>}
        />
      );
    }

    return (
      <>
        <input
          ref={ref}
          hidden={true}
          name={name || "phone"}
          readOnly={true}
          value={phone}
        />
        <NumberFormat
          {...props}
          placeholder={placeholder || "Phone"}
          customInput={customInput}
          value={phone}
          format="+# (###) ###-####"
          mask="_"
          type="tel"
          onValueChange={handleChange}
        />
      </>
    );
  },
);

export default PhoneField;
