import * as React from "react";
import { BasePatient } from "@natera/patient/lib/types";
import { getFullName } from "@natera/patient/lib/utils/patient";

export interface Props {
  patient: BasePatient;
  renderLink: (options: {
    to: string;
    className: string;
    children: React.ReactNode;
  }) => React.ReactElement;
}

export const PatientLink: React.FC<Props> = ({ patient, renderLink }) => {
  return renderLink({
    to: `/patient/${patient.id}`,
    className: "patient-link",
    children: getFullName(patient),
  });
};

export default PatientLink;
