"use client";

import { Corner, MenuController, MenuWrapper } from "@natera/material/lib/menu";
import classnames from "classnames";
import * as React from "react";

import UploadButton from "./uploadButton";

import "../attachment.scss";

import AttachIcon from "@natera/platform/assets/svg/icons/attach.svg";
import ArrowDown from "@natera/platform/assets/svg/icons/round-arrow_drop_down.svg";
import { IconProps, IconRenderer } from "@natera/material/lib/icon";

interface AttachmentSelectProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: React.ReactNode;
  className?: string;
  isDisabled?: boolean;
  items?: React.ReactNode;
  changeHandler: React.ChangeEventHandler<HTMLInputElement>;
  materialAttachmentIcon?: string | IconProps;
  materialDropdownIcon?: string | IconProps;
  floating?: boolean;
}

interface AttachmentMenuLabelProps {
  menuController?: MenuController;
  label?: React.ReactNode;
  className?: string;
  isDisabled?: boolean;
  materialAttachmentIcon?: string | IconProps;
  materialDropdownIcon?: string | IconProps;
  floating?: boolean;
}

const AttachmentMenuLabel: React.FunctionComponent<
  AttachmentMenuLabelProps
> = ({
  menuController,
  isDisabled,
  className,
  label,
  materialAttachmentIcon,
  materialDropdownIcon,
}) => {
  const handleKeydownToggleMenu = React.useCallback(
    (e: React.KeyboardEvent) => {
      if (e.key === "Enter" || e.key === " " || e.key === "Spacebar") {
        menuController?.toggleMenu();
      }
    },
    [menuController],
  );

  return (
    <label
      tabIndex={0}
      onKeyDown={handleKeydownToggleMenu}
      onClick={menuController?.toggleMenu}
      className={classnames(
        className,
        "attachment-button",
        "mdc-button",
        "mdc-button--outlined",
        { "mdc-button--disabled": isDisabled },
      )}
    >
      <IconRenderer
        icon={!materialAttachmentIcon ? AttachIcon : undefined}
        materialIcon={materialAttachmentIcon}
        className="mdc-button__icon"
        aria-hidden="true"
      />
      <span className="attachment-button-text">{label}</span>
      <IconRenderer
        icon={!materialDropdownIcon ? ArrowDown : undefined}
        materialIcon={materialDropdownIcon}
        className={classnames("attachment-dropdown", {
          "attachment-dropdown-up": !isDisabled && menuController?.isOpen(),
        })}
      />
    </label>
  );
};

const AttachmentSelect = React.forwardRef<
  HTMLInputElement,
  AttachmentSelectProps
>(
  (
    {
      label,
      className,
      isDisabled,
      items,
      materialAttachmentIcon,
      materialDropdownIcon,
      changeHandler,
      floating = true,
      ...props
    },
    ref: React.MutableRefObject<HTMLInputElement>,
  ) => (
    <>
      {!isDisabled ? (
        <MenuWrapper
          corner={Corner.BOTTOM_START}
          floating={floating}
          menu={
            <>
              <>
                <UploadButton
                  ref={ref}
                  changeHandler={changeHandler}
                  isDisabled={isDisabled}
                  {...props}
                />
                {items}
              </>
            </>
          }
        >
          {(ctrl) => (
            <AttachmentMenuLabel
              menuController={ctrl}
              isDisabled={isDisabled}
              className={className}
              materialAttachmentIcon={materialAttachmentIcon}
              materialDropdownIcon={materialDropdownIcon}
              label={label}
            />
          )}
        </MenuWrapper>
      ) : (
        <AttachmentMenuLabel
          isDisabled={isDisabled}
          className={className}
          materialAttachmentIcon={materialAttachmentIcon}
          materialDropdownIcon={materialDropdownIcon}
          label={label}
        />
      )}
    </>
  ),
);

export default AttachmentSelect;
