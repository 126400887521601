import * as React from "react";
import Svg from "@natera/material/lib/svg";
import { IconProps, IconRenderer } from "@natera/material/lib/icon";
import ArrowLeft from "@natera/material/assets/svg/arrow_leftward.svg";

import "./infoCard.scss";

export interface InfoCardProps extends React.HTMLProps<HTMLDivElement> {
  icon?: string;
  materialIcon?: string | IconProps;
  contentAside?: React.ReactNode;
  vertical?: boolean;
}

const iconClassName = "block-info-card__icon";

export const InfoCard: React.FC<InfoCardProps> = ({
  icon,
  materialIcon,
  vertical,
  contentAside,
  children,
  ...props
}) => (
  <div className="block-info-card" {...props}>
    {icon || materialIcon ? (
      <IconRenderer
        icon={icon}
        materialIcon={materialIcon}
        className={iconClassName}
      />
    ) : (
      <Svg className={iconClassName} content={ArrowLeft} />
    )}
    <div
      className={`block-info-card__content ${
        (vertical && "block-info-card__content--vertical") || ""
      }`}
    >
      {children}
    </div>
    {contentAside}
  </div>
);
