"use client";

import * as React from "react";
import classNames from "classnames";
import { Button } from "@natera/form";
import { Card, CardProps } from "@natera/material/lib/card";
import useMeasure from "react-use-measure";
import { ResizeObserver } from "@juggle/resize-observer";
import { defineMessages, useIntl } from "react-intl";
import CollapseIcon from "@natera/patient/assets/svg/icons/collapse-icon.svg";
import ExpandIcon from "@natera/patient/assets/svg/icons/expand-icon.svg";
import { useCardStyle, useRightSideExtendedStyle } from "./animation";
import { IconProps } from "@natera/material/lib/icon";

import "./testCard.scss";

const changeStatesDelay = 500;

export interface TestCardProps
  extends Omit<CardProps, "title" | "header" | "outlined"> {
  title: React.ReactNode;
  description: React.ReactNode;
  bottomLine: React.ReactNode;
  children: React.ReactNode;
  childrenExpanded?: React.ReactNode;
  expandable?: boolean;
  materialExpandIcon?: string | IconProps;
  materialCollapseIcon?: string | IconProps;
}

export const messages = defineMessages({
  expand: {
    id: "test.card.expand",
    defaultMessage: "Expand",
  },
  collapse: {
    id: "test.card.collapse",
    defaultMessage: "Collapse",
  },
});

export const TestCard: React.FunctionComponent<TestCardProps> = ({
  title,
  description,
  bottomLine,
  childrenExpanded,
  children,
  expandable = true,
  materialExpandIcon,
  materialCollapseIcon,
  ...rest
}) => {
  const [ref, bounds] = useMeasure({ polyfill: ResizeObserver });
  const refSideRightSide = React.useRef<HTMLDivElement>(null);
  const refSideRightSideExpanded = React.useRef<HTMLDivElement>(null);

  const [expanded, setExpanded] = React.useState<boolean>(false);
  const [transitionAnimation, setTransitionAnimation] =
    React.useState<boolean>(false);

  const [heightRightSide, setHeightRightSide] = React.useState(0);
  const [heightRightSideExpanded, setHeightRightSideExpanded] =
    React.useState(0);
  const [widthRightSide, setWidthRightSide] = React.useState(0);

  const intl = useIntl();

  React.useEffect(() => {
    if (
      expandable &&
      refSideRightSide &&
      refSideRightSide.current &&
      !transitionAnimation &&
      !expanded
    ) {
      setHeightRightSide(refSideRightSide.current.scrollHeight);
      setWidthRightSide(refSideRightSide.current.scrollWidth);
    }
  });

  React.useEffect(() => {
    if (
      expandable &&
      refSideRightSideExpanded &&
      refSideRightSideExpanded.current &&
      !transitionAnimation
    ) {
      setHeightRightSideExpanded(refSideRightSideExpanded.current.scrollHeight);
    }
  });

  const toggleExpandedView = () => {
    setExpanded((expanded) => !expanded);
    setTransitionAnimation(true);
    setTimeout(() => setTransitionAnimation(false), changeStatesDelay);
  };

  //Animation
  const cardStyle = useCardStyle({
    expandable,
    expanded,
    transitionAnimation,
    heightRightSide,
    heightRightSideExpanded,
  });
  const rightSideExtendedStyle = useRightSideExtendedStyle({
    expandable,
    expanded,
    boundsWidth: bounds.width,
    heightRightSideExpanded,
    widthRightSide,
    heightRightSide,
  });

  return (
    <Card
      {...rest}
      ref={ref}
      style={cardStyle}
      className={classNames("test-card", {
        "test-card--expanding": expanded && transitionAnimation,
        "test-card--expanded": expanded && !transitionAnimation,
        "test-card--collapsing": !expanded && transitionAnimation,
      })}
    >
      <div className="test-card__stretch-container">
        <div className="test-card__left-side">
          <div className="test-card__left-side-content">
            <div className="test-card__title">{title}</div>
            <div className="test-card__description">{description}</div>
          </div>
          <div className="test-card__bottom-line">{bottomLine}</div>
        </div>
      </div>
      <div ref={refSideRightSide} className="test-card__right-side">
        {children}
      </div>
      {expandable && (
        <div
          ref={refSideRightSideExpanded}
          style={rightSideExtendedStyle}
          className="test-card__right-side--expanded"
        >
          {childrenExpanded}
        </div>
      )}
      {expandable && !transitionAnimation && (
        <div className="test-card__expand-button-wrapper">
          <Button
            className="test-card__expand-button"
            icon={
              !materialCollapseIcon && !materialExpandIcon
                ? expanded
                  ? CollapseIcon
                  : ExpandIcon
                : undefined
            }
            materialIcon={expanded ? materialCollapseIcon : materialExpandIcon}
            onClick={toggleExpandedView}
            aria-label={
              expanded
                ? intl.formatMessage(messages.collapse)
                : intl.formatMessage(messages.expand)
            }
          />
        </div>
      )}
    </Card>
  );
};
