"use client";

import { Input, FormFieldContext } from "@natera/form";
import classnames from "classnames";
import * as React from "react";

import AttachIcon from "@natera/platform/assets/svg/icons/attach.svg";
import AttachmentSelect from "./attachmentSelect";
import { IconProps, IconRenderer } from "@natera/material/lib/icon";

import "./attachment.scss";

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  className?: string;
  label?: React.ReactNode;
  onFileSelect?: (files: File[]) => void;
  menu?: React.ReactNode;
  materialAttachmentIcon?: string | IconProps;
  materialDropdownIcon?: string | IconProps;
}

export const AttachmentButton = React.forwardRef<HTMLInputElement, Props>(
  (
    {
      className,
      label = "Attach",
      menu,
      onFileSelect,
      disabled = false,
      accept,
      materialAttachmentIcon,
      materialDropdownIcon,
      ...props
    },
    ref: React.RefObject<HTMLInputElement>,
  ) => {
    const formFieldContext = React.useContext(FormFieldContext);

    const isEditable = formFieldContext.isEditable();
    const isDisabled = disabled || formFieldContext.isDisabled();

    const changeHandler: React.ChangeEventHandler<HTMLInputElement> = (
      event,
    ) => {
      if (onFileSelect && event.currentTarget.files) {
        onFileSelect(Array.from(event.currentTarget.files));
      }

      // We need to clear value on order to allow re-upload of same file
      event.currentTarget.value = "";
    };

    if (!isEditable) {
      return <></>;
    }

    if (menu) {
      return (
        <AttachmentSelect
          ref={ref}
          accept={accept}
          label={label}
          isDisabled={isDisabled}
          changeHandler={changeHandler}
          className={classnames(className)}
          materialAttachmentIcon={materialAttachmentIcon}
          materialDropdownIcon={materialDropdownIcon}
          items={menu}
        />
      );
    }

    return (
      <label
        aria-disabled={isDisabled}
        className={classnames(
          className,
          "attachment-button",
          "mdc-button",
          "mdc-button--outlined",
          { "mdc-button--disabled": isDisabled },
        )}
      >
        <div className="mdc-button__ripple" />
        <IconRenderer
          icon={!materialAttachmentIcon ? AttachIcon : undefined}
          materialIcon={materialAttachmentIcon}
          className="mdc-button__icon"
          aria-hidden="true"
        />
        <span className="attachment-button-text">{label}</span>
        <Input
          {...props}
          tabIndex={0}
          ref={ref}
          accept={accept}
          type="file"
          onChange={changeHandler}
          className="attachment-button-input"
          disabled={isDisabled}
        />
      </label>
    );
  },
);

export default AttachmentButton;
