import { MDCLineRippleAdapter } from "@material/line-ripple";

export const createLineRippleAdapter = (
  lineRippleRef: React.RefObject<HTMLDivElement | null | undefined>,
): MDCLineRippleAdapter => ({
  addClass: (className) => {
    lineRippleRef.current?.classList.add(className);
  },
  removeClass: (className) => {
    lineRippleRef.current?.classList.remove(className);
  },
  hasClass: (className: string) =>
    lineRippleRef.current
      ? lineRippleRef.current.classList.contains(className)
      : false,
  setStyle: (propertyName, value) =>
    lineRippleRef.current?.style.setProperty(propertyName, value),
  registerEventHandler: (eventType, handler) => {
    lineRippleRef.current?.addEventListener(eventType, handler);
  },
  deregisterEventHandler: (eventType, handler) => {
    lineRippleRef.current?.addEventListener(eventType, handler);
  },
});
