import * as React from "react";
import classnames from "classnames";

export const SearchbarItemTitle: React.FunctionComponent<
  React.HTMLProps<HTMLDivElement>
> = ({ children, className, width = "auto", ...props }) => {
  return (
    <div
      style={{ width: width, minWidth: width }}
      className={classnames(className, "searchbar-item__title")}
      {...props}
    >
      {children}
    </div>
  );
};

export default SearchbarItemTitle;
