"use client";

import * as React from "react";
import { getMultiSelectGroupContext } from "@natera/form";
import { MultiSelectGroup } from "./group";
import { GroupOption } from "./option";

export interface MultiSelectRenderOptionProps<T> {
  items: T[];
  groupBy?: (item: T) => string | undefined;
  renderOption?: (option: T) => React.ReactElement;
  renderGroup?: (group?: string) => React.ReactElement;
}

const RenderOption = <T extends object>({
  items,
  groupBy,
  renderGroup,
  renderOption,
}: MultiSelectRenderOptionProps<T>): JSX.Element => {
  const multiselectGroupController = React.useContext(
    getMultiSelectGroupContext(),
  );
  const grouped = multiselectGroupController.getGroups();
  const unGrouped = multiselectGroupController.getUngroupedOptions();

  if (!renderOption) {
    return <React.Fragment />;
  }

  if (renderGroup && groupBy) {
    return (
      <React.Fragment>
        {grouped.map((group) => (
          <MultiSelectGroup<T>
            key={group}
            className="multiselect__option--grouped"
            group={group}
            renderGroup={renderGroup}
            renderOption={renderOption}
          />
        ))}
        {unGrouped.map((item: T, index) => (
          <GroupOption<T>
            ungrouped
            item={item}
            key={index}
            className="multiselect__option--grouped"
            renderOption={renderOption}
          />
        ))}
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      {items.map((item, index) => (
        <GroupOption<T>
          ungrouped
          key={index}
          item={item}
          renderOption={renderOption}
        />
      ))}
    </React.Fragment>
  );
};

export default RenderOption;
