"use client";

import * as R from "ramda";
import * as React from "react";

export type BasePermission = string;
export type UserRole = string;

type IsAuthorized = () => boolean;
type HasPermission = <P extends BasePermission>(...permissions: P[]) => boolean;
type HasAnyPermission = <P extends BasePermission>(
  ...permissions: P[]
) => boolean;

interface BaseUser {
  role: UserRole;
}

export interface SecurityController {
  isAuthorized: IsAuthorized;
  hasPermission: HasPermission;
  hasAnyPermission: HasAnyPermission;
}

export const SecurityContext = React.createContext<SecurityController>({
  isAuthorized: () => false,
  hasPermission: () => false,
  hasAnyPermission: () => false,
});

interface Props {
  currentUser?: BaseUser;
  getPermissions: GetPermissions;
  children?: React.ReactNode;
}

type GetPermissions = (role: UserRole) => BasePermission[];

export const SecurityProvider: React.FunctionComponent<Props> = ({
  currentUser,
  getPermissions,
  children,
}) => {
  const isAuthorized: IsAuthorized = React.useCallback(
    () => Boolean(currentUser),
    [currentUser],
  );

  const hasPermission: HasPermission = React.useCallback(
    (...permissions) => {
      if (!permissions.length) {
        return true;
      }

      if (!currentUser) {
        return false;
      }

      const grantedPermissions = getPermissions(currentUser.role);

      return R.all(
        (permission) => R.includes(permission, grantedPermissions),
        permissions,
      );
    },
    [currentUser, getPermissions],
  );

  const hasAnyPermission: HasPermission = React.useCallback(
    (...permissions) => {
      if (!permissions.length) {
        return true;
      }

      if (!currentUser) {
        return false;
      }

      const grantedPermissions = getPermissions(currentUser.role);

      return R.any(
        (permission) => R.includes(permission, grantedPermissions),
        permissions,
      );
    },
    [currentUser, getPermissions],
  );

  const securityController: SecurityController = React.useMemo(
    () => ({
      isAuthorized,
      hasPermission,
      hasAnyPermission,
    }),
    [isAuthorized, hasPermission, hasAnyPermission],
  );

  return (
    <SecurityContext.Provider value={securityController}>
      {children}
    </SecurityContext.Provider>
  );
};
