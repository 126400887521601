"use client";

import { Button } from "@natera/material/lib/button";
import { IconProps, IconRenderer } from "@natera/material/lib/icon";
import cn from "classnames";
import React, { FC } from "react";
import { FormattedDate, useIntl } from "react-intl";
import Current from "../../../../assets/svg/icons/current.svg";
import { useTimeslots } from "../../timeslots/TimeslotsContext";

import "./dayLabel.scss";

interface DesktopDayProps {
  current: boolean;
  day: number | Date;
  materialCurrentDayIcon?: string | IconProps;
}

interface DayLabelProps extends DesktopDayProps {
  mobile?: boolean;
  disabled?: boolean;
}

const DesktopDay: FC<DesktopDayProps> = ({
  current,
  day,
  materialCurrentDayIcon,
}) => (
  <div style={{ display: "flex", alignItems: "center" }}>
    <IconRenderer
      icon={!materialCurrentDayIcon ? Current : undefined}
      materialIcon={materialCurrentDayIcon}
      style={{
        visibility: current ? undefined : "hidden",
      }}
      className={"mobile-phlebotomy__timeslots__current"}
    />
    <div className="mobile-phlebotomy__timeslots__day">
      <FormattedDate value={day} weekday="short" timeZone="UTC" />
      ,&nbsp;
      <FormattedDate value={day} day="numeric" timeZone="UTC" />
    </div>
  </div>
);

const MobileDay: FC<DayLabelProps> = ({ day, disabled }) => {
  const { updateDay, focusDay } = useTimeslots();
  const isActive = Number(focusDay) === Number(day);
  const intl = useIntl();

  const formattedDateShort = intl.formatDate(day, {
    weekday: "short",
    timeZone: "UTC",
  });

  const formattedDateLong = intl.formatDate(day, {
    weekday: "long",
    timeZone: "UTC",
  });

  const formattedDateNumeric = intl.formatDate(day, {
    day: "numeric",
    timeZone: "UTC",
  });

  return (
    <div>
      <div className="timeslots__weekday">
        <span className="timeslots__weekday_long-date--hidden">
          {formattedDateLong}
        </span>
        <span aria-hidden>{formattedDateShort}</span>
      </div>

      <Button
        outlined
        onClick={() => updateDay(new Date(day))}
        disabled={disabled}
        aria-disabled={disabled}
        role="radio"
        aria-checked={isActive}
        aria-label={formattedDateNumeric}
        className={cn("timeslots__day", { "timeslots__day--active": isActive })}
      >
        <span>{formattedDateNumeric}</span>
      </Button>
    </div>
  );
};

export const DayLabel: FC<DayLabelProps> = ({
  current,
  day,
  mobile,
  materialCurrentDayIcon,
  ...mobileProps
}) =>
  mobile ? (
    <MobileDay {...mobileProps} day={day} current={current} />
  ) : (
    <DesktopDay
      day={day}
      current={current}
      materialCurrentDayIcon={materialCurrentDayIcon}
    />
  );
