"use client";

import { Corner, Menu, MenuItem } from "@natera/material/lib/menu";
import classnames from "classnames";
import * as React from "react";
import { PhoneField } from "@natera/form";

import { DropDownIcon } from "@natera/material/lib/select";

import HomePhoneIcon from "@natera/material/assets/svg/home.svg";
import MobilePhoneIcon from "@natera/platform/assets/svg/icons/mobile.svg";
import { defineMessages, useIntl } from "react-intl";
import { MenuController } from "@natera/material/lib/menu/controller";
import { IconProps, IconRenderer } from "@natera/material/lib/icon";

import "./phoneSelector.scss";

export enum PhoneType {
  HOME = "HOME",
  MOBILE = "MOBILE",
}

export interface PhoneSelectorProps
  extends Omit<React.HTMLProps<HTMLDivElement>, "ref"> {
  disabled?: boolean;
  onPhoneChange: (phone: string) => void;
  onTypeChange: (phone: PhoneType) => void;
  phone: string;
  type: PhoneType;
  materialHomePhoneIcon?: string | IconProps;
  materialMobilePhoneIcon?: string | IconProps;
  floating?: boolean;
}

export const messages = defineMessages({
  home: {
    id: "platform.phoneSelector.home",
    defaultMessage: "Home Phone Number",
    description: "Phone Type",
  },
  mobile: {
    id: "platform.phoneSelector.mobile",
    defaultMessage: "Mobile Phone Number",
    description: "Phone Type",
  },
});

export const PhoneSelector = React.forwardRef<
  HTMLDivElement,
  React.PropsWithChildren<PhoneSelectorProps>
>(
  (
    {
      phone,
      type,
      onPhoneChange,
      onTypeChange,
      disabled,
      materialHomePhoneIcon,
      materialMobilePhoneIcon,
      id,
      ...rest
    },
    ref: React.RefObject<HTMLInputElement>,
  ) => {
    const intl = useIntl();
    const anchorRef = React.useRef<HTMLDivElement>(null);
    const menuRef = React.useRef<HTMLDivElement>(null);

    const typeChange = React.useCallback(
      (type$: PhoneType) => () => onTypeChange(type$),
      [],
    );

    const scaleMenuWidth = React.useCallback(() => {
      if (menuRef.current && anchorRef?.current) {
        menuRef.current.style.width = `${anchorRef?.current.offsetWidth}px`;
      }
    }, []);

    const onKeydownHandlerTypeChange = React.useCallback(
      (type$: PhoneType) => (e: React.KeyboardEvent) => {
        if (e.key === "Enter" || e.key === " " || e.key === "Spacebar") {
          onTypeChange(type$);
        }
      },
      [],
    );

    const onKeydownHandlerMenu = React.useCallback(
      (ctrl: MenuController) => (e: React.KeyboardEvent) => {
        if (e.key === "Enter" || e.key === " " || e.key === "Spacebar") {
          ctrl.toggleMenu();
          e.stopPropagation();
        }
      },
      [],
    );

    return (
      <Menu
        {...rest}
        ref={menuRef}
        anchorRef={anchorRef}
        corner={Corner.BOTTOM_LEFT}
        onOpen={scaleMenuWidth}
        menu={
          <div>
            <MenuItem
              onClick={typeChange(PhoneType.HOME)}
              onKeyDown={onKeydownHandlerTypeChange(PhoneType.HOME)}
            >
              <IconRenderer
                icon={!materialHomePhoneIcon ? HomePhoneIcon : undefined}
                materialIcon={materialHomePhoneIcon}
                className="phone-container__type-icon"
              />
              <span
                className="phone-container__type-text"
                title={intl.formatMessage(messages.home)}
              >
                {intl.formatMessage(messages.home)}
              </span>
            </MenuItem>
            <MenuItem
              onClick={typeChange(PhoneType.MOBILE)}
              onKeyDown={onKeydownHandlerTypeChange(PhoneType.MOBILE)}
            >
              <IconRenderer
                icon={!materialHomePhoneIcon ? MobilePhoneIcon : undefined}
                materialIcon={materialMobilePhoneIcon}
                className="phone-container__type-icon"
              />

              <span
                className="phone-container__type-text"
                title={intl.formatMessage(messages.mobile)}
              >
                {intl.formatMessage(messages.mobile)}
              </span>
            </MenuItem>
          </div>
        }
      >
        {(ctrl) => {
          return (
            <div
              className={classnames("phone-container", {
                "phone-container__disabled": disabled,
              })}
              ref={anchorRef}
            >
              <PhoneField
                name="phone__number"
                value={phone}
                onPhoneChange={onPhoneChange}
                ref={ref}
                id={id}
              />
              <div
                className="phone-container__items"
                onClick={disabled ? undefined : ctrl.toggleMenu}
                tabIndex={0}
                onKeyDown={onKeydownHandlerMenu(ctrl)}
              >
                <IconRenderer
                  icon={
                    !materialHomePhoneIcon && !materialMobilePhoneIcon
                      ? type === PhoneType.MOBILE
                        ? MobilePhoneIcon
                        : HomePhoneIcon
                      : undefined
                  }
                  materialIcon={
                    type === PhoneType.MOBILE
                      ? materialMobilePhoneIcon
                      : materialHomePhoneIcon
                  }
                />
                <DropDownIcon focused={ctrl.isOpen()} />
              </div>
            </div>
          );
        }}
      </Menu>
    );
  },
);

export default PhoneSelector;
