import { DateRange, addToRange } from "react-day-picker";

export const readDate = (date?: string): Date | undefined => {
  if (!date) {
    return undefined;
  }

  const date$ = date.split("T")[0];
  const [year, month, day] = date$.split("-");

  return new Date(Number(year), Number(month) - 1, Number(day));
};

export const writeDate = (date?: Date): string => {
  if (!date) {
    return "";
  }
  const year = date.getFullYear();
  const month = `00${String(date.getMonth() + 1)}`.slice(-2);
  const day = `00${String(date.getDate())}`.slice(-2);

  return `${year}-${month}-${day}`;
};

export const getUSLocaleDate = (date: Date | undefined): string => {
  if (date && date instanceof Date && !isNaN(date.getTime())) {
    // IE 11 can't detect timezone for Intl
    // For fix need set date with timeZone: "UTC"
    const fixDate = new Date(
      Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()),
    );
    return new Intl.DateTimeFormat("en-US", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      timeZone: "UTC",
    }).format(fixDate);
  }
  return "";
};

export const getUSLocaleDateRange = (range: DateRange): string =>
  range?.from && range?.to
    ? `${getUSLocaleDate(range?.from)} - ${getUSLocaleDate(range?.to)}`
    : "";

export const isDayFirstOfMonth = (day$: Date): boolean => day$.getDate() === 1;

export const isDayLastOfMonth = (day$: Date): boolean => {
  const lastDay = new Date(
    day$.getFullYear(),
    day$.getMonth() + 1,
    0,
  ).getDate();
  return lastDay === day$.getDate();
};

export const isAfter = (date1: Date, date2: Date): boolean => date1 > date2;
export const isBefore = (date1: Date, date2: Date): boolean => date1 < date2;

export const isSameDay$ = (date1: Date, date2: Date): boolean =>
  date1.getFullYear() === date2.getFullYear() &&
  date1.getMonth() === date2.getMonth() &&
  date1.getDate() === date2.getDate();

export const isFuture = (date: Date): boolean => date > new Date();
export const isPast = (date: Date): boolean => date < new Date();

export const addMonths = (date: Date, months: number): Date => {
  const newDate = new Date(date);
  newDate.setMonth(date.getMonth() + months);
  return newDate;
};

export const startOfMonth = (date: Date): Date => {
  const newDate = new Date(date);
  newDate.setDate(1);
  newDate.setHours(0, 0, 0, 0);
  return newDate;
};

function isWithinInterval(date: Date, startDate: Date, endDate: Date): boolean {
  return startDate.getTime() < endDate.getTime()
    ? date.getTime() > startDate.getTime() && date.getTime() < endDate.getTime()
    : date.getTime() < startDate.getTime() &&
        date.getTime() > endDate.getTime();
}
export const isSameDay = (date1?: Date, date2?: Date): boolean =>
  Boolean(date1 && date2 && isSameDay$(date1, date2));
export const isDayBefore = (date1?: Date, date2?: Date): boolean =>
  Boolean(date1 && date2 && isBefore(date1, date2));
export const isDayAfter = (date1?: Date, date2?: Date): boolean =>
  Boolean(date1 && date2 && isAfter(date1, date2));
export const isDayBetween = (
  date1?: Date,
  date2?: Date,
  date3?: Date,
): boolean =>
  Boolean(date1 && date2 && date3 && isWithinInterval(date1, date2, date3));

export const addDayToRange = (date: Date, range: DateRange): DateRange => {
  const newRange = addToRange(date, range) as DateRange;

  if (isDayBefore(newRange?.to, newRange?.from)) {
    return { from: newRange?.to, to: newRange?.from };
  }
  return newRange;
};

export const getBeginningOfDay = (date: Date): Date =>
  new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0);
export const getEndOfDay = (date: Date): Date =>
  new Date(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59);

type Months = [
  string,
  string,
  string,
  string,
  string,
  string,
  string,
  string,
  string,
  string,
  string,
  string,
];

const monthDates = [
  new Date(1970, 0),
  new Date(1970, 1),
  new Date(1970, 2),
  new Date(1970, 3),
  new Date(1970, 4),
  new Date(1970, 5),
  new Date(1970, 6),
  new Date(1970, 7),
  new Date(1970, 8),
  new Date(1970, 9),
  new Date(1970, 10),
  new Date(1970, 11),
];

export const getLocalizedMonths = (format: (date: Date) => string): Months => {
  return monthDates.map((month) => {
    const localizedMonth = format(month);
    return localizedMonth.charAt(0).toUpperCase() + localizedMonth.slice(1);
  }) as Months;
};

export const cloneDate = (date: Date): Date => {
  return new Date(date.getTime());
};

export const getLocalizedMonthTitle = (date: Date, locale: string): string => {
  const localizedTitle = Intl.DateTimeFormat(locale, {
    month: "long",
    year: "numeric",
  }).format(date);

  return localizedTitle.charAt(0).toUpperCase() + localizedTitle.slice(1);
};
