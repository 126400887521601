"use client";

import React from "react";
import Button from "@natera/material/lib/button";
import { getTypeAheadContext } from "@natera/form";
import { defineMessages, useIntl } from "react-intl";
import ExclamationIcon from "../../assets/svg/exclamationIcon.svg";
import { IconProps, IconRenderer } from "@natera/material/lib/icon";

interface SearchbarErrorProps {
  message: string;
  description?: string;
  materialExclamationIcon?: string | IconProps;
}

export const messages = defineMessages({
  errorButtonText: {
    id: "form.searchbar.errorButtonText",
    defaultMessage: "Refresh",
  },
});

const SearchbarError: React.FC<SearchbarErrorProps> = ({
  message,
  description,
  materialExclamationIcon,
}) => {
  const intl = useIntl();
  const searchbarController = React.useContext(getTypeAheadContext());

  const handleButtonClick = () => {
    searchbarController.refresh();
  };

  return (
    <div className="searchbar-error-container">
      <div className="searchbar-error-icon">
        <IconRenderer
          icon={!materialExclamationIcon ? ExclamationIcon : undefined}
          materialIcon={materialExclamationIcon}
        />
      </div>
      <span className="searchbar-error-message">{message}</span>
      <span className="searchbar-error-description">{description}</span>
      <div className="searchbar-error-button">
        <Button onClick={handleButtonClick} outlined>
          {intl.formatMessage(messages.errorButtonText)}
        </Button>
      </div>
    </div>
  );
};

export default SearchbarError;
