"use client";

import Chip from "@natera/material/lib/chip";
import { Spinner } from "@natera/material/lib/progress";
import classnames from "classnames";
import * as React from "react";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";
import { FormContext, FormFieldContext } from "@natera/form";
import CancelIcon from "@natera/platform/assets/svg/icons/cancel.svg";
import ErrorIcon from "@natera/platform/assets/svg/icons/error.svg";
import FileIcon from "@natera/platform/assets/svg/icons/file.svg";
import { IconProps, IconRenderer } from "@natera/material/lib/icon";

import { AttachmentId } from "./attachmentContext";

import "./attachment.scss";

type AttachmentFile = Pick<File, "name" | "size">;

export const messages = defineMessages({
  byte: {
    id: "platform.attachment.byte",
    defaultMessage: `{size} {size, plural,
        one {byte}
        other {bytes}
      }`,
  },
  kilobyte: {
    id: "platform.attachment.kilobyte",
    defaultMessage: "{size} KB",
  },
  megabyte: {
    id: "platform.attachment.megabyte",
    defaultMessage: "{size} MB",
  },
  loading: {
    id: "platform.attachment.loading",
    defaultMessage: "Loading...",
  },
  removeItem: {
    id: "platform.attachment.removeItem",
    defaultMessage: "Remove",
  },
});

interface InputProps {
  file?: AttachmentFile;
  id?: AttachmentId;
  className?: string;
  loading?: boolean;
  disabled?: boolean;
  error?: string;
  isEditable?: boolean;
  onItemClick?: (id: AttachmentId) => void;
  onReset?: (id: AttachmentId) => void;
  materialLeadIcon?: string | IconProps;
  materialTrailingIcon?: string | IconProps;
}

export const AttachmentItem: React.FunctionComponent<InputProps> = ({
  className,
  file,
  id,
  disabled,
  error,
  isEditable: editable = true,
  loading = false,
  onItemClick,
  onReset,
  materialLeadIcon,
  materialTrailingIcon,
  ...props
}) => {
  const intl = useIntl();

  const getFileSizeLabel = (size: number): string => {
    return size < 1024
      ? intl.formatMessage(messages.byte, { size })
      : size < 1024 * 1024
        ? intl.formatMessage(messages.kilobyte, {
            size: (size / 1024).toFixed(),
          })
        : intl.formatMessage(messages.megabyte, {
            size: (size / (1024 * 1024)).toFixed(),
          });
  };

  const formContext = React.useContext(FormContext);
  const formFieldContext = React.useContext(FormFieldContext);

  const isEditable = editable && formFieldContext.isEditable();
  const isDisabled = loading || formFieldContext.isDisabled();

  const removeItem = React.useCallback(
    (event: React.MouseEvent) => {
      event.stopPropagation();
      event.preventDefault();

      if (onReset && id) {
        onReset(id);
      }

      formContext.setFormChanged();
    },
    [onReset, file, id],
  );

  const onKeydownHandlerRemoveItem = React.useCallback(
    (event: React.KeyboardEvent) => {
      if (
        event.key === "Enter" ||
        event.key === " " ||
        event.key === "Spacebar"
      ) {
        event.stopPropagation();
        event.preventDefault();

        if (onReset && id) {
          onReset(id);
        }
      }
    },
    [onReset, file, id],
  );

  const onClickHandler = React.useCallback(
    (event: React.MouseEvent) => {
      event.stopPropagation();
      event.preventDefault();

      if (id && !loading && onItemClick) {
        onItemClick(id);
      }
    },
    [onItemClick, id, loading],
  );

  const loadingText = loading ? <FormattedMessage {...messages.loading} /> : "";

  return (
    <div
      id={id ? String(id) : undefined}
      className={classnames(className, "attachment-item", {
        "attachment-item-editable": isEditable,
      })}
    >
      <Chip
        {...props}
        onClick={onClickHandler}
        error={Boolean(error)}
        disabled={disabled}
        leadingIcon={
          <>
            {loading && <Spinner className="attachment-item-loading" />}
            {!loading && (
              <IconRenderer
                icon={
                  !materialLeadIcon ? (error ? ErrorIcon : FileIcon) : undefined
                }
                materialIcon={materialLeadIcon}
                className={classnames("attachment-item-icon", {
                  "attachment-item-icon__error": error,
                })}
              />
            )}
          </>
        }
        trailingIcon={
          isEditable &&
          !isDisabled && (
            <IconRenderer
              icon={!materialTrailingIcon ? CancelIcon : undefined}
              materialIcon={materialTrailingIcon}
              className="attachment-item-tools"
              onClick={removeItem}
              onKeyDown={onKeydownHandlerRemoveItem}
              tabIndex={0}
              aria-label={intl.formatMessage(messages.removeItem)}
            />
          )
        }
      >
        <div
          className={classnames("attachment-item-name", {
            "attachment-item-name__error": error,
            "attachment-item-name__link": id && !loading,
            "attachment-item-name__disabled": disabled,
          })}
          title={file?.name}
        >
          {file?.name || loadingText}
        </div>
        {!loading && !error && file && Boolean(file.size) && (
          <div className="attachment-item-size">
            {getFileSizeLabel(file?.size)}
          </div>
        )}
      </Chip>
      {error && <div className="attachment-item-error-message">{error}</div>}
    </div>
  );
};

export default AttachmentItem;
