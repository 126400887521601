"use client";

import {
  TimePicker as BaseTimePicker,
  TimePickerProps,
} from "@natera/platform/lib/components/timePicker";
import classnames from "classnames";
import React, { forwardRef, RefObject } from "react";
import { FormattedTime } from "react-intl";
import { DisplayField, FormFieldContext } from "@natera/form";

export const TimePicker = forwardRef<HTMLInputElement, TimePickerProps>(
  (
    {
      value,
      defaultValue,
      disabled,
      invalid,
      required,
      className,
      timeValue,
      ...props
    },
    ref: RefObject<HTMLInputElement>,
  ) => {
    const fieldContext = React.useContext(FormFieldContext);
    const isDisabled = disabled || fieldContext.isDisabled();
    const hasError = invalid || fieldContext.hasError();
    const isRequired =
      required === false ? false : required || fieldContext.isRequired();

    if (!fieldContext.isEditable()) {
      return (
        <DisplayField
          id={props.id}
          value={
            timeValue ? (
              <FormattedTime
                value={timeValue}
                hour="2-digit"
                minute="2-digit"
              />
            ) : (
              <>&mdash;</>
            )
          }
        />
      );
    }

    return (
      <BaseTimePicker
        ref={ref}
        value={value}
        defaultValue={defaultValue}
        disabled={isDisabled}
        required={isRequired}
        invalid={hasError}
        timeValue={timeValue}
        className={classnames(className, "mdc-form-field")}
        {...props}
      />
    );
  },
);

export default TimePicker;
