import React, { FC } from "react";
import { IconProps, IconRenderer } from "@natera/material/lib/icon";

export interface LoadIconProps {
  icon?: React.ReactNode;
  materialIcon?: string | IconProps;
}

const LeadIcon: FC<LoadIconProps> = ({ icon, materialIcon }) => {
  return (
    <IconRenderer
      icon={icon}
      materialIcon={materialIcon}
      className="mdc-text-field__icon mdc-text-field__icon--leading"
      role={typeof materialIcon !== "string" ? "button" : undefined}
    />
  );
};

export default React.memo(LeadIcon);
