"use client";

import React, { FC } from "react";
import { IntlShape, useIntl } from "react-intl";
import { DateRange } from "../../definitions";

interface SelectedSlotTimeProps {
  slot: DateRange;
  mobile?: boolean;
}

export const getSelectedSlotTime = (
  slot: DateRange,
  intl: IntlShape,
  mobile?: boolean,
): string => {
  const weekday = intl.formatDate(new Date(slot.start), {
    weekday: "short",
    timeZone: "UTC",
  });

  const shortDate = intl.formatDate(new Date(slot.end), {
    timeZone: "UTC",
    day: "numeric",
    month: mobile ? "numeric" : "long",
  });

  const slotTime = `${shortDate}, ${getChipTime(slot, intl)}`;
  return mobile ? slotTime : `${weekday}, ${slotTime}`;
};

export const getChipTime = (slot: DateRange, intl: IntlShape): string => {
  const slotStart = intl.formatTime(new Date(slot.start), {
    hour: "numeric",
    timeZone: "UTC",
  });

  const slotEnd = intl.formatTime(new Date(slot.end), {
    hour: "numeric",
    timeZone: "UTC",
  });

  return `${slotStart} — ${slotEnd}`;
};

const SelectedSlotTime: FC<SelectedSlotTimeProps> = ({
  slot,
  mobile = false,
}) => {
  const intl = useIntl();

  const selectedSlotTime = getSelectedSlotTime(slot, intl, mobile);

  return <>{selectedSlotTime}</>;
};

export default SelectedSlotTime;
