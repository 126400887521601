"use client";

import React from "react";
import { useComponentState } from "@natera/material/lib/hooks";
import { DayModifiers } from "react-day-picker";
import { BaseDayPicker, BaseDayPickerProps } from "./base";

import "./../date.scss";

export interface DayPickerProps extends BaseDayPickerProps {
  date?: Date;
  defaultDate?: Date;
  onDateChange?: (date: Date | undefined) => void;
  enableOutsideDaysClick?: boolean;
}

export const DayPicker: React.FunctionComponent<DayPickerProps> = ({
  date,
  defaultDate,
  onDateChange,
  numberOfMonths = 1,
  showOutsideDays = false,
  enableOutsideDaysClick = false,
  defaultMonthYear = new Date(),
  ...props
}) => {
  const [currentMonth, setCurrentMonth] = React.useState<Date | undefined>(
    new Date(defaultMonthYear),
  );

  const [day, setDay] = useComponentState<Date | undefined>({
    controlledValue: date,
    defaultValue: defaultDate,
    onChange: (day$) => {
      if (onDateChange) {
        onDateChange(day$);
      }
    },
  });

  const onDayClick = React.useCallback(
    (
      day$: Date,
      { disabled, outside }: DayModifiers,
      e: React.MouseEvent<HTMLButtonElement>,
    ) => {
      if (disabled) {
        return;
      }

      if (!outside) {
        setDay(day$);
        return;
      }

      if (showOutsideDays && enableOutsideDaysClick) {
        setCurrentMonth(day$);
        e.currentTarget.blur();
      }
    },
    [setDay, onDateChange],
  );

  return (
    <BaseDayPicker
      numberOfMonths={numberOfMonths}
      selected={day}
      defaultMonthYear={currentMonth}
      onDayClick={onDayClick}
      showOutsideDays={showOutsideDays}
      enableOutsideDaysClick={enableOutsideDaysClick}
      {...props}
    />
  );
};

export default DayPicker;
