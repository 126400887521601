"use client";

import {
  RuntimeConfigurationService,
  RuntimeConfigurationServiceInterface,
} from "@natera/platform/lib/service/runtimeConfiguration";

import * as React from "react";
interface Props {
  children?: React.ReactNode;
}

export const RuntimeConfigurationContext =
  React.createContext<RuntimeConfigurationServiceInterface>({
    getConfig: () => {
      throw Error(
        "RuntimeConfigurationContext must be used within a RuntimeConfigurationProvider",
      );
    },
  });

export const RuntimeConfigurationProvider: React.FunctionComponent<Props> = ({
  children,
}) => {
  const runtimeConfigurationService = React.useMemo(
    () => new RuntimeConfigurationService(),
    [],
  );

  return (
    <RuntimeConfigurationContext.Provider value={runtimeConfigurationService}>
      {children}
    </RuntimeConfigurationContext.Provider>
  );
};
