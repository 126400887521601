"use client";

import React from "react";
import { defineMessages, useIntl } from "react-intl";

interface Props {
  children: React.ReactNode;
}

export const messages = defineMessages({
  filtersTitle: {
    id: "form.searchbar.filtersTitle",
    defaultMessage: "Filters",
  },
});

const SearchbarFilters: React.FC<Props> = ({ children }) => {
  const intl = useIntl();

  return (
    <div className="searchbar-filters">
      <span className="searchbar-filters__label">
        {intl.formatMessage(messages.filtersTitle)}
      </span>
      <div className="searchbar-filters__list">{children}</div>
    </div>
  );
};

export default SearchbarFilters;
