import React, { HTMLAttributes } from "react";
import { Svg } from "@natera/material/lib/svg";
import { Icon, IconProps } from "../icon";

export type MaterialIconType = string | IconProps;

interface MaterialIconRendererProps extends HTMLAttributes<HTMLElement> {
  materialIcon?: MaterialIconType;
}

interface IconRendererProps extends HTMLAttributes<HTMLElement> {
  icon?: React.ReactNode;
  materialIcon?: MaterialIconType;
}

export const MaterialIconRenderer: React.FC<MaterialIconRendererProps> = ({
  materialIcon,
  ...restProps
}) => {
  if (materialIcon) {
    if (typeof materialIcon === "string") {
      return <Icon {...restProps}>{materialIcon}</Icon>;
    } else {
      return <Icon {...materialIcon} {...restProps} />;
    }
  }
  return null;
};

export const IconRenderer: React.FC<IconRendererProps> = ({
  icon,
  materialIcon,
  ...rest
}) => {
  if (icon) {
    if (typeof icon === "string") return <Svg content={icon} {...rest} />;
    else return <>{icon}</>;
  }

  return <MaterialIconRenderer materialIcon={materialIcon} {...rest} />;
};
