"use client";

import {
  DateStringInput as BaseDateStringInput,
  DateStringInputProps,
  Utils,
} from "@natera/material/lib/date";
import { FormContext, DisplayField, FormFieldContext } from "@natera/form";
import * as R from "ramda";

import classnames from "classnames";
import * as React from "react";

export { DateStringInputProps } from "@natera/material/lib/date";

export const DateStringInput: React.FunctionComponent<DateStringInputProps> = ({
  value,
  defaultValue,
  onDateChange = R.always(undefined),
  disabled,
  invalid,
  required,
  className,
  ...props
}) => {
  const fieldContext = React.useContext(FormFieldContext);
  const { setFormChanged } = React.useContext(FormContext);

  const isDisabled = disabled || fieldContext.isDisabled();
  const hasError = invalid || fieldContext.hasError();
  const isRequired =
    required === false ? false : required || fieldContext.isRequired();

  // TODO: use intl instead
  const displayValue = Utils.getUSLocaleDate(
    Utils.readDate(value || defaultValue),
  );

  const dateChangeHandler = (date$: string) => {
    onDateChange(date$);
    setFormChanged();
  };

  return fieldContext.isEditable() ? (
    <BaseDateStringInput
      className={classnames(className, "mdc-form-field")}
      value={value}
      defaultValue={defaultValue}
      onDateChange={dateChangeHandler}
      disabled={isDisabled}
      required={isRequired}
      invalid={hasError}
      {...props}
    />
  ) : (
    <DisplayField {...props} value={displayValue || <>&mdash;</>} />
  );
};

export default DateStringInput;
