"use client";

import classnames from "classnames";
import * as R from "ramda";
import * as React from "react";
import { v4 as uuidv4 } from "uuid";

import { MDCMenuFoundation } from "@material/menu";
import { MDCMenuSurfaceFoundation } from "@material/menu-surface";
import { MDCSelectFoundation } from "@material/select";
import DropdownIcon from "@natera/material/assets/svg/dropdown.svg";
import { useComponentState } from "@natera/material/lib/hooks";
import { List } from "@natera/material/lib/list";
import {
  MenuSurface,
  MenuSurfaceAnchor,
  MenuWidth,
} from "@natera/material/lib/menu";
import {
  MDCFloatingLabelFoundation,
  cssClasses as floatingLabelCssClasses,
} from "@material/floating-label";
import { MDCLineRippleFoundation } from "@material/line-ripple";
import { MDCNotchedOutlineFoundation } from "@material/notched-outline";
import Svg from "@natera/material/lib/svg";
import { getNormalizedXCoordinate } from "./helpers";
import { createAdapter } from "./adapter";
import { createFloatingLabelAdapter } from "../floatingLabel/adapter";
import { createLineRippleAdapter } from "../lineRipple/adapter";
import { createNotchedOutlineAdapter } from "../notchedOutline/adapter";
import { IconProps, IconRenderer } from "@natera/material/lib/icon";

import "./select.scss";

export type SelectValueType = string | string[] | number;

export interface SelectController {
  onItemAction: (element: HTMLElement) => void;
  hasLeadingIcon: boolean;
}

export interface SelectProps
  extends Omit<React.HTMLProps<HTMLDivElement>, "ref"> {
  value?: SelectValueType;
  disabled?: boolean;
  required?: boolean;
  defaultValue?: SelectValueType;
  onValueChange?: (value: SelectValueType) => void;
  className?: string;
  name?: string;
  title?: string;
  placeholder?: string;
  leadIcon?: React.ReactNode;
  materialLeadIcon?: string | IconProps;
  dropdownIcon?: string;
  materialDropdownIcon?: string | IconProps;
  outlined?: boolean;
  filled?: boolean;
  plain?: boolean;
  dense?: boolean;
  denseMenu?: boolean;
  children?: React.ReactNode | React.ReactNode[];
  surfaceStyle?: React.CSSProperties;
  labelId?: string;
  label?: string;
  helperText?: React.ReactNode;
  useDefaultValidation?: boolean;
  validate?: (value: string) => boolean;
  floating?: boolean;
}

export const SelectContext = React.createContext<SelectController>({
  onItemAction: () => undefined,
  hasLeadingIcon: false,
});

export const Select = React.forwardRef<
  HTMLDivElement,
  React.PropsWithChildren<SelectProps>
>(
  (
    {
      children,
      className,
      disabled = false,
      required = false,
      placeholder,
      defaultValue,
      onValueChange,
      value,
      name,
      title,
      leadIcon,
      materialLeadIcon,
      dropdownIcon,
      materialDropdownIcon,
      outlined,
      filled,
      plain,
      dense,
      denseMenu,
      surfaceStyle,
      role,
      labelId,
      label,
      id,
      helperText,
      useDefaultValidation = false,
      validate,
      floating,
      ...props
    },
    ref,
  ) => {
    const [uuid, setUuid] = React.useState<string>();
    React.useEffect(() => {
      setUuid(uuidv4());
    }, []);

    const selectRef = (ref ||
      React.useRef<HTMLDivElement>(null)) as React.RefObject<HTMLDivElement>;
    const selectAnchorRef = React.useRef<HTMLDivElement | null>(null);
    const selectedTextRef = React.useRef<HTMLDivElement>();
    const listRef = React.useRef<HTMLUListElement>(null);
    const surfaceRef = React.useRef<HTMLDivElement>();
    const surfaceFoundationRef = React.useRef<MDCMenuSurfaceFoundation>();
    const selectFoundationRef = React.useRef<MDCSelectFoundation>();

    const labelWidth = React.useRef(0);
    const labelRef = React.useRef<HTMLLabelElement | null>(null);
    const labelFoundationRef: React.MutableRefObject<MDCFloatingLabelFoundation | null> =
      React.useRef(null);

    const lineRippleRef = React.useRef<HTMLDivElement | null>(null);
    const lineRippleFoundationRef: React.MutableRefObject<MDCLineRippleFoundation | null> =
      React.useRef(null);

    const notchRef = React.useRef<HTMLDivElement | null>(null);
    const notchRootRef = React.useRef<HTMLDivElement | null>(null);
    const notchFoundationRef: React.MutableRefObject<MDCNotchedOutlineFoundation | null> =
      React.useRef(null);

    const [selectedItemText, setSelectedItemText] = React.useState<
      string | null
    >(null);

    const [inputValue, setInputValue] = useComponentState<
      SelectValueType | undefined
    >({
      controlledValue: value,
      defaultValue,
      onChange: onValueChange,
    });

    // have to use ref to value be available inside Adapter closures
    const inputValueRef = React.useRef<string>();
    React.useEffect(() => {
      inputValueRef.current = R.isNil(inputValue) ? "" : String(inputValue);
    }, [inputValue]);

    const getDomItems = React.useCallback((): HTMLElement[] => {
      if (!listRef.current) {
        return [];
      }
      const items = listRef.current.querySelectorAll<HTMLElement>(
        '.mdc-deprecated-list-item[role="option"]',
      );

      return Array.from(items);
    }, []);

    const getDomItemAtIndex = React.useCallback(
      (index: number): HTMLElement | undefined => {
        const items = getDomItems();

        return items[index];
      },
      [],
    );

    const selectChangeHandler = React.useCallback((value$: SelectValueType) => {
      if (inputValueRef.current !== value$) {
        setInputValue(value$);
      }
    }, []);

    React.useEffect(() => {
      if (labelRef.current) {
        labelWidth.current = Math.ceil(
          labelRef.current?.getBoundingClientRect().width || 0,
        );

        if (required) {
          labelWidth.current = labelWidth.current + 10;
        }
      }
      selectFoundationRef.current?.setRequired(Boolean(required));
    }, [label, required]);

    const initFoundation = React.useCallback(() => {
      if (!children && !placeholder) {
        return;
      }

      if (!surfaceRef.current) {
        return;
      }

      if (!selectAnchorRef.current) {
        return;
      }

      if (!selectedTextRef.current) {
        return;
      }

      if (!surfaceFoundationRef.current) {
        return;
      }

      if (selectFoundationRef.current) {
        return;
      }

      const labelAdapter = createFloatingLabelAdapter(labelRef, labelWidth);
      const labelFoundation = new MDCFloatingLabelFoundation(labelAdapter);
      labelFoundation.init();
      labelFoundationRef.current = labelFoundation;

      const lineAdapter = createLineRippleAdapter(lineRippleRef);
      const lineFoundation = new MDCLineRippleFoundation(lineAdapter);
      lineFoundation.init();
      lineRippleFoundationRef.current = lineFoundation;

      const notchAdapter = createNotchedOutlineAdapter({
        root: notchRootRef,
        notch: notchRef,
      });
      const notchFoundation = new MDCNotchedOutlineFoundation(notchAdapter);
      notchFoundation.init();
      notchFoundationRef.current = notchFoundation;

      const mdcAnchorElem = selectAnchorRef.current;
      const mdcSelectedText = selectedTextRef.current;

      const foundation = new MDCSelectFoundation(
        createAdapter({
          mdcAnchorElem,
          mdcSelectedText,
          surfaceFoundation: surfaceFoundationRef.current,
          selectRef,
          selectChangeHandler,
          selectAnchorRef,
          setSelectedItemText,
          getDomItemAtIndex,
          getDomItems,
          label,
          labelFoundation,
          outline: outlined,
          lineFoundation,
          notchFoundation,
          inputValue,
          placeholder,
          onValueChange,
        }),
      );
      selectFoundationRef.current = foundation;

      selectFoundationRef.current.setDisabled(Boolean(disabled));
      selectFoundationRef.current.setRequired(Boolean(required));
      selectFoundationRef.current.setUseDefaultValidation(
        Boolean(useDefaultValidation),
      );
      if (validate) {
        selectFoundationRef.current?.setValid(
          validate(inputValueRef.current || ""),
        );
      }
      selectFoundationRef.current?.setValue(
        R.isNil(inputValue) ? "" : String(inputValue),
      );

      foundation.init();
    }, [value, placeholder, disabled, Boolean(children)]);

    const setSurfaceRef = React.useCallback(
      (element: HTMLDivElement) => {
        surfaceRef.current = element;
        initFoundation();
      },
      [initFoundation],
    );

    const setSelectAnchorRef = React.useCallback(
      (element: HTMLDivElement) => {
        selectAnchorRef.current = element;
        initFoundation();
      },
      [initFoundation],
    );

    const setSelectedTextRef = React.useCallback(
      (element: HTMLDivElement) => {
        selectedTextRef.current = element;
        initFoundation();
      },
      [initFoundation],
    );

    const setSurfaceFoundationRef = React.useCallback(
      (element: MDCMenuSurfaceFoundation) => {
        surfaceFoundationRef.current = element;
        initFoundation();
      },
      [initFoundation],
    );

    React.useEffect(() => {
      initFoundation();
      return function cleanup() {
        selectFoundationRef.current?.destroy();
        selectFoundationRef.current = undefined;
        labelFoundationRef.current?.destroy();
        labelFoundationRef.current = null;
        lineRippleFoundationRef.current?.destroy();
        lineRippleFoundationRef.current = null;
        notchFoundationRef.current?.destroy();
        notchFoundationRef.current = null;
      };
    }, [value, label, outlined, placeholder, disabled, Boolean(children)]);

    React.useEffect(() => {
      selectFoundationRef.current?.init();
      const res = getDomItems().find(
        (item) => item.getAttribute("data-value") === inputValue?.toString(),
      );
      setSelectedItemText(
        res?.textContent || (!label ? placeholder : null) || null,
      );
    }, [label, children]);

    React.useEffect(() => {
      selectFoundationRef.current?.setUseDefaultValidation(
        Boolean(useDefaultValidation),
      );
    }, [useDefaultValidation]);
    React.useEffect(() => {
      selectFoundationRef.current?.setDisabled(Boolean(disabled));
    }, [disabled]);

    React.useEffect(() => {
      const nextValue = R.isNil(inputValue) ? "" : String(inputValue);
      if (validate) {
        selectFoundationRef.current?.setValid(validate(nextValue));
      }
      selectFoundationRef.current?.setValue(nextValue);
    }, [value, validate]);

    const selectAnchorFocusHandler = React.useCallback(() => {
      selectFoundationRef.current?.handleFocus();
    }, []);

    const selectAnchorBlurHandler = React.useCallback(() => {
      selectFoundationRef.current?.handleBlur();
    }, []);

    const normalizedXCoordinates = React.useCallback(
      (event: React.MouseEvent | React.TouchEvent) => {
        return getNormalizedXCoordinate(event);
      },
      [],
    );

    const selectAnchorClickHandler: React.MouseEventHandler = (event) => {
      selectRef.current?.focus();
      selectFoundationRef.current?.handleClick(normalizedXCoordinates(event));
    };

    const selectAnchorKeydownHandler: React.KeyboardEventHandler =
      React.useCallback((event) => {
        selectFoundationRef.current?.handleKeydown(event.nativeEvent);
      }, []);

    const menuClosedHandler = React.useCallback(() => {
      selectFoundationRef.current?.handleMenuClosed();
      selectAnchorRef.current?.focus();
    }, []);
    const menuOpenedHandler = React.useCallback(
      () => selectFoundationRef.current?.handleMenuOpened(),
      [],
    );

    const menuItemActionHandler = React.useCallback((element: HTMLElement) => {
      selectFoundationRef.current?.handleMenuItemAction(
        getDomItems().indexOf(element),
      );
    }, []);

    const selectController = React.useMemo<SelectController>(
      () => ({
        onItemAction: menuItemActionHandler,
        hasLeadingIcon: Boolean(leadIcon || materialLeadIcon),
      }),
      [],
    );

    return (
      <>
        <div
          {...props}
          className={classnames(
            className,
            MDCSelectFoundation.cssClasses.ROOT,
            {
              "mdc-select--no-label": !label,
              "mdc-select--filled": !outlined,
              "mdc-select--plain": plain,
              // here we introduced "mdc-select--custom-filled" class to distinguish between default, filled, and outlined
              // because material.io only has either filled or outlined, but not unfilled and outlined option
              "mdc-select--custom-filled": filled,
              "mdc-select--dense": dense,
              [MDCSelectFoundation.cssClasses.DISABLED]: disabled,
              [MDCSelectFoundation.cssClasses.REQUIRED]: required,
              [MDCSelectFoundation.cssClasses.OUTLINED]: outlined,
              [MDCSelectFoundation.cssClasses.WITH_LEADING_ICON]: Boolean(
                leadIcon || materialLeadIcon,
              ),
            },
          )}
          ref={selectRef}
        >
          <MenuSurfaceAnchor
            className="mdc-select__anchor"
            aria-disabled={disabled}
            aria-required={required}
            aria-expanded={false}
            aria-labelledby={labelId || `select-title-id_${uuid}`}
            aria-controls={`helper-text-${uuid}`}
            aria-describedby={`helper-text-${uuid}`}
            ref={setSelectAnchorRef}
            role={role}
            onFocus={selectAnchorFocusHandler}
            onBlur={selectAnchorBlurHandler}
            onClick={selectAnchorClickHandler}
            onKeyDown={selectAnchorKeydownHandler}
          >
            {label && !outlined && (
              <>
                <div ref={lineRippleRef} className="mdc-line-ripple" />
                <label
                  htmlFor={`select-title-id_${uuid}`}
                  className={floatingLabelCssClasses.ROOT}
                  ref={labelRef}
                >
                  {label}
                </label>
              </>
            )}
            {outlined && (
              <div className="mdc-notched-outline" ref={notchRootRef}>
                <div className="mdc-notched-outline__leading"></div>
                <div className="mdc-notched-outline__notch" ref={notchRef}>
                  <label
                    htmlFor={`select-title-id_${uuid}`}
                    className={floatingLabelCssClasses.ROOT}
                    ref={labelRef}
                  >
                    {label}
                  </label>
                </div>
                <div className="mdc-notched-outline__trailing"></div>
              </div>
            )}
            {(leadIcon || materialLeadIcon) && (
              <div
                className="mdc-select__icon mdc-select__icon--leading"
                role="button"
              >
                <IconRenderer icon={leadIcon} materialIcon={materialLeadIcon} />
              </div>
            )}
            <span className="mdc-select__selected-text-container">
              <span
                id={`select-title-id_${uuid}`}
                className="mdc-select__selected-text"
                ref={setSelectedTextRef}
                title={title}
              >
                {selectedItemText
                  ? selectedItemText
                  : !label
                    ? placeholder
                    : undefined}
              </span>
            </span>
            <span className="mdc-select__dropdown-icon">
              <i className="mdc-select__dropdown-icon-inactive">
                {materialDropdownIcon || dropdownIcon ? (
                  <IconRenderer
                    icon={dropdownIcon}
                    materialIcon={materialDropdownIcon}
                  />
                ) : (
                  <Svg content={DropdownIcon} />
                )}
              </i>
              <i className="mdc-select__dropdown-icon-active">
                {materialDropdownIcon || dropdownIcon ? (
                  <IconRenderer
                    icon={dropdownIcon}
                    materialIcon={materialDropdownIcon}
                  />
                ) : (
                  <Svg content={DropdownIcon} />
                )}
              </i>
            </span>
            <input
              name={name}
              hidden={true}
              readOnly={true}
              id={id}
              className="mdc-select__native-control"
              value={inputValue || ""}
            />
          </MenuSurfaceAnchor>
          <MenuSurface
            style={surfaceStyle}
            anchorRef={selectAnchorRef}
            surfaceFoundationRef={
              !floating ? surfaceFoundationRef : setSurfaceFoundationRef
            }
            className={classnames(
              "mdc-select__menu",
              MDCMenuFoundation.cssClasses.ROOT,
            )}
            role="listbox"
            ref={setSurfaceRef}
            onOpen={menuOpenedHandler}
            onClose={menuClosedHandler}
            menuWidth={MenuWidth.FLOATING_WIDTH}
            floating={floating}
          >
            <SelectContext.Provider value={selectController}>
              <List
                ref={listRef}
                tabIndex={-1}
                dense={denseMenu !== undefined ? denseMenu : dense}
              >
                {children}
              </List>
            </SelectContext.Provider>
          </MenuSurface>
        </div>
        {helperText && (
          <p
            id={`helper-text-${uuid}`}
            className={classnames("mdc-select-helper-text")}
          >
            {helperText}
          </p>
        )}
      </>
    );
  },
);

export default Select;
