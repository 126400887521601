import React from "react";
import { MenuController } from "@natera/material/lib/menu";
import { IconProps } from "../../icon";

const iconClickHandler = (
  event: React.MouseEvent<HTMLElement, MouseEvent>,
  menu: MenuController,
) => {
  if (!menu.isOpen()) {
    menu.openMenu();
  } else {
    menu.closeMenu();
    event.currentTarget.blur();
  }
};

export const getMaterialTrailingIcon = (
  materialTrailingIcon?: string | IconProps,
  label?: string,
  menu?: MenuController,
) => {
  if (materialTrailingIcon && label) {
    if (typeof materialTrailingIcon === "string") {
      return {
        className: "mdc-text-field__icon-button",
        tabIndex: 0,
        role: "button",
        onClick: (event: React.MouseEvent<HTMLElement, MouseEvent>) =>
          menu ? iconClickHandler(event, menu) : undefined,
        children: materialTrailingIcon,
      };
    }
    return materialTrailingIcon;
  }
  return materialTrailingIcon;
};
