import React from "react";
import { TypeAheadController } from "@natera/form";
import { MenuController } from "@natera/platform/lib/components/menu";

export const useKeydownHandler = (
  allowType: boolean,
  onKeyDown: (event: React.KeyboardEvent<HTMLInputElement>) => void,
  typeahead: TypeAheadController<object>,
  menuController: MenuController,
  minQuery?: number,
  onEnterPress?: (event: React.KeyboardEvent<HTMLInputElement>) => void,
): React.KeyboardEventHandler<HTMLInputElement> => {
  return React.useCallback(
    (event) => {
      if (
        !menuController.isOpen() &&
        (event.key === "ArrowDown" ||
          event.key === "ArrowUp" ||
          event.key === "Enter")
      ) {
        menuController.openMenu();
      }
      if (menuController.isOpen() && event.key === "Escape") {
        menuController.closeMenu();
      }
      if (event.key === "ArrowDown" || event.key === "ArrowUp") {
        menuController.focus();
        event.preventDefault();
      }

      if (event.key === "Enter" && event.currentTarget.value) {
        if (onEnterPress) {
          if (minQuery && event.currentTarget?.value?.length >= minQuery) {
            onEnterPress(event);
            menuController.closeMenu();
            event.currentTarget.blur();
          }
        } else {
          onKeyDown(event);

          const inputValueLength = typeahead.getInputValue().length;
          const optionsItemsLength = typeahead
            .getOptionsCollection()
            .getItems().length;

          if (
            !(minQuery && inputValueLength < minQuery) &&
            optionsItemsLength
          ) {
            const firstOption = typeahead.getOptionsCollection().getItems()[0];
            typeahead.selectOption(firstOption);
            menuController.closeMenu();
          }
        }
      }
    },
    [allowType, onKeyDown, typeahead, menuController],
  );
};
