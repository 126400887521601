import {
  AccessToken,
  AuthPollStopError,
  AuthSdkError,
  AuthnTransaction as BaseAuthnTransaction,
  isAuthApiError,
  OAuthError,
  OktaAuth,
  OktaAuthOptions,
  SessionObject,
  Tokens,
  UserClaims,
} from "@okta/okta-auth-js";
import { Subject } from "rxjs";
import {
  OktaIdentity,
  OktaTransactionWrapper,
  TransactionStatus,
} from "./okta";

export {
  AccessToken,
  AuthnTransaction,
  OktaAuthOptions,
} from "@okta/okta-auth-js";

const NATERA_DOMAIN = "natera.com";
const AUTH_TOKEN = "AUTH_TOKEN";
const POLL_DELAY = 3000;
export enum FactorErrorCodes {
  REJECTED_CODE = "E0000073",
  EXPIRED_CODE = "E0000083",
}
enum FactorResultEnum {
  TIMEOUT = "TIMEOUT",
  REJECTED = "REJECTED",
}

export interface Credentials {
  email: string;
  password: string;
}

export interface CredentialsIdentityProvider {
  idp: string;
  clientId: string;
  issuer: string;
  scope: string[];
}

export interface Factor extends Record<string, unknown> {
  factorType: string;
  provider: string;
}

interface AuthnTransaction extends BaseAuthnTransaction {
  data?: Record<string, unknown>;
}
type StartService = () => void;
type StopService = () => void;
type GetAuthClient = () => OktaAuth;
type GetOktaConfig = () => OktaAuthOptions;
type GetToken = () => Promise<AccessToken | undefined>;
type ClearToken = () => Promise<void>;
type GetUserData<U extends OktaIdentity> = () => Promise<U | null>;
type Login = (credentials: Credentials) => Promise<OktaTransactionWrapper>;
type LoginIdentityProvider = (
  credentialsIdentityProvider: CredentialsIdentityProvider,
) => Promise<void>;
type Logout = () => Promise<void>;
type VerifyMFA = (
  factor: Factor,
  passCode: string | number,
  rememberDevice?: boolean,
) => Promise<OktaTransactionWrapper>;
type ChallengeMFA = (
  passCode: string | number,
  rememberDevice?: boolean,
) => Promise<OktaTransactionWrapper | undefined>;
type ActivateMFA = (factor: Factor) => Promise<void>;
type ActivateEnroll = (passCode?: string | number) => Promise<void>;
type EnrollFactor = (
  factor: Factor,
) => Promise<OktaTransactionWrapper | undefined>;
type RenewToken = () => Promise<AccessToken>;
type ForgotPassword = (email: string) => Promise<AuthnTransaction>;
type ChangeExpiredPassword = (
  oldPassword: string,
  newPassword: string,
) => Promise<void>;
type GetTokenSubject = () => Subject<AccessToken | undefined>;
type HasExpiredPasswordTransaction = () => boolean;
type ResendFactor = (factorType: string) => Promise<void>;
type ChallengePush = (
  passCode: string | number,
  autoPush?: () => boolean,
  rememberDevice?: () => boolean,
) => Promise<OktaTransactionWrapper | undefined>;
type PrevFactor = () => Promise<void>;

export interface SessionServiceInterface<U extends OktaIdentity> {
  getAuthClient: GetAuthClient;
  getOktaConfig: GetOktaConfig;
  getToken: GetToken;
  clearToken: ClearToken;
  getUserData: GetUserData<U>;
  login: Login;
  loginIdentityProvider: LoginIdentityProvider;
  logout: Logout;
  renewToken: RenewToken;
  forgotPassword: ForgotPassword;
  changeExpiredPassword: ChangeExpiredPassword;
  getTokenSubject: GetTokenSubject;
  hasExpiredPasswordTransaction: HasExpiredPasswordTransaction;
  verifyMFA: VerifyMFA;
  activateMFA: ActivateMFA;
  challengeMFA: ChallengeMFA;
  enrollFactor: EnrollFactor;
  activateEnroll: ActivateEnroll;
  resendFactor: ResendFactor;
  prevFactor: PrevFactor;
  start: StartService;
  stop: StopService;
}

export default abstract class SessionService<U extends OktaIdentity>
  implements SessionServiceInterface<U>
{
  public abstract getUserData: GetUserData<U>;
  private redirectUri = window.location.origin;

  private readonly authClient: OktaAuth;
  private readonly oktaCustomScopes: string[];
  private readonly tokenSubject = new Subject<AccessToken | undefined>();
  private currentTransaction: AuthnTransaction;

  constructor(
    readonly oktaConfig: OktaAuthOptions,
    oktaApplicationScopes: string[] = ["prospera_api"],
  ) {
    this.authClient = this.createAuthClient(oktaConfig);
    this.oktaCustomScopes = oktaApplicationScopes;
  }

  public start: StartService = () => {
    return this.authClient.start();
  };

  public stop: StopService = () => {
    return this.authClient.stop();
  };

  public getAuthClient: GetAuthClient = () => {
    return this.authClient;
  };

  public getOktaConfig: GetOktaConfig = () => {
    return this.oktaConfig;
  };

  public getTokenSubject: GetTokenSubject = () => {
    return this.tokenSubject;
  };

  public tryRestoreSession = async () => {
    try {
      const client = this.authClient;
      const exists = await client.session.exists();

      if (!exists) {
        return false;
      }

      // TODO: revise the type
      const session = (await client.session.get()) as SessionObject & {
        id: string;
      };

      if (!session) {
        return false;
      }

      const token = await this.getTokens(session?.id);

      if (token?.accessToken) {
        await this.setToken(token.accessToken);
      }

      return true;
    } catch (error) {
      return false;
    }
  };

  public getToken: GetToken = async () => {
    try {
      const client = this.authClient;
      let token = await client.tokenManager.get(AUTH_TOKEN);

      const autoRenew = client.tokenManager.getOptions()?.autoRenew;
      if (autoRenew && token && client.tokenManager.hasExpired(token)) {
        token = await this.renewToken();
      }

      return token as AccessToken;
    } catch (error) {
      // The user no longer has an existing SSO session in the browser.
      // (OIDC error `login_required`)
      // Ask the user to authenticate again.
      return undefined;
    }
  };

  public renewToken: RenewToken = async () => {
    const client = this.authClient;
    const newToken = (await client.tokenManager.renew(
      AUTH_TOKEN,
    )) as AccessToken;
    if (newToken) {
      this.setToken(newToken);
    } else {
      this.clearToken();
    }

    return newToken;
  };

  public clearToken: ClearToken = async () => {
    const client = this.authClient;
    client.tokenManager.clear();
    this.tokenSubject.next(undefined);
  };

  public login: Login = async (credentials) => {
    try {
      const transaction = await this.authClient.signInWithCredentials({
        password: credentials.password,
        username: credentials.email,
      });
      return await this.handleTransactionStatus(transaction);
    } catch (error) {
      throw this.parseError(error);
    }
  };

  public loginIdentityProvider: LoginIdentityProvider = async (
    credentialsIdentityProvider,
  ) => {
    try {
      const config = this.oktaConfig || {};
      const client = await this.authClient;
      const { tokens } = await client.token.getWithPopup({
        redirectUri: this.redirectUri,
        responseType: "token",
        /* scopes like ["openid", "profile", "patient-service_api"],*/
        ...config,
        ...credentialsIdentityProvider,
      });

      if (tokens.accessToken) {
        this.setToken(tokens.accessToken);
      } else {
        this.clearToken();
      }
    } catch (error) {
      throw this.parseError(error);
    }
  };

  public logout: Logout = async () => {
    const client = this.authClient;
    const accessToken = await this.getToken();
    try {
      await client.revokeAccessToken(accessToken);
      await client.closeSession();
    } catch (error) {
      throw this.parseError(error);
    } finally {
      await this.clearToken();
    }
  };

  public activateMFA: ActivateMFA = async (factor) => {
    try {
      const { factorType, provider } = factor;

      const response = this.currentTransaction;

      const transaction = await response.factors
        ?.find(
          (singleFactor) =>
            singleFactor.provider === provider &&
            singleFactor.factorType === factorType,
        )
        ?.verify({});

      await this.handleTransactionStatus(transaction);
    } catch (error) {
      this.parseError(error);
    }
  };

  public challengeMFA: ChallengeMFA = async (passCode, rememberDevice) => {
    try {
      const response = this.currentTransaction;

      if (response.verify) {
        const transaction = await response.verify({
          passCode,
          rememberDevice,
        });
        return await this.handleTransactionStatus(transaction);
      }
    } catch (e) {
      throw this.parseError(e);
    }
  };

  public verifyMFA: VerifyMFA = async (factor, passCode, rememberDevice) => {
    try {
      const { factorType, provider } = factor;
      const response = this.currentTransaction;
      const transaction = await response.factors
        ?.find(
          (singleFactor) =>
            singleFactor.provider === provider &&
            singleFactor.factorType === factorType,
        )
        ?.verify({ passCode, rememberDevice });
      /* const transaction = factor.verify({ passCode });

      this comment for future, because factor in argument have a verify() function,
      but in many cases it does not work*/
      return await this.handleTransactionStatus(transaction);
    } catch (e) {
      throw this.parseError(e);
    }
  };

  public enrollFactor: EnrollFactor = async (factor) => {
    try {
      const { factorType, provider } = factor;
      const response = this.currentTransaction;
      const transaction = await response.factors
        ?.find(
          (singleFactor) =>
            singleFactor.provider === provider &&
            singleFactor.factorType === factorType,
        )
        ?.enroll();
      return await this.handleTransactionStatus(transaction);
    } catch (e) {
      this.parseError(e);
    }
  };

  public activateEnroll: ActivateEnroll = async (passCode) => {
    try {
      const current = this.currentTransaction;
      if (current.activate) {
        const transaction = await current.activate({ passCode });
        this.handleTransactionStatus(transaction);
      }
    } catch (e) {
      this.parseError(e);
    }
  };

  public challengePush: ChallengePush = async (
    passCode,
    autoPush,
    rememberDevice,
  ) => {
    try {
      const response = this.currentTransaction;

      if (response.poll) {
        const transaction = await response.poll({
          delay: POLL_DELAY,
          autoPush,
          rememberDevice,
        });
        return await this.handleTransactionStatus(transaction);
      }
    } catch (e) {
      throw this.parseError(e);
    }
  };

  public resendFactor: ResendFactor = async (factorType) => {
    try {
      const response = this.currentTransaction;

      if (response.resend) {
        const transaction = await response.resend(factorType);
        await this.handleTransactionStatus(transaction);
      }
    } catch (e) {
      throw this.parseError(e);
    }
  };

  public prevFactor: PrevFactor = async () => {
    try {
      const response = this.currentTransaction;

      if (response.prev) {
        const transaction = await response.prev();
        await this.handleTransactionStatus(transaction);
      }
    } catch (e) {
      throw this.parseError(e);
    }
  };

  public forgotPassword: ForgotPassword = async (email: string) => {
    try {
      const client = this.authClient;
      const transaction = await client.forgotPassword({
        username: email,
        factorType: "EMAIL",
      });

      return transaction;
    } catch (error) {
      throw this.parseError(error);
    }
  };

  public changeExpiredPassword: ChangeExpiredPassword = async (
    oldPassword,
    newPassword,
  ) => {
    if (
      this.currentTransaction.status === TransactionStatus.PASSWORD_EXPIRED &&
      this.currentTransaction.changePassword
    ) {
      try {
        const transaction = await this.currentTransaction.changePassword({
          stateToken: this.currentTransaction.data?.stateToken,
          oldPassword,
          newPassword,
        });
        await this.handleTransactionStatus(transaction);

        return;
      } catch (e) {
        throw this.parseError(e);
      }
    }
  };

  public hasExpiredPasswordTransaction: HasExpiredPasswordTransaction = () => {
    return (
      Boolean(this.currentTransaction) &&
      this.currentTransaction.status === TransactionStatus.PASSWORD_EXPIRED
    );
  };

  protected async getTokenData(): Promise<UserClaims | undefined> {
    const token = await this.getToken();
    if (!token) {
      return undefined;
    }

    const client = this.authClient;
    const { payload } = client.token.decode(token.accessToken);

    return payload;
  }

  private createAuthClient = (config: OktaAuthOptions): OktaAuth => {
    return new OktaAuth({
      redirectUri: this.redirectUri,
      ...config,
    });
  };

  private setToken = async (token: AccessToken) => {
    const client = this.authClient;
    client.tokenManager.add(AUTH_TOKEN, token);
    this.tokenSubject.next(token);
  };

  private getTokens = async (sessionToken: string): Promise<Tokens> => {
    const client = this.authClient;
    const response = await client.token.getWithoutPrompt({
      responseType: "token", // or array of types
      scopes: ["openid", "profile", ...this.oktaCustomScopes],
      sessionToken,
    });

    const token = response?.tokens;

    if (!token) {
      throw new Error("Authentication error: no tokens granted");
    }

    if (!token?.accessToken || !token?.accessToken?.accessToken) {
      throw new Error("Authentication error: no Access Token granted");
    }

    return token;
  };

  private handleTransactionStatus = async (
    transaction: AuthnTransaction,
  ): Promise<OktaTransactionWrapper> => {
    // TODO: remove global state mutation
    this.currentTransaction = transaction;
    const nateraTransaction: OktaTransactionWrapper = {
      transaction,
      success: false,
    };

    // TODO: additional behavior for statuses on next versions
    switch (transaction.status) {
      case TransactionStatus.SUCCESS: {
        nateraTransaction.success = true;
        if (!transaction.sessionToken) {
          throw new Error("No session token granted");
        }
        const token = await this.getTokens(transaction.sessionToken);
        if (token?.accessToken) {
          await this.setToken(token.accessToken);
        }
        if (token?.idToken) {
          nateraTransaction.idToken = token?.idToken;
        }
        break;
      }
      case TransactionStatus.PASSWORD_EXPIRED:
        if (this.isLDAPUser(transaction)) {
          throw new Error(
            "Your password has expired. Please contact an administrator to reset your password.",
          );
        }
        nateraTransaction.redirect = "/change-expired-password";
        nateraTransaction.error =
          "Your password has expired and must be changed";
        break;
      case TransactionStatus.LOCKED_OUT:
        if (nateraTransaction.transaction.unlock) {
          return nateraTransaction;
        } else {
          throw new Error("Account is locked");
        }

      case TransactionStatus.PASSWORD_WARN:
        if (transaction.skip) {
          const skipTransaction = await transaction.skip();
          const skipNateraTransaction =
            await this.handleTransactionStatus(skipTransaction);

          if (skipNateraTransaction.success) {
            skipNateraTransaction.warning = transaction.policy
              ? `Your password will expire in ${transaction.policy.expiration.passwordExpireDays} days. Please change it`
              : "Your password is about to expire. Please change it";

            skipNateraTransaction.redirect = this.isLDAPUser(transaction)
              ? undefined
              : "user-settings";

            return skipNateraTransaction;
          }
        }
      // eslint-disable-next-line no-fallthrough
      case TransactionStatus.PASSWORD_RESET:
      case TransactionStatus.RECOVERY:
      case TransactionStatus.RECOVERY_CHALLENGE:
      case TransactionStatus.MFA_CHALLENGE:
        if (!transaction.data?.stateToken) {
          throw new Error("No state token granted");
        } else if (
          transaction.data.factorResult === FactorResultEnum.REJECTED
        ) {
          throw new OAuthError(
            FactorErrorCodes.REJECTED_CODE,
            "You have chosen to reject this login.",
          );
        } else if (transaction.data.factorResult === FactorResultEnum.TIMEOUT) {
          throw new OAuthError(
            FactorErrorCodes.EXPIRED_CODE,
            "Your push notification has expired.",
          );
        }

        return nateraTransaction;
      case TransactionStatus.MFA_ENROLL:
        if (!transaction.data?.stateToken) {
          throw new Error("No state token granted");
        }
        if (transaction.factors) {
          return nateraTransaction;
        }
        break;
      case TransactionStatus.MFA_ENROLL_ACTIVATE:
        if (!transaction.data?.stateToken) {
          throw new Error("No state token granted");
        }

        return nateraTransaction;
      case TransactionStatus.MFA_REQUIRED:
        if (!transaction.data?.stateToken) {
          throw new Error("No state token granted");
        }

        if (transaction.factors) {
          return nateraTransaction;
        }

        break;

      default:
        throw new Error(
          "We cannot handle the " + transaction.status + " status",
        );
    }
    return nateraTransaction;
  };

  private parseError(error: Error) {
    console.warn(error);

    if (isAuthApiError(error)) {
      error.message = error.errorSummary;
      return error;
    }

    if (error instanceof AuthSdkError) {
      return new Error(error.message);
    }

    if (error instanceof OAuthError) {
      return error;
    }

    if (error instanceof AuthPollStopError) {
      return error;
    }

    return new Error("Authentication failed");
  }

  private isLDAPUser = (transaction: AuthnTransaction) =>
    transaction.user
      ? transaction.user.profile.login.endsWith(NATERA_DOMAIN)
      : false;
}
