"use client";

import {
  Button,
  ButtonProps as BaseButtonProps,
} from "@natera/material/lib/button";
import { Tooltip } from "@natera/material/lib/tooltip";
import * as R from "ramda";
import * as React from "react";
import { FormContext } from "@natera/form";
import "../form/form.scss";

export interface ButtonProps extends BaseButtonProps {
  tooltip?: React.ReactNode;
}

export const FormButton = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ disabled, tooltip, ...props }, ref) => {
    const { isDisabled } = React.useContext(FormContext);
    const disabled$ = R.isNil(disabled) ? isDisabled() : disabled;

    return tooltip ? (
      <Tooltip content={<div className="button-tooltip">{tooltip}</div>}>
        <Button ref={ref} disabled={disabled$} {...props} />
      </Tooltip>
    ) : (
      <Button ref={ref} disabled={disabled$} {...props} />
    );
  },
);
