"use client";

import {
  defaultSelectionController,
  SelectionController,
  useMultiSelection,
  useStorage,
} from "@natera/platform/lib/hooks";
import * as R from "ramda";
import * as React from "react";
import { getTypeAheadContext } from "@natera/form";

export interface MultiSelectController<T> {
  getSelection: () => SelectionController<T>;
  handleSelect: (option: T) => void;
  apply: () => void;
  reset: () => void;
  clear: () => void;
  remove: () => void;
  getSelectedItems: () => T[];
  isGroupSelected: (options: T[]) => boolean;
  isSomeSelected: (options: T[]) => boolean;
  handleGroupSelect: (options: T[]) => void;
}

export interface MultiSelectContextProps<T> {
  initialSelection?: T[];
  onSelect?: (option: T[] | undefined) => void;
  recordIndex?: (record: T) => string;
  activeSelection?: boolean;
}

const MultiSelectContext = React.createContext<MultiSelectController<unknown>>({
  getSelection: R.always(defaultSelectionController),
  handleSelect: R.always(undefined),
  apply: R.always(undefined),
  reset: R.always(undefined),
  clear: R.always(undefined),
  remove: R.always(undefined),
  getSelectedItems: R.always([]),
  isGroupSelected: R.always(false),
  isSomeSelected: R.always(false),
  handleGroupSelect: R.always(undefined),
});

export function getMultiSelectContext<T extends object>() {
  return MultiSelectContext as React.Context<MultiSelectController<T>>;
}

export const MultiSelectProvider = <T extends object>({
  initialSelection = [],
  onSelect = R.always(undefined),
  recordIndex = R.propOr("", "id"),
  activeSelection = false,
  children,
}: React.PropsWithChildren<
  MultiSelectContextProps<T>
>): React.ReactElement | null => {
  const isMounted = React.useRef(false);
  const typeaheadContext = React.useContext(getTypeAheadContext<T>());

  // This cache is needed to store selected option data independently of typeahead collection
  const itemCache = useStorage({
    initialRecords: initialSelection,
    recordIndex,
  });

  const selection = useMultiSelection({
    initialSelection,
    recordIndex,
  });

  const apply = () => {
    onSelect(getSelectedItems());
  };

  const reset = () => {
    selection.reset();
    itemCache.clear();
    itemCache.addRecords(initialSelection);
    selection.setSelection(initialSelection.map(recordIndex));
  };

  const clear = () => {
    selection.clear();
    itemCache.clear();
  };

  const remove = () => {
    onSelect([]);
  };

  const handleSelect = (option: T) => {
    selection.handleItemSelect(option);
    itemCache.addRecord(option);
  };

  const filterDisabledOptions = (options: T[]): T[] =>
    options.filter((option) => !typeaheadContext.isOptionDisabled(option));

  const isGroupSelected = (options: T[]) => {
    const availableOptions = filterDisabledOptions(options);

    return (
      availableOptions.length > 0 && selection.isAllSelected(availableOptions)
    );
  };

  const isSomeSelected = (options: T[]) =>
    selection.getSelectedItems(options).length > 0;

  const handleGroupSelect = (options: T[]) => {
    const availableOptions = filterDisabledOptions(options);

    if (selection.isAllSelected(availableOptions)) {
      availableOptions.forEach((option) => handleSelect(option));
      return;
    }

    availableOptions
      .filter((option) => !selection.isSelected(option))
      .forEach((option) => handleSelect(option));
  };

  const getSelectedItems = () =>
    selection.getSelectedItems(itemCache.getRecords());

  // TODO: this is somewhat unreliable way to publish changes upwards, we should use streams insread
  React.useEffect(() => {
    if (isMounted.current) {
      if (activeSelection) {
        apply();
      }
    } else {
      isMounted.current = true;
    }
  }, [selection.getSelection]);

  const controller: MultiSelectController<T> = {
    getSelection: R.always(selection),
    apply,
    reset,
    clear,
    remove,
    handleSelect,
    getSelectedItems,
    isGroupSelected,
    isSomeSelected,
    handleGroupSelect,
  };

  return (
    <MultiSelectContext.Provider value={controller}>
      {children}
    </MultiSelectContext.Provider>
  );
};
