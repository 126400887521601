"use client";

import * as React from "react";

type ItemActionHandler = (element: HTMLElement, shouldClose?: boolean) => void;
type HasItem = (element: HTMLElement) => boolean;
type RegisterItem = (controller: MenuItemController) => void;

export interface MenuItemController {
  getElement: () => HTMLLIElement;
  handleItemSelect: () => void;
}

export interface MenuController {
  getParentController: () => MenuController | undefined;
  toggleMenu: () => void;
  closeMenu: () => void;
  openMenu: () => void;
  isOpen: () => boolean;
  onItemAction: ItemActionHandler;
  registerItem: RegisterItem;
  hasItem: HasItem;
  focus: () => void;
}

export const defaultMenuController: MenuController = {
  getParentController: () => undefined,
  toggleMenu: () => undefined,
  closeMenu: () => undefined,
  openMenu: () => undefined,
  isOpen: () => false,
  onItemAction: () => undefined,
  registerItem: () => undefined,
  hasItem: () => false,
  focus: () => undefined,
};

export const MenuContext = React.createContext<MenuController>(
  defaultMenuController,
);

interface MenuProviderProps {
  defaultOpen?: boolean;
  onItemAction: ItemActionHandler;
  hasItem: HasItem;
  registerItem: RegisterItem;
  onClose?: () => void;
  onOpen?: () => void;
  focus: () => void;
  children: React.ReactElement;
}

export const MenuProvider: React.FunctionComponent<MenuProviderProps> = ({
  defaultOpen = false,
  children,
  onItemAction,
  hasItem,
  registerItem,
  onClose = () => undefined,
  onOpen = () => undefined,
  focus = () => undefined,
}) => {
  const parentMenuController = React.useContext(MenuContext);
  const [isOpen$, setOpen] = React.useState(defaultOpen);

  const isOpen = React.useCallback(() => isOpen$, [isOpen$]);

  const closeMenu = React.useCallback(() => {
    onClose();
    setOpen(false);
  }, [onClose]);

  const openMenu = React.useCallback(() => {
    setOpen(true);
    onOpen();
  }, [onOpen]);

  const getParentController = React.useCallback(() => parentMenuController, []);

  const toggleMenu = React.useCallback(() => {
    isOpen() ? closeMenu() : openMenu();
  }, [isOpen]);

  const handleItemAction: ItemActionHandler = React.useCallback(
    (element, shouldCloseMenu = true) => {
      if (hasItem(element)) {
        onItemAction(element);
        if (shouldCloseMenu) {
          parentMenuController.closeMenu();
        }
      } else {
        parentMenuController.onItemAction(element);
      }
    },
    [],
  );

  const handleRegister = React.useCallback((controller: MenuItemController) => {
    const element = controller.getElement();
    if (hasItem(element)) {
      registerItem(controller);
    } else {
      parentMenuController.registerItem(controller);
    }
  }, []);

  const menuController = React.useMemo<MenuController>(
    () => ({
      getParentController,
      toggleMenu,
      closeMenu,
      openMenu,
      isOpen,
      onItemAction: handleItemAction,
      registerItem: handleRegister,
      hasItem,
      focus,
    }),
    [isOpen, toggleMenu],
  );

  return (
    <MenuContext.Provider value={menuController}>
      {children}
    </MenuContext.Provider>
  );
};
