"use client";

import {
  CollectionController,
  defaultCollectionController,
  useCollection,
} from "@natera/platform/lib/hooks";
import { HttpCollection } from "@natera/platform/lib/service/httpCollection";
import * as R from "ramda";
import * as React from "react";

export type GetOptionsCollection<T> = () => CollectionController<T>;
export type GetPageLimit = () => number;
export type GetInputValue = () => string;
export type GetError = () => string;
export type IsCustomValueAllowed = () => boolean;
export type Reset = () => void;
export type Refresh = () => void;
export type SetInputValue = React.Dispatch<React.SetStateAction<string>>;
export type OnSelect<T> = (option: T | undefined) => void;
export type Clear = () => void;
export type ForceValue = () => void;

export type DisplayValue<T> = (option: T) => string;
export type IsOptionDisabled<T> = (item: T) => boolean;

export interface TypeAheadController<T> {
  getOptionsCollection: GetOptionsCollection<T>;
  getPageLimit: GetPageLimit;
  getInputValue: GetInputValue;
  getError: GetError;
  setInputValue: SetInputValue;
  isCustomValueAllowed: IsCustomValueAllowed;
  selectOption: OnSelect<T>;
  forceValue: ForceValue;
  refresh: Refresh;
  reset: Reset;
  clear: Clear;
  isOptionDisabled: IsOptionDisabled<T>;
}

export interface TypeAheadContextProps<T> {
  getOptions?: (value: string, size: number) => Promise<T[]>;
  getInitialOptions?: () => T[];
  pageLimit?: number;
  delayTime?: number;
  minQuery?: number;
  allowCustomValue?: boolean;
  selectOnFocus?: boolean;
  optionFactory?: (input: string) => T;
  displayValue?: DisplayValue<T>;
  selectedOption?: T | undefined;
  onSelect?: OnSelect<T>;
  autoSelect?: boolean;
  clearOnSelect?: boolean;
  isOptionDisabled?: IsOptionDisabled<T>;
}

const DEFAULT_PAGE_LIMIT = 100;
const DEFAULT_DELAY_TIME = 75;
const DEFAULT_MIN_QUERY = 0;

const TypeAheadContext = React.createContext<TypeAheadController<unknown>>({
  getOptionsCollection: R.always(defaultCollectionController),
  getPageLimit: R.always(DEFAULT_PAGE_LIMIT),
  getInputValue: R.always(""),
  getError: R.always(""),
  setInputValue: R.always(undefined),
  isCustomValueAllowed: R.always(false),
  selectOption: R.always(undefined),
  refresh: R.always(undefined),
  reset: R.always(undefined),
  clear: R.always(undefined),
  forceValue: R.always(undefined),
  isOptionDisabled: R.always(false),
});

export function getTypeAheadContext<T extends object>() {
  return TypeAheadContext as React.Context<TypeAheadController<T>>;
}

export const TypeAheadProvider = <T extends object>({
  getOptions,
  getInitialOptions,
  pageLimit = DEFAULT_PAGE_LIMIT,
  delayTime = DEFAULT_DELAY_TIME,
  minQuery = DEFAULT_MIN_QUERY,
  allowCustomValue = false,
  selectOnFocus = false,
  optionFactory,
  children,
  displayValue = R.always(""),
  selectedOption,
  onSelect = R.always(undefined),
  clearOnSelect = false,
  isOptionDisabled = R.always(false),
  autoSelect = false,
}: React.PropsWithChildren<
  TypeAheadContextProps<T>
>): React.ReactElement | null => {
  const [error, setError] = React.useState<string>("");
  const isMounted = React.useRef(false);
  const getDisplayValue: DisplayValue<T | undefined> = (option) => {
    if (!option) {
      return "";
    }

    return displayValue(option);
  };

  const defaultInputValue = getDisplayValue(selectedOption);
  const [inputValue$, setInputValue$] =
    React.useState<string>(defaultInputValue);

  const optionsCollection = useCollection({
    load: async () => {
      try {
        setError("");
        const results = !getOptions
          ? []
          : selectOnFocus
            ? await getOptions("", pageLimit)
            : await getOptions(inputValue$, pageLimit);

        if (allowCustomValue && inputValue$ && optionFactory) {
          const hasValue = results.some(
            (option) => displayValue(option) === inputValue$,
          );

          if (!hasValue) {
            results.unshift(optionFactory(inputValue$));
          }
        }
        return new HttpCollection(results, results.length);
      } catch (error) {
        const errorMessage = error.message || "Unknown error";
        setError(errorMessage);
        return new HttpCollection([], 0);
      }
    },
    delayTime,
    initialRecords: getInitialOptions?.(),
  });

  const refresh = () => {
    if (getOptions) {
      optionsCollection.load();
    }
  };

  const clearResource = () => {
    if (getOptions) {
      optionsCollection.getResource().clear();
    }
  };

  const clear: Clear = () => {
    clearResource();
    setInputValue$("");
  };

  const reset: Reset = () => {
    setInputValue$(getDisplayValue(selectedOption));
  };

  const selectOption: OnSelect<T> = (option) => {
    onSelect(option);

    if (clearOnSelect) {
      clear();
    }
  };

  const forceValue: ForceValue = () => {
    if (!R.isNil(selectedOption)) {
      reset();
      return;
    }

    if (allowCustomValue && optionFactory && inputValue$) {
      if (autoSelect) {
        setInputValue$(getDisplayValue(optionFactory(inputValue$)));
      } else {
        selectOption(optionFactory(inputValue$));
      }
      return;
    }

    clear();
  };

  React.useEffect(() => {
    if (isMounted.current) {
      if (minQuery) {
        if (inputValue$.length < minQuery) {
          setError("");
          clearResource();
        } else {
          refresh();
        }
      } else {
        refresh();
      }
    } else {
      isMounted.current = true;
    }
  }, [inputValue$, getOptions]);

  React.useEffect(() => {
    reset();
  }, [selectedOption]);

  const controller: TypeAheadController<T> = {
    getOptionsCollection: R.always(optionsCollection),
    getPageLimit: R.always(pageLimit),
    getInputValue: R.always(inputValue$),
    getError: R.always(error),
    setInputValue: setInputValue$,
    isCustomValueAllowed: R.always(allowCustomValue),
    isOptionDisabled,
    forceValue,
    selectOption,
    refresh,
    reset,
    clear,
  };

  return (
    <TypeAheadContext.Provider value={controller}>
      {children}
    </TypeAheadContext.Provider>
  );
};
