import * as R from "ramda";
import * as React from "react";

import {
  BasePermission,
  SecurityContext,
} from "@natera/platform/lib/provider/security";

type GetPriority<T> = (actionSubject?: T) => number;
type GetOrder = () => number;
type GetPermission<P> = () => P;
type Condition<T> = (actionObject?: T) => boolean;
type GetActionName<K> = () => K;

export interface Action<T, K, P extends BasePermission> {
  getPriority: GetPriority<T>;
  getOrder: GetOrder;
  getPermission: GetPermission<P>;
  condition: Condition<T>;
  getActionName: GetActionName<K>;
}

export type GetAvailableActions<T, K> = (
  actionSubject?: T,
  whiteList?: K[],
) => K[];

type GetUnsortedAvailableActions<T, K, P extends BasePermission> = (
  actionSubject?: T,
  whiteList?: K[],
) => Array<Action<T, K, P>>;

export interface ActionsController<T, K> {
  getAvailableActions: GetAvailableActions<T, K>;
  getPrioritizeActions: GetAvailableActions<T, K>;
}

export const useActions = <T, K, P extends BasePermission>(
  actions: Array<Action<T, K, P>>,
): ActionsController<T, K> => {
  const { hasPermission } = React.useContext(SecurityContext);

  const getPrioritizeActions: GetAvailableActions<T, K> = (
    actionSubject,
    whiteList,
  ) => {
    const availableActions = getPureAvailableActions(actionSubject, whiteList);
    return R.compose(
      R.map(R.invoker(0, "getActionName")),
      R.sortBy(R.invoker(1, "getPriority")(actionSubject)),
    )(availableActions);
  };

  const getAvailableActions: GetAvailableActions<T, K> = (
    actionSubject,
    whiteList,
  ) => {
    const availableActions = getPureAvailableActions(actionSubject, whiteList);
    return R.compose(
      R.map(R.invoker(0, "getActionName")),
      R.sortBy(R.invoker(0, "getOrder")),
    )(availableActions);
  };

  const getPureAvailableActions: GetUnsortedAvailableActions<T, K, P> = (
    actionSubject,
    whiteList,
  ) => {
    const whiteListedActions = whiteList
      ? R.filter(
          (action$) => whiteList.includes(action$.getActionName()),
          actions,
        )
      : actions;

    const hadPermissionActions = R.filter(
      (action$) => hasPermission(action$.getPermission()),
      whiteListedActions,
    );

    return R.filter(
      R.invoker(1, "condition")(actionSubject),
      hadPermissionActions,
    );
  };

  return {
    getAvailableActions,
    getPrioritizeActions,
  };
};

export default useActions;
