(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("react"), require("classnames"), require("@natera/material/lib/progress"), require("@natera/material/lib/icon"), require("@natera/material/lib/common"), require("@natera/material/lib/svg"));
	else if(typeof define === 'function' && define.amd)
		define(["react", "classnames", "@natera/material/lib/progress", "@natera/material/lib/icon", "@natera/material/lib/common", "@natera/material/lib/svg"], factory);
	else {
		var a = typeof exports === 'object' ? factory(require("react"), require("classnames"), require("@natera/material/lib/progress"), require("@natera/material/lib/icon"), require("@natera/material/lib/common"), require("@natera/material/lib/svg")) : factory(root["react"], root["classnames"], root["@natera/material/lib/progress"], root["@natera/material/lib/icon"], root["@natera/material/lib/common"], root["@natera/material/lib/svg"]);
		for(var i in a) (typeof exports === 'object' ? exports : root)[i] = a[i];
	}
})(this, function(__WEBPACK_EXTERNAL_MODULE__9155__, __WEBPACK_EXTERNAL_MODULE__992__, __WEBPACK_EXTERNAL_MODULE__2697__, __WEBPACK_EXTERNAL_MODULE__5387__, __WEBPACK_EXTERNAL_MODULE__819__, __WEBPACK_EXTERNAL_MODULE__8860__) {
return 