"use client";

import { Chip, ChipSet } from "@natera/material/lib/chip";
import { IconProps, IconRenderer } from "@natera/material/lib/icon";
import cn from "classnames";
import React, { FC } from "react";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";
import DeleteIcon from "../../../assets/svg/icons/delete.svg";
import { DateRange } from "../../definitions";
import TimeSlotPlaceholder from "../timeSlotPlaceholder/TimeSlotPlaceholder";
import { useTimeslots } from "../timeslots/TimeslotsContext";
import SelectedSlotTime, { getSelectedSlotTime } from "./SelectedSlotTime";

interface SelectedSlotsProps {
  maxSlots: number;
  selectedSlots?: DateRange[];
  onChange: (steps: DateRange[]) => void;
  mobile?: boolean;
  showSlotPlaceholder?: boolean;
  materialDeleteIcon?: string | IconProps;
}

export const messages = defineMessages({
  timeslotsMessage: {
    id: "mobilePhlebotomy.timeslots.selectSlots",
    defaultMessage: "Please select up to {maxSlots} timeslots",
    description: "Timeslots message",
  },
  timeslotRemove: {
    id: "mobilePhlebotomy.timeslots.timeslotRemove",
    defaultMessage: "Remove {timeSlot}",
    description: "Remove Timeslot",
  },
});

export const SelectedSlots: FC<SelectedSlotsProps> = ({
  maxSlots,
  selectedSlots = [],
  onChange,
  showSlotPlaceholder,
  mobile,
  materialDeleteIcon,
}) => {
  const intl = useIntl();
  const { renderSelectedSlotTime, setRemoveTimeslotAriaLabel } = useTimeslots();

  const onKeydownHandler = (slot: DateRange) => (e: React.KeyboardEvent) => {
    if (e.key === "Enter" || e.key === " " || e.key === "Spacebar") {
      onChange(selectedSlots.filter((val) => val !== slot));
    }
  };

  return (
    <>
      {!showSlotPlaceholder && selectedSlots.length === 0 && (
        <div className="mobile-phlebotomy__timeslots__empty-message">
          <FormattedMessage
            {...messages.timeslotsMessage}
            values={{ maxSlots }}
          />
        </div>
      )}
      <ChipSet
        role="list"
        className="mobile-phlebotomy__timeslots__selected-slots"
      >
        {selectedSlots.map((slot) => (
          <Chip
            role="listitem"
            tabIndex={-1}
            className={cn("mobile-phlebotomy__timeslots__selected-slot", {
              "mobile-phlebotomy__timeslots__slot--mobile": mobile,
            })}
            key={slot.start.toString()}
            trailingIcon={
              <IconRenderer
                icon={!materialDeleteIcon ? DeleteIcon : undefined}
                materialIcon={materialDeleteIcon}
                role="button"
                aria-label={
                  setRemoveTimeslotAriaLabel
                    ? setRemoveTimeslotAriaLabel(slot)
                    : intl.formatMessage(messages.timeslotRemove, {
                        timeSlot: getSelectedSlotTime(slot, intl, mobile),
                      })
                }
                tabIndex={0}
                onKeyDown={onKeydownHandler(slot)}
                onClick={() =>
                  onChange(selectedSlots.filter((val) => val !== slot))
                }
              />
            }
          >
            {renderSelectedSlotTime ? (
              renderSelectedSlotTime(slot)
            ) : (
              <SelectedSlotTime slot={slot} mobile={mobile} />
            )}
          </Chip>
        ))}
        {showSlotPlaceholder && selectedSlots.length < maxSlots && (
          <TimeSlotPlaceholder mobile={mobile} index={selectedSlots.length} />
        )}
      </ChipSet>
    </>
  );
};
