import classnames from "classnames";
import * as React from "react";
import { IconProps, IconRenderer } from "@natera/material/lib/icon";

import "./item.scss";

export interface TabItemProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  icon?: React.ReactNode;
  materialIcon?: string | IconProps;
  active?: boolean;
  stacked?: boolean;
}

export const TabItem: React.FunctionComponent<TabItemProps> = ({
  children,
  className,
  icon,
  materialIcon,
  active = false,
  stacked = false,
  ...props
}) => (
  <button
    {...props}
    tabIndex={-1}
    aria-selected={active}
    role="tab"
    className={classnames(className, "mdc-tab", {
      "mdc-tab--active": active,
      "mdc-tab--stacked": stacked,
    })}
  >
    <span className="mdc-tab__content">
      {(icon || materialIcon) && (
        <span className="mdc-tab__icon" aria-hidden="true">
          <IconRenderer icon={icon} materialIcon={materialIcon} />
        </span>
      )}
      {children ? (
        <span className="mdc-tab__text-label">{children}</span>
      ) : null}
    </span>
    <span
      className={classnames("mdc-tab-indicator", {
        "mdc-tab-indicator--active": active,
      })}
    >
      <span className="mdc-tab-indicator__content mdc-tab-indicator__content--underline" />
    </span>
    <span className="mdc-tab__ripple" />
  </button>
);

export default TabItem;
