"use client";

import {
  MaterialOption,
  MaterialOptionProps as OptionProps,
} from "@natera/material/lib/select";
import * as React from "react";
import { DisplayField } from "@natera/form";
import { SelectContext } from "./select";

export { OptionProps };

export const Option: React.FunctionComponent<OptionProps> = ({
  value,
  ...props
}) => {
  const { isEditable, getValue } = React.useContext(SelectContext);

  if (!isEditable()) {
    if (value !== getValue()) {
      return null;
    }

    return <DisplayField id={props.id} value={props.children} />;
  }

  return <MaterialOption value={value} {...props} />;
};
