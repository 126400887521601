"use client";

import { HttpError } from "@natera/platform/lib/service/httpError";
import * as React from "react";
import { defineMessages, useIntl } from "react-intl";
import Error from "./error";

interface Props extends React.HTMLProps<HTMLDivElement> {
  error: HttpError;
  children?: React.ReactNode;
}

export const messages = defineMessages({
  notFound: {
    id: "platform.notFoundError.title",
    defaultMessage: "<b>404</b> &mdash; Not found",
  },
});

const NotFoundError: React.FC<Props> = ({ error, ...rest }) => {
  const intl = useIntl();
  const title = (
    <>
      {intl.formatMessage(messages.notFound, {
        b: (children) => <b>{children}</b>,
      })}
    </>
  );

  return (
    <Error
      {...rest}
      className="http-error"
      title={title}
      subtitle={error.message}
    >
      {
        <pre style={{ whiteSpace: "pre-wrap" }}>
          {JSON.stringify(error.body)}
        </pre>
      }
    </Error>
  );
};

export default NotFoundError;
