import { MDCTooltip, MDCTooltipFoundation } from "@material/tooltip";

export class TooltipComponent extends MDCTooltip {
  private onClose?: () => void;

  getFoundation(): MDCTooltipFoundation {
    return this.foundation;
  }

  show() {
    return this.foundation.show();
  }

  hide() {
    this.foundation.hide();
    if (this.onClose) this.onClose();
  }

  setOnClose(callback: () => void) {
    this.onClose = callback;
  }

  reopen() {
    this.foundation.hide();
    this.foundation.show();
  }
}
