"use client";

import { useComponentState } from "@natera/material/lib/hooks";
import { Textfield, TextfieldV2 } from "@natera/material/lib/textfield";
import * as R from "ramda";
import { defineMessages, useIntl } from "react-intl";
import { DayRangePickerWrapper, DateRange, HeaderAlign } from "../";
import { getUSLocaleDateRange } from "../utils";
import { BaseDateInputProps } from "./inputMask";
import { IconProps } from "@natera/material/lib/icon";
import * as React from "react";
import { DateFormatter } from "react-day-picker";
import { MenuController } from "@natera/material/lib/menu";
import classnames from "classnames";
import TrailingIcon from "./trailingIcon";
import { getMaterialTrailingIcon } from "./materialTrailingIcon";

export interface DateRangeInputProps extends BaseDateInputProps {
  range?: DateRange;
  defaultRange?: DateRange;
  onDateRangeChange?: (range: DateRange | undefined) => void;
  numberOfMonths?: number;
  showOutsideDays?: boolean;
  enableOutsideDaysClick?: boolean;
  defaultMonthYear?: Date;
  formatWeekdayName?: DateFormatter;
  formatMonthTitle?: DateFormatter;
  formatMonthInMenu?: (date: Date) => string;
  floating?: boolean;
  materialArrowIcon?: string | IconProps;
  materialDropdownIcon?: string | IconProps;
  headerAlign?: HeaderAlign;
}

export const messages = defineMessages({
  placeholder: {
    id: "material.range.placeholder",
    defaultMessage: "mm/dd/yyyy - mm/dd/yyyy",
  },
});

export const DateRangeInput: React.FunctionComponent<DateRangeInputProps> = ({
  range,
  defaultRange,
  maxDate,
  minDate,
  disabled,
  onDateRangeChange = R.always(undefined),
  numberOfMonths,
  showOutsideDays = false,
  enableOutsideDaysClick = false,
  defaultMonthYear = new Date(),
  formatWeekdayName,
  formatMonthTitle,
  formatMonthInMenu,
  floating,
  materialTrailingIcon,
  materialArrowIcon,
  materialDropdownIcon,
  trailingIcon,
  className,
  headerAlign,
  ...props
}) => {
  const [range$, setRange$] = useComponentState<DateRange | undefined>({
    controlledValue: range,
    defaultValue: defaultRange,
    onChange: onDateRangeChange,
  });
  const intl = useIntl();

  const onClear$ = () => {
    setRange$(undefined);
  };

  const title = range$ ? getUSLocaleDateRange(range$) : "";

  const TextfieldComponent = props.label ? TextfieldV2 : Textfield;

  const trailingIcon$ = (menu: MenuController): React.ReactNode | undefined => {
    return trailingIcon ? (
      <TrailingIcon trailingIcon={trailingIcon} menu={menu} />
    ) : undefined;
  };

  const materialTrailingIcon$ = (menu: MenuController) =>
    getMaterialTrailingIcon(materialTrailingIcon, props.label, menu);

  return (
    <DayRangePickerWrapper
      numberOfMonths={numberOfMonths}
      enableOutsideDaysClick={enableOutsideDaysClick}
      showOutsideDays={showOutsideDays}
      minDate={minDate}
      maxDate={maxDate}
      defaultMonthYear={defaultMonthYear}
      range={range$}
      onSelectRange={setRange$}
      formatWeekdayName={formatWeekdayName}
      formatMonthTitle={formatMonthTitle}
      formatMonthInMenu={formatMonthInMenu}
      floating={floating}
      materialArrowIcon={materialArrowIcon}
      materialDropdownIcon={materialDropdownIcon}
      headerAlign={headerAlign}
    >
      {(menu) => (
        <TextfieldComponent
          {...props}
          title={title}
          readOnly={true}
          selectable={true}
          clearable={true}
          disabled={disabled}
          onClick={!disabled ? menu.openMenu : undefined}
          value={title}
          onClear={onClear$}
          className={classnames("range-field", className)}
          dropDownActive={menu.isOpen()}
          placeholder={intl.formatMessage(messages.placeholder)}
          materialTrailingIcon={materialTrailingIcon$(menu)}
          trailingIcon={trailingIcon$(menu)}
        />
      )}
    </DayRangePickerWrapper>
  );
};

export default DateRangeInput;
