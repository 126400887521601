"use client";

import { useComponentState } from "@natera/material/lib/hooks";
import * as React from "react";

interface RadioController {
  value: string;
  name: string;
  changeHandler?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  hasGroup: boolean;
}

export interface RadioGroupProps {
  value?: string;
  defaultValue?: string;
  name: string;
  onChange?: (value: string) => void;
  children: React.ReactNode;
}

export const RadioGroupContext = React.createContext<RadioController>({
  value: "",
  name: "",
  changeHandler: () => undefined,
  hasGroup: false,
});

export const RadioGroup: React.FunctionComponent<RadioGroupProps> = ({
  name,
  value,
  defaultValue,
  onChange,
  children,
}) => {
  const [value$ = "", setValue$] = useComponentState<string>({
    controlledValue: value,
    defaultValue,
    onChange,
  });

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue$(e.currentTarget.value);
  };

  const controller: RadioController = {
    value: value$,
    name,
    changeHandler: handleOnChange,
    hasGroup: true,
  };

  return (
    <RadioGroupContext.Provider value={controller}>
      {children}
    </RadioGroupContext.Provider>
  );
};
