"use client";

import Icon from "@natera/material/assets/svg/dropdown.svg";
import { IconProps, IconRenderer } from "@natera/material/lib/icon";
import classnames from "classnames";
import * as React from "react";
import "./dropdownicon.scss";

interface Props extends React.HTMLAttributes<HTMLElement> {
  focused?: boolean;
  materialDropdownIcon?: string | IconProps;
}

export const DropDownIcon: React.FunctionComponent<Props> = ({
  className,
  onClick,
  focused,
  materialDropdownIcon,
  ...rest
}) => {
  return (
    <IconRenderer
      {...rest}
      icon={!materialDropdownIcon ? Icon : undefined}
      materialIcon={materialDropdownIcon}
      className={classnames("dropdown-icon", className, {
        "dropdown-icon--focused": focused,
      })}
      onClick={onClick}
    />
  );
};

export default DropDownIcon;
