"use client";

import BaseRadioButton, {
  RadioButtonProps,
} from "@natera/material/lib/radioButton";
import {
  DisplayField,
  FormFieldContext,
  RadioGroupContext,
} from "@natera/form";

import classnames from "classnames";
import * as React from "react";
import { v4 as uuidv4 } from "uuid";

export interface RadioProps extends Omit<RadioButtonProps, "id"> {
  id?: string;
}

export const RadioButton: React.FunctionComponent<RadioProps> = ({
  id,
  className,
  label,
  name,
  checked,
  disabled,
  required,
  onChange,
  ...props
}) => {
  const { isEditable, isDisabled, isRequired } =
    React.useContext(FormFieldContext);
  const radioId = React.useMemo(() => {
    return id || uuidv4();
  }, [id]);
  const groupContext = React.useContext(RadioGroupContext);

  const disabled$ = disabled || isDisabled();
  const required$ = required || isRequired();

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (onChange) {
      onChange(e);
    }
    if (groupContext.changeHandler) {
      groupContext.changeHandler(e);
    }
  };

  const name$ = groupContext.hasGroup ? name || groupContext.name : name;
  const checked$ = groupContext.hasGroup
    ? checked || props.value === groupContext.value
    : checked;

  return isEditable() ? (
    <BaseRadioButton
      id={radioId}
      className={classnames("mdc-form-field", className)}
      disabled={disabled$}
      required={required$}
      onChange={handleOnChange}
      label={label}
      name={name$}
      checked={checked$}
      {...props}
    />
  ) : (
    <DisplayField value={props.defaultChecked || checked$ ? label : ""} />
  );
};
