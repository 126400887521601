import React from "react";
import classNames from "classnames";

import "./icon.scss";

export enum IconVariant {
  OUTLINED = "outlined",
  ROUNDED = "rounded",
  SHARP = "sharp",
}

export interface IconProps extends React.HTMLAttributes<HTMLElement> {
  variant?: IconVariant;
  fill?: boolean;
  weight?: number;
  grade?: number;
  opsz?: number;
}

export const Icon: React.FC<IconProps> = ({
  children,
  variant = IconVariant.ROUNDED,
  fill = false,
  weight = 400,
  grade = 0,
  opsz = 24,
  className,
  style,
  ...props
}) => {
  const fontVariationSettings = `'FILL' ${
    fill ? "1" : "0"
  }, 'wght' ${weight}, 'GRAD' ${grade}, 'opsz' ${opsz}`;

  return (
    <span
      {...props}
      className={classNames(
        "material-symbols",
        `material-symbols-${variant}`,
        className,
      )}
      style={{
        fontVariationSettings: fontVariationSettings,
        ...style,
      }}
    >
      {children}
    </span>
  );
};

export default Icon;
