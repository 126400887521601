"use client";

import * as React from "react";
import classnames from "classnames";

import { MDCRipple } from "@material/ripple";
import { Spinner } from "@natera/material/lib/progress";
import { IconProps, IconRenderer } from "@natera/material/lib/icon";

import "./button.scss";

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  raised?: boolean;
  unelevated?: boolean;
  outlined?: boolean;
  error?: boolean;
  icon?: string;
  materialIcon?: string | IconProps;
  materialTrailingIcon?: string | IconProps;
  trailingIcon?: string;
  loading?: boolean;
  ripple?: boolean;
}

export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      children,
      className,
      raised = false,
      unelevated = false,
      outlined = false,
      error = false,
      disabled,
      icon,
      materialIcon,
      materialTrailingIcon,
      trailingIcon,
      loading,
      ripple,
      id,
      ...props
    },
    ref,
  ) => {
    const createRef = React.useCallback((element: HTMLButtonElement) => {
      if (ref) {
        if (ref instanceof Function) {
          ref(element);
        } else {
          ref.current = element;
        }
      }

      if (ripple && element) {
        new MDCRipple(element);
      }
    }, []);

    return (
      <button
        ref={createRef}
        id={id}
        className={classnames(className, "mdc-button", {
          "mdc-button--outlined": outlined,
          "mdc-button--raised": raised,
          "mdc-button--unelevated": unelevated,
          "mdc-button--error": error,
          "mdc-button--disabled": disabled || loading,
        })}
        disabled={disabled || loading}
        {...props}
      >
        <div className="mdc-button__ripple" />

        {!loading && (
          <IconRenderer
            icon={icon}
            materialIcon={materialIcon}
            className="mdc-button__icon"
            aria-hidden="true"
          />
        )}
        {loading && <Spinner className="mdc-button__spinner" />}
        {children && <span className="mdc-button__label">{children}</span>}
        {!loading && (
          <IconRenderer
            icon={trailingIcon}
            materialIcon={materialTrailingIcon}
            className="mdc-button__icon"
            aria-hidden="true"
          />
        )}
      </button>
    );
  },
);

Button.displayName = "Button";

export default Button;
