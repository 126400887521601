"use client";

import * as React from "react";
import classnames from "classnames";
import { ListItemText } from "@natera/material/lib/list";
import { Checkbox } from "@natera/material/lib/checkbox";
import { ExpansionPanel as BaseExpansionPanel } from "@natera/platform/lib/components/expansionPanel";
import {
  getTypeAheadContext,
  TypeAheadOptionProps,
} from "@natera/form/typeAhead";
import {
  getMultiSelectContext,
  getMultiSelectGroupContext,
} from "@natera/form";
import { GroupOption } from "./option";
import {
  RenderGroup as RenderGroupType,
  RenderOption as RenderOptionType,
} from "../types";

export interface Props<T> extends Omit<TypeAheadOptionProps, "optionValue"> {
  group: string;
  renderGroup: RenderGroupType;
  renderOption: RenderOptionType<T>;
}

export const MultiSelectGroup = <T extends object>({
  group,
  renderGroup,
  renderOption,
  className,
}: Props<T>): React.ReactElement => {
  const [expanded, setExpanded] = React.useState(false);
  const multiSelectGroupContext = React.useContext(
    getMultiSelectGroupContext(),
  );
  const multiSelectContext = React.useContext(getMultiSelectContext());
  const typeaheadContext = React.useContext(getTypeAheadContext());
  const options = multiSelectGroupContext.getGroupOptions(group);

  React.useEffect(() => {
    if (typeaheadContext.getInputValue()) {
      setExpanded(true);
    }
  }, [typeaheadContext.getInputValue()]);

  const isSelected = multiSelectContext.isGroupSelected(options);
  const isSomeSelected = multiSelectContext.isSomeSelected(options);
  const groupDisabled = options.every((option) =>
    typeaheadContext.isOptionDisabled(option),
  );

  const groupLabel = (
    <Checkbox
      readOnly
      id={group}
      onLabelClick={(event) => event.preventDefault()}
      key={group}
      disabled={groupDisabled}
      tabIndex={-1}
      checked={isSelected}
      indeterminate={!isSelected && isSomeSelected}
      className={classnames("multiselect-item", {
        "multiselect-item--disabled": groupDisabled,
      })}
      label={
        <div className="multiselect__group-label">
          <ListItemText>
            {renderGroup(group, {
              expanded,
              isSelected,
              isSomeSelected,
            })}
          </ListItemText>
        </div>
      }
    />
  );

  return (
    <div className={classnames("multiselect__group", className)}>
      <BaseExpansionPanel
        tabIndex={-1}
        label={groupLabel}
        onIconClick={groupDisabled ? undefined : setExpanded}
        defaultExpanded={expanded}
        onClick={
          groupDisabled
            ? setExpanded
            : () => multiSelectContext.handleGroupSelect(options)
        }
      >
        {options.map((item: T, index) => (
          <GroupOption<T>
            nested
            item={item}
            key={index}
            groupExpanded={expanded}
            renderOption={renderOption}
          />
        ))}
      </BaseExpansionPanel>
    </div>
  );
};
