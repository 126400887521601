"use client";

import PersonIcon from "@natera/patient/assets/svg/icons/person.svg";
import { PatientLink } from "@natera/patient/lib/patientLink";
import React from "react";
import { BasePatient } from "@natera/patient/lib/types";
import { defineMessages, useIntl } from "react-intl";
import { IconProps, IconRenderer } from "@natera/material/lib/icon";

import "./patientInfo.scss";

export interface Props extends React.HTMLAttributes<HTMLDivElement> {
  patient: BasePatient;
  renderLink: (options: {
    to: string;
    className: string;
    children: React.ReactNode;
  }) => React.ReactElement;
  materialPersonIcon?: string | IconProps;
}

export const messages = defineMessages({
  fullName: {
    id: "patient.info.fullName",
    defaultMessage: "Patient:",
  },
  id: {
    id: "patient.info.id",
    defaultMessage: " (ID: {id})",
  },
  dob: {
    id: "patient.info.dob",
    defaultMessage: "DOB:",
  },
  clinic: {
    id: "patient.info.clinic",
    defaultMessage: "Clinic:",
  },
});

export const PatientInfo: React.FC<Props> = ({
  patient,
  renderLink,
  materialPersonIcon,
  ...props
}) => {
  const intl = useIntl();

  return (
    <div {...props} className="patient-info-block">
      <IconRenderer
        icon={!materialPersonIcon ? PersonIcon : undefined}
        materialIcon={materialPersonIcon}
        className="patient-info-block__lead-icon"
      />
      <div className="patient-info-block__section">
        <span className="section-label">
          {intl.formatMessage(messages.fullName)}
        </span>
        <div className="ellipsis">
          <PatientLink renderLink={renderLink} patient={patient} />
          {intl.formatMessage(messages.id, { id: patient.id })}
        </div>
      </div>
      <div className="patient-info-block__section">
        <span className="section-label">
          {intl.formatMessage(messages.dob)}
        </span>
        <div className="ellipsis">
          {patient.dateOfBirth ? patient.dateOfBirth.toString() : "—"}
        </div>
      </div>
      <div className="patient-info-block__section">
        <span className="section-label">
          {intl.formatMessage(messages.clinic)}
        </span>
        <div className="ellipsis">
          {patient.clinic.name}
          {intl.formatMessage(messages.id, { id: patient.clinic.limsId })}
        </div>
      </div>
    </div>
  );
};

export default PatientInfo;
