"use client";

import {
  DateInput as BaseDateInput,
  DateInputProps,
  Utils,
} from "@natera/material/lib/date";
import { FormContext, DisplayField, FormFieldContext } from "@natera/form";
import * as R from "ramda";

import classnames from "classnames";
import * as React from "react";

export { DateInputProps } from "@natera/material/lib/date";

export const DateInput: React.FunctionComponent<DateInputProps> = ({
  date,
  defaultDate,
  onDateChange = R.always(undefined),
  disabled,
  invalid,
  required,
  className,
  ...props
}) => {
  const fieldContext = React.useContext(FormFieldContext);
  const { setFormChanged } = React.useContext(FormContext);

  const isDisabled = disabled || fieldContext.isDisabled();
  const hasError = invalid || fieldContext.hasError();
  const isRequired =
    required === false ? false : required || fieldContext.isRequired();

  const onDateChange$ = (date$?: Date) => {
    onDateChange(date$);
    setFormChanged();
  };

  const displayValue = date || defaultDate;

  return fieldContext.isEditable() ? (
    <BaseDateInput
      className={classnames(className, "mdc-form-field")}
      date={date}
      defaultDate={defaultDate}
      onDateChange={onDateChange$}
      disabled={isDisabled}
      required={isRequired}
      invalid={hasError}
      {...props}
    />
  ) : (
    <DisplayField
      {...props}
      value={displayValue ? Utils.getUSLocaleDate(displayValue) : <>&mdash;</>}
    />
  );
};
