"use client";

import { Button } from "@natera/form/button";
import { MenuContext } from "@natera/platform/lib/components/menu";
import classnames from "classnames";
import * as React from "react";
import { defineMessages, useIntl } from "react-intl";
import { getTypeAheadContext, getMultiSelectContext } from "@natera/form";

export const messages = defineMessages({
  apply: {
    id: "platform.multiselect.apply",
    defaultMessage: "Apply",
  },
  clear: {
    id: "platform.multiselect.clear",
    defaultMessage: "Clear",
  },
});

interface MultiSelectActionProps extends React.HTMLProps<HTMLDivElement> {
  divided?: boolean;
}

export const MultiSelectActions: React.FunctionComponent<
  MultiSelectActionProps
> = ({ divided = false, ...props }) => {
  const intl = useIntl();
  const typeaheadController = React.useContext(getTypeAheadContext());
  const multiselectController = React.useContext(getMultiSelectContext());
  const menuController = React.useContext(MenuContext);

  const collection = typeaheadController.getOptionsCollection();
  const selection = multiselectController.getSelection();

  const disabledClearButton =
    collection.isLoading() ||
    multiselectController.getSelectedItems().length === 0;

  const onApplyHandler = React.useCallback(() => {
    multiselectController.apply();
    menuController.closeMenu();
  }, [selection]);

  const onClearHandler = React.useCallback(() => {
    multiselectController.clear();
  }, [selection]);

  return (
    <div
      {...props}
      className={classnames("multiselect__action-buttons", {
        "multiselect__action-buttons--divided": divided,
      })}
    >
      <Button
        tabIndex={0}
        disabled={disabledClearButton}
        onClick={onClearHandler}
      >
        {intl.formatMessage(messages.clear)}
      </Button>
      <Button
        tabIndex={0}
        disabled={collection.isLoading()}
        outlined={true}
        onClick={onApplyHandler}
      >
        {intl.formatMessage(messages.apply)}
      </Button>
    </div>
  );
};
