"use client";

import * as React from "react";
import * as ReactIs from "react-is";
import { FixedSizeList, ListChildComponentProps } from "react-window";

import { ListProps, List } from "./list";

const ITEM_HEIGHT = 48;
const ITEM_HEIGHT_WITH_TWO_LINES_AND_DENSE = 60;
const ITEM_HEIGHT_WITH_TWO_LINES = 64;
const ITEM_HEIGHT_WITH_DENSE = 40;

export interface VirtualizedListProps extends ListProps {
  children: React.ReactNode | React.ReactNode[];
  height?: number;
  width?: number | string;
  itemHeight?: number;
  minimizeHeight?: boolean;
  paddingTopFirstElement?: number;
  paddingBottomLastElement?: number;
}

interface InnerElementController {
  paddingTopFirstElement: number;
  paddingBottomLastElement: number;
}

const InnerElementContext = React.createContext<InnerElementController>({
  paddingTopFirstElement: 0,
  paddingBottomLastElement: 0,
});

const innerElementType = React.forwardRef<
  HTMLDivElement,
  React.HTMLProps<HTMLDivElement>
>(({ style, ...rest }, ref) => {
  const { paddingTopFirstElement, paddingBottomLastElement } =
    React.useContext(InnerElementContext);
  const height: string = (style?.height as string) || "0";
  return (
    <div
      {...rest}
      ref={ref}
      style={{
        ...style,
        height: `${
          parseInt(height, 10) +
          paddingTopFirstElement +
          paddingBottomLastElement
        }px`,
      }}
    />
  );
});

export const VirtualizedList = React.forwardRef<
  HTMLUListElement,
  VirtualizedListProps
>(
  (
    {
      children,
      twoLines = false,
      dense = false,
      minimizeHeight = false,
      height = 200,
      width,
      paddingTopFirstElement = 0,
      paddingBottomLastElement = 0,
      itemHeight,
      ...props
    }: VirtualizedListProps,
    ref: React.MutableRefObject<HTMLUListElement>,
  ) => {
    const fixedSizeListRow = React.useMemo(() => {
      let childrenArray: React.ReactNode[];
      if (ReactIs.isElement(children)) {
        const element = children as React.ReactElement;
        childrenArray = React.Children.toArray(element.props.children);
      } else {
        childrenArray = React.Children.toArray(children);
      }

      return ({ index, style }: ListChildComponentProps) => {
        const item = childrenArray[index] as React.ReactElement<
          React.HTMLProps<HTMLLIElement>
        >;
        if (React.isValidElement(item)) {
          return React.cloneElement(item, {
            style: {
              ...style,
              top: `${
                parseInt(style.top as string, 10) + paddingTopFirstElement
              }px`,
              boxSizing: "border-box",
            } as React.CSSProperties,
          });
        }
        return null;
      };
    }, [children, paddingTopFirstElement]);

    const itemCount = React.useMemo(() => {
      if (ReactIs.isElement(children)) {
        const element = children as React.ReactElement;
        return React.Children.count(element.props.children);
      }
      return React.Children.count(children);
    }, [children]);

    const calculatedItemHeight = React.useMemo(() => {
      if (itemHeight === undefined) {
        if (twoLines && dense) {
          return ITEM_HEIGHT_WITH_TWO_LINES_AND_DENSE;
        } else if (twoLines) {
          return ITEM_HEIGHT_WITH_TWO_LINES;
        } else if (dense) {
          return ITEM_HEIGHT_WITH_DENSE;
        }
        return ITEM_HEIGHT;
      }

      return itemHeight;
    }, [twoLines, dense, itemHeight]);

    const calculatedListHeight = React.useMemo(() => {
      const itemsHeight =
        itemCount * calculatedItemHeight +
        paddingTopFirstElement +
        paddingBottomLastElement;

      if (
        height === undefined ||
        (minimizeHeight && height && height > itemsHeight)
      ) {
        return itemsHeight;
      }

      return height;
    }, [
      calculatedItemHeight,
      itemCount,
      height,
      minimizeHeight,
      paddingTopFirstElement,
      paddingBottomLastElement,
    ]);

    return (
      <List {...props} ref={ref}>
        <InnerElementContext.Provider
          value={{
            paddingTopFirstElement,
            paddingBottomLastElement,
          }}
        >
          <FixedSizeList
            innerElementType={innerElementType}
            height={calculatedListHeight}
            itemSize={calculatedItemHeight}
            width={width || "auto"}
            itemCount={itemCount}
          >
            {fixedSizeListRow}
          </FixedSizeList>
        </InnerElementContext.Provider>
      </List>
    );
  },
);
