"use client";

import {
  Textfield as BaseTextfield,
  TextfieldProps,
} from "@natera/material/lib/textfield";
import classnames from "classnames";
import * as React from "react";
import { DisplayField, FormFieldContext } from "@natera/form";

import "./textfield.scss";

export { TextfieldProps } from "@natera/material/lib/textfield";

export const Textfield = React.forwardRef<HTMLInputElement, TextfieldProps>(
  (
    { value, defaultValue, disabled, invalid, required, className, ...props },
    ref: React.RefObject<HTMLInputElement>,
  ) => {
    const fieldContext = React.useContext(FormFieldContext);
    const isDisabled = disabled || fieldContext.isDisabled();
    const hasError = invalid || fieldContext.hasError();
    const isRequired =
      required === false ? false : required || fieldContext.isRequired();

    if (!fieldContext.isEditable()) {
      return <DisplayField value={value || defaultValue || <>&mdash;</>} />;
    }

    return (
      <BaseTextfield
        ref={ref}
        value={value}
        defaultValue={defaultValue}
        disabled={isDisabled}
        required={isRequired}
        invalid={hasError}
        className={classnames(className, "mdc-form-field")}
        {...props}
      />
    );
  },
);

export default Textfield;
