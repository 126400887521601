"use client";

import * as React from "react";
import { FormContext, ButtonProps, FormButton } from "@natera/form";

export const ResetButton: React.FunctionComponent<ButtonProps> = ({
  children,
  disabled,
  ...props
}) => {
  const { isChanged } = React.useContext(FormContext);

  return (
    <FormButton type="reset" disabled={disabled || !isChanged()} {...props}>
      {children}
    </FormButton>
  );
};

export default ResetButton;
