"use client";

import classnames from "classnames";
import * as R from "ramda";
import * as React from "react";
import { defineMessages, FormattedMessage } from "react-intl";
import { FormField, FormFieldProps } from "@natera/form";
import { IconProps } from "@natera/material/lib/icon";
import {
  AttachmentContext,
  AttachmentController,
  AttachmentId,
} from "./attachmentContext";
import AttachmentButton from "./button";
import AttachmentItem from "./item";

import "custom-event-polyfill";

export type AttachmentClickEvent = CustomEvent<AttachmentId>;
export type AttachmentResetEvent = CustomEvent<AttachmentId>;

export enum AttachmentActions {
  ADD = "ADD",
  REMOVE = "REMOVE",
}

export const messages = defineMessages({
  label: {
    id: "platform.attachment.metadata",
    defaultMessage: "Attachment label",
  },
  placeholder: {
    id: "platform.attachment.placeholder",
    defaultMessage: "select file to upload",
  },
});

export interface AttachmentFieldProps
  extends FormFieldProps,
    Pick<React.InputHTMLAttributes<HTMLInputElement>, "accept"> {
  onAttachmentClick?: (event: AttachmentClickEvent) => void;
  onAttachmentRemove?: (event: AttachmentResetEvent) => void;
  controllerRef?: React.RefObject<AttachmentController>;
  maxAttachments?: number;
  actions?: AttachmentActions[];
  menu?: React.ReactNode;
  isAttachmentEditable?: (attachmentId?: AttachmentId) => boolean;
  materialItemLeadIcon?: string | IconProps;
  materialItemTrailingIcon?: string | IconProps;
  materialAttachmentIcon?: string | IconProps;
  materialDropdownIcon?: string | IconProps;
  children?: React.ReactNode;
}

const defaultLabel = <FormattedMessage {...messages.label} />;
const defaultPlaceholder = <FormattedMessage {...messages.placeholder} />;

export const AttachmentField: React.FunctionComponent<AttachmentFieldProps> = ({
  className,
  onAttachmentClick,
  onAttachmentRemove,
  controllerRef,
  label = defaultLabel,
  children = defaultPlaceholder,
  maxAttachments,
  menu,
  disabled,
  isEditable,
  isAttachmentEditable = R.always(true),
  actions = [AttachmentActions.ADD, AttachmentActions.REMOVE],
  accept,
  materialItemLeadIcon,
  materialItemTrailingIcon,
  materialAttachmentIcon,
  materialDropdownIcon,
  ...props
}) => {
  const attachmentContext = React.useContext(AttachmentContext);

  if (controllerRef) {
    const controllerRef$: React.MutableRefObject<AttachmentController | null> =
      controllerRef;
    controllerRef$.current = attachmentContext;
  }

  const onFileSelect = (files$: File[]) => {
    files$.forEach((file) => attachmentContext.createAttachment(file));
  };

  const attachments = attachmentContext.getAttachments();

  const attachmentClickHandler = (attachmentId: AttachmentId) => {
    if (onAttachmentClick) {
      const event = new CustomEvent<AttachmentId>("click", {
        detail: attachmentId,
      });
      onAttachmentClick(event);
    } else {
      attachmentContext.downloadAttachment(attachmentId);
    }
  };

  const attachmentResetHandler = (attachmentId: AttachmentId) => {
    const event = new CustomEvent<AttachmentId>("reset", {
      cancelable: true,
      detail: attachmentId,
    });

    if (onAttachmentRemove) {
      onAttachmentRemove(event);
    }

    if (!event.defaultPrevented) {
      attachmentContext.removeAttachment(attachmentId);
    }
  };

  return (
    <div className="attachment-field">
      <FormField
        className={classnames("attachment-form-field", className)}
        label={label}
        disabled={disabled}
        isEditable={isEditable}
        {...props}
      >
        {actions.includes(AttachmentActions.ADD) && (
          <AttachmentButton
            accept={accept}
            label={children}
            onFileSelect={onFileSelect}
            menu={menu}
            materialAttachmentIcon={materialAttachmentIcon}
            materialDropdownIcon={materialDropdownIcon}
            disabled={
              maxAttachments ? attachments.length >= maxAttachments : undefined
            }
          />
        )}
      </FormField>
      <div className="attachment-items">
        {attachments.map((attachment) => (
          <AttachmentItem
            key={attachment.id}
            isEditable={
              isEditable &&
              actions.includes(AttachmentActions.REMOVE) &&
              isAttachmentEditable(attachment.id)
            }
            loading={attachment.isLoading}
            file={attachment.file}
            id={attachment.id}
            disabled={disabled}
            error={attachment.error}
            onItemClick={attachmentClickHandler}
            onReset={attachmentResetHandler}
            materialLeadIcon={materialItemLeadIcon}
            materialTrailingIcon={materialItemTrailingIcon}
          />
        ))}
      </div>
    </div>
  );
};

export default AttachmentField;
