"use client";

import React, { FC } from "react";
import ClearIcon from "@natera/material/assets/svg/clear.svg";
import { DropDownIcon } from "../select";
import * as R from "ramda";
import { IconProps, IconRenderer } from "@natera/material/lib/icon";

export interface TextfieldProps {
  trailingIcon?: React.ReactNode;
  materialTrailingIcon?: string | IconProps;
  materialClearIcon?: string | IconProps;
  materialDropdownIcon?: string | IconProps;
  isClearable?: boolean;
  selectable?: boolean;
  dropDownActive?: boolean;
  onClear?: () => void;
  onSelectionOpen?: () => void;
  focus?: boolean;
}

const TrailingIcon: FC<TextfieldProps> = ({
  trailingIcon,
  materialTrailingIcon,
  materialClearIcon,
  materialDropdownIcon,
  focus,
  isClearable,
  onClear = R.always(undefined),
  selectable,
  dropDownActive,
  onSelectionOpen,
}) => {
  const mouseClearHandler: React.MouseEventHandler = React.useCallback(
    (event) => {
      event.preventDefault();
      event.stopPropagation();

      onClear();
    },
    [onClear],
  );

  const keyboardClearHandler: React.KeyboardEventHandler = React.useCallback(
    (event) => {
      if (
        event.key === "Enter" ||
        event.key === " " ||
        event.key === "Spacebar"
      ) {
        event.preventDefault();
        event.stopPropagation();

        onClear();
      }
    },
    [onClear],
  );

  if (trailingIcon || materialTrailingIcon) {
    return (
      <IconRenderer icon={trailingIcon} materialIcon={materialTrailingIcon} />
    );
  }

  if (isClearable) {
    return (
      <IconRenderer
        icon={!materialClearIcon ? ClearIcon : undefined}
        materialIcon={materialClearIcon}
        className="mdc-text-field__icon-button"
        tabIndex={0}
        role="button"
        onClick={mouseClearHandler}
        onKeyDown={keyboardClearHandler}
      />
    );
  }

  if (selectable) {
    return (
      <DropDownIcon
        focused={R.isNil(dropDownActive) ? focus : dropDownActive}
        onClick={onSelectionOpen}
        materialDropdownIcon={materialDropdownIcon}
      />
    );
  }
  return null;
};

export default React.memo(TrailingIcon);
