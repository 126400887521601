import * as React from "react";

type OpenDialog<P> = (props: P) => void;
type CloseDialog = () => void;
type RefreshDialog<P> = (props: P) => void;
type GetDialog = () => React.ReactNode | null;

interface DialogController<P> {
  open: OpenDialog<Omit<P, "close">>;
  close: CloseDialog;
  refreshState: RefreshDialog<Omit<P, "close">>;
  getDialog: GetDialog;
}

interface State<P> {
  isOpen: boolean;
  props?: P;
}

export const useDialog = <
  P extends NonNullable<unknown> = Record<string, unknown>,
>(
  dialog: React.ComponentType<P>,
): DialogController<P> => {
  const [state, setState] = React.useState<State<P>>({
    isOpen: false,
  });

  const open: OpenDialog<P> = React.useCallback((props) => {
    setState({
      props,
      isOpen: true,
    });
  }, []);

  const close: CloseDialog = React.useCallback(() => {
    setState({
      isOpen: false,
      props: undefined,
    });
  }, []);

  const refreshState: RefreshDialog<P> = React.useCallback((props) => {
    setState((prevState) => ({
      ...prevState,
      props,
    }));
  }, []);

  const getDialog: GetDialog = React.useCallback(() => {
    if (!state.isOpen) {
      return null;
    }

    if (!state.props) {
      return null;
    }

    return React.createElement<P>(dialog, {
      ...state.props,
      isOpen: state.isOpen,
      close,
    });
  }, [state]);

  return React.useMemo(
    () => ({
      open,
      close,
      refreshState,
      getDialog,
    }),
    [open, close, getDialog],
  );
};
