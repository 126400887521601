"use client";

import {
  MenuItem as BaseMenuItem,
  MenuItemProps,
} from "@natera/material/lib/menu";
import {
  BasePermission,
  SecurityContext,
} from "@natera/platform/lib/provider/security";
import * as React from "react";

export interface Props extends MenuItemProps {
  permissions?: BasePermission[];
}

const MenuItem: React.FunctionComponent<Props> = ({
  permissions = [],
  ...props
}) => {
  const { hasPermission } = React.useContext(SecurityContext);

  if (permissions.length && !hasPermission(...permissions)) {
    return null;
  }

  return <BaseMenuItem {...props} />;
};

export default MenuItem;
