"use client";

import { MenuItem } from "@natera/material/lib/menu";
import { Attachment, AttachmentContext } from "./attachmentContext";

import * as React from "react";

interface Props extends Omit<React.HTMLProps<HTMLLIElement>, "children"> {
  attachment: Attachment;
  onAttachmentClick?: (attachment: Attachment) => void;
  children?: React.ReactNode;
}

export const AttachmentOption: React.FunctionComponent<Props> = ({
  attachment,
  children,
  onAttachmentClick,
  ...rest
}) => {
  const attachmentContext = React.useContext(AttachmentContext);

  const onClick = () => {
    if (onAttachmentClick) {
      onAttachmentClick(attachment);
    }
    attachmentContext.addAttachment(attachment);
  };

  return (
    <MenuItem
      {...rest}
      onClick={onClick}
      disabled={attachmentContext.hasAttachment(attachment)}
    >
      {children}
    </MenuItem>
  );
};
