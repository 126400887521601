"use client";

import {
  BasePermission,
  SecurityContext,
} from "@natera/platform/lib/provider/security";
import classnames from "classnames";
import * as React from "react";
import { NavLink, NavLinkProps } from "react-router-dom";

import "./link.scss";

export interface LinkProps extends NavLinkProps {
  disabled?: boolean;
  permissions?: BasePermission[];
  visibleWhenUnpermitted?: boolean;
  activeClassName?: string;
  className?: string;
}

export const Link: React.FunctionComponent<LinkProps> = React.forwardRef(
  (
    {
      disabled,
      permissions = [],
      className,
      activeClassName = "active",
      visibleWhenUnpermitted,
      ...props
    },
    ref: React.ForwardedRef<HTMLAnchorElement>,
  ) => {
    const { hasPermission } = React.useContext(SecurityContext);
    if (!hasPermission(...permissions) || disabled) {
      if (visibleWhenUnpermitted || disabled) {
        return <>{props.children}</>;
      }
      return null;
    }

    return (
      <NavLink
        ref={ref}
        className={({ isActive }) =>
          classnames("link", className, {
            [activeClassName]: isActive,
          })
        }
        {...props}
      />
    );
  },
);

export default Link;
