"use client";

import classnames from "classnames";
import PerfectScrollbar from "perfect-scrollbar";
import * as R from "ramda";
import * as React from "react";

import "./scrollbar.scss";

const handlersMap: Record<string, ScrollHandler> = Object.freeze({
  "ps-scroll-y": "onScrollY",
  "ps-scroll-x": "onScrollX",
  "ps-scroll-up": "onScrollUp",
  "ps-scroll-down": "onScrollDown",
  "ps-scroll-left": "onScrollLeft",
  "ps-scroll-right": "onScrollRight",
  "ps-y-reach-start": "onYReachStart",
  "ps-y-reach-end": "onYReachEnd",
  "ps-x-reach-start": "onXReachStart",
  "ps-x-reach-end": "onXReachEnd",
});

type ScrollHandler =
  | "onScrollY"
  | "onScrollX"
  | "onScrollUp"
  | "onScrollDown"
  | "onScrollLeft"
  | "onScrollRight"
  | "onYReachStart"
  | "onYReachEnd"
  | "onXReachStart"
  | "onXReachEnd";

type Scrollhandlers = Partial<
  Record<ScrollHandler, EventListenerOrEventListenerObject>
>;

export type ScrollbarOptions = PerfectScrollbar.Options;

export interface ScrollbarProps extends React.HTMLAttributes<HTMLElement> {
  component: keyof React.ReactHTML;
  settings?: PerfectScrollbar.Options;
  isStatic?: boolean;
}

const ScrollContainer = React.forwardRef<
  HTMLElement,
  ScrollbarProps & Scrollhandlers
>(
  (
    { children, component, className, settings, isStatic = true, ...props },
    ref,
  ) => {
    const root = React.useRef<HTMLElement>();
    const scrollbarRef = React.useRef<PerfectScrollbar>();
    const handlers = React.useMemo<
      Record<string, EventListenerOrEventListenerObject>
    >(() => ({}), []);

    React.useEffect(() => {
      if (root.current) {
        scrollbarRef.current = new PerfectScrollbar(root.current, settings);

        R.mapObjIndexed((name, key) => {
          const handler = R.prop(name, props);

          if (handler && root.current) {
            handlers[key] = handler;
            root.current.addEventListener(key, handler);
          }
        }, handlersMap);
      }

      return () => {
        R.mapObjIndexed((handler, key) => {
          if (root.current) {
            root.current.removeEventListener(key, handler);
          }
        }, handlers);

        if (scrollbarRef.current) {
          scrollbarRef.current.destroy();
          scrollbarRef.current = undefined;
        }
      };
    }, []);

    React.useEffect(() => {
      if (!isStatic && scrollbarRef.current) {
        scrollbarRef.current.update();
      }
    }, [children]);

    const refFn = React.useCallback((element: HTMLElement) => {
      if (!element) {
        return;
      }

      if (ref instanceof Function) {
        ref(element);
      } else if (ref) {
        ref.current = element;
      }

      root.current = element;
    }, []);

    return React.createElement(
      component,
      {
        ref: refFn,
        className: classnames(className, "scrollbar-container", "ps"),
      },
      children,
    );
  },
);

export default ScrollContainer;
