"use client";

import FormFieldLabel from "@natera/material/lib/label";
import { FormContext } from "@natera/form";
import {
  BasePermission,
  SecurityContext,
} from "@natera/platform/lib/provider/security";
import classnames from "classnames";
import * as React from "react";

import "./formField.scss";

export interface FormFieldProps
  extends Omit<React.HTMLProps<HTMLDivElement>, "label"> {
  fullWidth?: boolean;
  inline?: boolean;
  label?: React.ReactNode;
  error?: boolean | React.ReactNode;
  className?: string;
  isEditable?: boolean;
  permissions?: BasePermission[];
  disabled?: boolean;
  helpText?: React.ReactNode;
  required?: boolean;
  title?: string;
  children?: React.ReactNode;
  htmlFor?: string;
  labelId?: string;
}

interface FormFieldController {
  isEditable: () => boolean;
  hasError: () => boolean;
  hasLabel: () => boolean;
  isDisabled: () => boolean;
  isRequired: () => boolean;
}

export const FormFieldContext = React.createContext<FormFieldController>({
  hasError: () => false,
  hasLabel: () => false,
  isEditable: () => true,
  isDisabled: () => false,
  isRequired: () => false,
});

export const FormField: React.FunctionComponent<FormFieldProps> = ({
  permissions = [],
  isEditable,
  inline = false,
  children,
  label,
  error,
  className,
  disabled = false,
  helpText,
  required = false,
  htmlFor,
  title,
  labelId,
  ...rest
}) => {
  const { hasPermission } = React.useContext(SecurityContext);
  const formContext = React.useContext(FormContext);
  const formFieldRef = React.useRef<HTMLDivElement>(null);
  const editable =
    isEditable !== undefined && isEditable !== null
      ? isEditable && hasPermission(...permissions)
      : formContext.isEditable();

  React.useEffect(() => {
    if (error) {
      formContext.scrollToError(formFieldRef.current as HTMLDivElement);
    }
  }, [error]);

  const controller: FormFieldController = {
    hasError: () => Boolean(error),
    hasLabel: () => Boolean(label),
    isEditable: () => editable,
    isDisabled: () => disabled || formContext.isDisabled(),
    isRequired: () => required,
  };

  const hasErrorMessage = error && React.Children.count(error) > 0;
  const hasHelpText = helpText || hasErrorMessage;

  return (
    <FormFieldContext.Provider value={controller}>
      <div
        {...rest}
        ref={formFieldRef}
        className={classnames("form-field", className, {
          "form-field--has-label": label,
          "form-field--inline": inline,
          "form-field--invalid": error,
          "form-field--with-help-text": hasHelpText,
        })}
      >
        <>
          {label && (
            <FormFieldLabel
              id={labelId}
              title={title}
              required={required}
              htmlFor={htmlFor}
            >
              {label}
            </FormFieldLabel>
          )}
          {children}
          {hasHelpText && (
            <div
              className={classnames("mdc-text-field-helper-line", {
                "mdc-text-field-helper-line--invalid": hasErrorMessage,
              })}
            >
              <div
                className={classnames(
                  "mdc-text-field-helper-text",
                  "mdc-text-field-helper-text--persistent",
                  {
                    "mdc-text-field-helper-text--validation-msg":
                      hasErrorMessage,
                  },
                )}
                role={hasErrorMessage ? "alert" : undefined}
              >
                {hasErrorMessage ? error : helpText}
              </div>
            </div>
          )}
        </>
      </div>
    </FormFieldContext.Provider>
  );
};
