"use client";

import {
  ListItem,
  ListItemMeta,
  ListItemText,
} from "@natera/material/lib/list";
import { Svg } from "@natera/material/lib/svg";
import { IconProps } from "@natera/material/lib/icon";
import ArrowDown from "@natera/platform/assets/svg/icons/arrow_down.svg";
import classnames from "classnames";
import * as React from "react";

import "./expansionPanel.scss";

export interface ExpansionPanelProps
  extends Omit<React.HTMLProps<HTMLDivElement>, "label" | "onClick"> {
  label?: string | React.ReactNode;
  content?: string;
  divider?: boolean;
  defaultExpanded?: boolean;
  children?: React.ReactNode;
  onClick?: (expanded: boolean) => void;
  onIconClick?: (expanded: boolean) => void;
  tabIndex?: number;
  arrowIcon?: string;
  materialArrowIcon?: string | IconProps;
  spacious?: boolean;
}

export const ExpansionPanel: React.FunctionComponent<ExpansionPanelProps> = ({
  defaultExpanded = false,
  label,
  content,
  divider,
  children,
  onClick,
  onIconClick,
  tabIndex,
  arrowIcon = ArrowDown,
  materialArrowIcon,
  spacious,
  className,
  ...props
}) => {
  const [expanded, setExpanded] = React.useState(defaultExpanded);

  React.useEffect(() => {
    setExpanded(defaultExpanded);
  }, [defaultExpanded]);

  React.useEffect(() => {
    if (onIconClick) {
      return onIconClick(expanded);
    }

    if (onClick) {
      onClick(expanded);
    }
  }, [expanded]);

  const handleChange = (e: React.SyntheticEvent) => {
    e.stopPropagation();
    setExpanded((prevValue) => !prevValue);
  };

  const onPanelKeyPress = (e: React.KeyboardEvent<HTMLLIElement>) => {
    if (e.key === "Enter" || e.key === " ") {
      handleChange(e);
    }
  };

  return (
    <div
      {...props}
      className={classnames("expansion-panel", className, {
        "expansion-panel--show-divider": divider,
        "expansion-panel--show-content": content,
        "expansion-panel--expanded": expanded,
        "expansion-panel--spacious": spacious,
      })}
    >
      <ListItem
        tabIndex={tabIndex || 0}
        onKeyDown={onPanelKeyPress}
        onClick={
          onIconClick ? () => onClick && onClick(expanded) : handleChange
        }
      >
        <ListItemText>{label}</ListItemText>
        {content ? (
          <span className="expansion-panel__header-content">{content}</span>
        ) : null}
        <ListItemMeta
          className="expansion-panel__icon"
          role={onIconClick ? "button" : "img"}
          onClick={onIconClick ? handleChange : undefined}
          materialIcon={materialArrowIcon}
        >
          <Svg content={arrowIcon} />
        </ListItemMeta>
      </ListItem>
      <div
        className={classnames("expansion-panel__content", {
          "expansion-panel__content-expanded": expanded,
        })}
      >
        {children}
      </div>
    </div>
  );
};

export default ExpansionPanel;
