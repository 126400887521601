import { SortOrder } from "@natera/platform/lib/service/sort";
import * as R from "ramda";
import * as React from "react";
import { useStorage } from "../useStorage";
import {
  Proxy,
  ProxyProps,
  SerializedRecords,
  useStorageProxy,
} from "../useStorageProxy";

type SortType = "SINGLE" | "MULTIPLE";
export interface SortAPI {
  getOrder: () => SortOrder | undefined;
  setOrder: (order: SortOrder) => void;
  removeOrder: () => void;
  toggleOrder: () => void;
}

type SortSettings = Record<string, SortOrder>;

interface SortRecord {
  id: string;
  value: SortOrder;
}

interface Props {
  initSorts: SortSettings;
  sortType?: SortType;
  proxy?: <T>(proxyProps: ProxyProps<T>) => Proxy<T>;
}

export const useSorts = <K extends string>({
  initSorts,
  sortType = "SINGLE",
  proxy,
}: Props): [Record<K, SortOrder>, (fieldName: K) => SortAPI] => {
  const sortToStorageSettings = React.useCallback(
    (sortSettings: SortSettings) =>
      R.pipe(
        R.toPairs,
        R.map(([id, value]: [string, SortOrder]) => ({
          id,
          value,
        })),
      )(sortSettings),
    [],
  );

  const serializeSorts = React.useCallback(
    (record: SortRecord[]) =>
      record.reduce(
        (acc, sortRecord) => ({
          ...acc,
          sort: encodeURI(`${sortRecord.id} ${sortRecord.value}`),
        }),
        {},
      ),
    [],
  );

  const deserializeSorts = React.useCallback(
    (record: SerializedRecords) =>
      R.pipe(
        R.toPairs,
        R.map(([, order]: [string, string]) => {
          const [id, value] = decodeURI(order).split(" ");
          return {
            id,
            value: value as SortOrder,
          };
        }),
      )(record),
    [],
  );

  const initialRecords: SortRecord[] = React.useMemo(
    () => sortToStorageSettings(initSorts),
    [],
  );

  const sortsStorage = proxy
    ? useStorageProxy<SortRecord>({
        initialRecords,
        serialize: serializeSorts,
        deserialize: deserializeSorts,
        proxy,
      })
    : useStorage({ initialRecords });

  const setOrderByFieldname = (fieldName: K) => (newValue: SortOrder) => {
    switch (sortType) {
      case "SINGLE":
        sortsStorage.clear();
        sortsStorage.addRecord({ id: fieldName, value: newValue });
        break;
      case "MULTIPLE":
        if (!sortsStorage.getRecord(fieldName)?.value) {
          sortsStorage.addRecord({ id: fieldName, value: newValue });
        } else {
          sortsStorage.updateRecord(fieldName, (sort) => ({
            ...sort,
            value: newValue,
          }));
        }
        break;
    }
  };

  const removeOrRevertOrChangeOrderByFiledName = (
    fieldName: K,
    order: SortOrder,
  ) => {
    if (R.includes(fieldName, R.keys(initSorts))) {
      setOrderByFieldname(fieldName)(order);
    } else {
      removeOrderByFieldName(fieldName);
    }
  };

  const removeOrderByFieldName = (fieldName: K) => {
    sortsStorage.removeRecord(fieldName);
  };

  React.useEffect(() => {
    if (sortsStorage.getRecords().length === 0 && initialRecords.length !== 0) {
      sortsStorage.addRecords(initialRecords);
    }
  }, [sortsStorage.getRecords]);

  const sortFields: Record<string, SortOrder> = React.useMemo(() => {
    return sortsStorage
      .getRecords()
      .reduce((acc, sort) => ({ ...acc, [sort.id]: sort.value }), {});
  }, [sortsStorage.getRecords]);

  const getOrder = React.useCallback(
    (fieldName: string) => sortFields[fieldName],
    [sortFields],
  );

  const getSortAPI = React.useCallback(
    (fieldName: K): SortAPI => ({
      getOrder: () => getOrder(fieldName),
      removeOrder: () => removeOrderByFieldName(fieldName),
      setOrder: setOrderByFieldname(fieldName),
      toggleOrder: () => {
        const order = sortFields[fieldName];
        switch (order) {
          case undefined:
            setOrderByFieldname(fieldName)("ASC");
            break;
          case "ASC":
            setOrderByFieldname(fieldName)("DESC");
            break;
          case "DESC":
            removeOrRevertOrChangeOrderByFiledName(fieldName, "ASC");
            break;
        }
      },
    }),
    [sortFields],
  );

  return [sortFields, getSortAPI];
};
