"use client";

import { Chip } from "@natera/material/lib/chip";
import { Tooltip } from "@natera/material/lib/tooltip";
import classNames from "classnames";
import React, { FC } from "react";
import { defineMessages, FormattedMessage } from "react-intl";
import { DateRange } from "../../definitions";
import styles from "../../theme/main.scss";
import { useTimeslots } from "../timeslots/TimeslotsContext";
import SlotChipTime from "./SlotChipTime";

type SlotChipProps = {
  slot: DateRange;
  onClick: () => void;
  selected: boolean;
  disabled: boolean;
  selectedSlots: DateRange[];
  maxSlots: number;
  mobile?: boolean;
  onKeyDown?: (e: React.KeyboardEvent) => void;
  focus?: boolean;
  oneSlotPerDay?: boolean;
};

const cn = classNames.bind(styles);
export const messages = defineMessages({
  timeslotTooltip: {
    id: "mobilePhlebotomy.timeslots.timeslotTooltip",
    defaultMessage: "You have selected maximum amount of timeslots",
    description: "Timeslot tooltip text",
  },
});

export const SlotChip: FC<SlotChipProps> = ({
  slot,
  onClick,
  selected,
  disabled,
  selectedSlots,
  maxSlots,
  mobile,
  onKeyDown,
  focus,
  oneSlotPerDay,
}) => {
  const ref = React.useRef<HTMLDivElement>(null);
  const isMounted = React.useRef(false);
  const { renderSlotChipTime } = useTimeslots();

  React.useEffect(() => {
    if (isMounted.current) {
      if (focus) {
        ref.current?.focus();
      }
    } else {
      isMounted.current = true;
    }
  }, [focus]);

  return (
    <Tooltip
      position={{ xPos: 0, yPos: 1 }}
      className={cn("mobile-phlebotomy__timeslots__tooltip", {
        "mobile-phlebotomy__timeslots__tooltip-hidden":
          selectedSlots.length < maxSlots,
      })}
      content={<FormattedMessage {...messages.timeslotTooltip} />}
    >
      <div>
        <Chip
          ref={ref}
          role={oneSlotPerDay ? "radio" : "checkbox"}
          aria-checked={selected}
          tabIndex={focus ? 0 : -1}
          onClick={onClick}
          onKeyDown={onKeyDown}
          className={cn("mobile-phlebotomy__timeslots__slot", {
            "mobile-phlebotomy__timeslots__slot--mobile": mobile,
            "mobile-phlebotomy__timeslots__selected-slot": selected,
            "mobile-phlebotomy__timeslots__disabled-slot":
              disabled && !selected,
          })}
        >
          {renderSlotChipTime ? (
            renderSlotChipTime(slot)
          ) : (
            <SlotChipTime slot={slot} />
          )}
        </Chip>
      </div>
    </Tooltip>
  );
};
