import * as React from "react";
import classnames from "classnames";
import { IconProps, IconRenderer } from "@natera/material/lib/icon";

interface Props extends React.HTMLProps<HTMLDivElement> {
  leadIcon?: string;
  materialLeadIcon?: string | IconProps;
}

export const SearchbarInfoItem: React.FunctionComponent<Props> = ({
  children,
  className,
  leadIcon,
  materialLeadIcon,
  ...props
}) => {
  return (
    <div
      className={classnames(className, "searchbar-item__info-item")}
      {...props}
    >
      {(leadIcon || materialLeadIcon) && (
        <div className="searchbar-item__info-item--icon">
          <IconRenderer icon={leadIcon} materialIcon={materialLeadIcon} />
        </div>
      )}
      <div className="searchbar-item__info-item--text">{children}</div>
    </div>
  );
};

export default SearchbarInfoItem;
