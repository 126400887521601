"use client";

import * as React from "react";
import classnames from "classnames";
import { getTypeAheadContext } from "@natera/form";
import { MenuItem, MenuItemProps } from "@natera/material/lib/menu";

export interface SearchBarOptionProps extends MenuItemProps {
  optionValue: object;
  recent?: boolean;
}

export const SearchbarOption: React.FunctionComponent<SearchBarOptionProps> = ({
  className,
  optionValue,
  children,
  disabled,
  recent,
  ...props
}) => {
  const typeahead = React.useContext(getTypeAheadContext());

  const itemSelectHandler = () => {
    typeahead.selectOption(optionValue);
  };

  return (
    <MenuItem
      onItemSelect={itemSelectHandler}
      disabled={disabled}
      className={classnames(className, "searchbar-item", {
        "searchbar-item--disabled": disabled,
        "searchbar-item--recent": recent,
      })}
      aria-hidden={false}
      {...props}
    >
      {children}
    </MenuItem>
  );
};
