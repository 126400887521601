import * as R from "ramda";
import { Observable, Subject } from "rxjs";
import { filter, map } from "rxjs/operators";

type Config = {
  [P in string]?: string;
};

type DeepMapConfig = {
  [P in string]?: DeepMapConfig | Config;
};

export type GetConfig = (...names: string[]) => ConfigSetterInterface;
export interface RuntimeConfigurationServiceInterface {
  getConfig: GetConfig;
}

interface ConfigData {
  names: string[];
  value: string | undefined;
}
interface ChangedConfigData {
  name: string;
  value: string | undefined;
}

type Getter = () => Config;
type SetValue = (name: string, value: string | undefined) => void;
type GetValue = (name: string) => string | undefined;
type AsObservable<T> = () => Observable<T>;
export interface ConfigSetterInterface {
  get: Getter;
  setValue: SetValue;
  getValue: GetValue;
  asObservable: AsObservable<ChangedConfigData>;
}

type IsObject = (object: DeepMapConfig) => boolean;
type FilterOutNonString = (object: object) => Config;

const isObject: IsObject = R.both(R.is(Object), R.compose(R.not, R.is(Array)));
const filterOutNonString: FilterOutNonString = R.filter(R.is(String));

export default class RuntimeConfigurationService
  implements RuntimeConfigurationServiceInterface
{
  private subject: Subject<ConfigData> = new Subject();
  private configData: DeepMapConfig = {};

  public getConfig: GetConfig = (...names) => {
    return {
      get: () => this.getConfigData(...names),
      setValue: (name, value) => this.setConfigData(value, [...names, name]),
      getValue: (name) => this.getConfigData(...names)[name],
      asObservable: () =>
        this.subject.pipe(
          filter(({ names: names$ }) => R.equals(R.dropLast(1, names$), names)),
          map(({ names: names$, value }) => ({
            name: R.last(names$) || "",
            value,
          })),
        ),
    };
  };

  private getConfigData = (...names: string[]): Config => {
    const data = R.path<DeepMapConfig>(names, this.configData);
    return data && isObject(data) ? filterOutNonString(data) : {};
  };

  private setConfigData = (value: string | undefined, names: string[]) => {
    const oldValue = R.path<string | undefined>(names, this.configData);

    if (oldValue === value) {
      return;
    }

    this.configData =
      value === undefined || R.isEmpty(value)
        ? R.dissocPath(names, this.configData)
        : R.assocPath(names, value, this.configData);

    this.subject.next({ names, value });
  };
}
