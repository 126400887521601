"use client";

import {
  MaterialSelect,
  MaterialSelectProps,
} from "@natera/material/lib/select";
import { FormContext, DisplayField, FormFieldContext } from "@natera/form";
import classnames from "classnames";
import * as R from "ramda";
import * as React from "react";

import "./select.scss";
import { SelectValueType } from "@natera/material/lib/select/select";

export const enum DropdownWidth {
  SAME_WIDTH = "SAME_WIDTH",
  FLOATING_WIDTH = "FLOATING_WIDTH",
}

export interface SelectProps extends MaterialSelectProps {
  isEditable?: boolean;
  dropdownWidth?: DropdownWidth;
}

interface SelectController<T> {
  getValue: () => T | undefined;
  isEditable: () => boolean;
}

export const SelectContext = React.createContext<SelectController<unknown>>({
  getValue: () => undefined,
  isEditable: () => true,
});

export const Select: React.FunctionComponent<SelectProps> = ({
  disabled,
  required,
  className,
  isEditable,
  onValueChange = R.always(undefined),
  dropdownWidth = DropdownWidth.FLOATING_WIDTH,
  ...props
}) => {
  const { setFormChanged } = React.useContext(FormContext);
  const formFieldContext = React.useContext(FormFieldContext);
  const selectRef = React.useRef<HTMLDivElement>(null);
  const [surfaceStyle, setSurfaceStyle] = React.useState<React.CSSProperties>(
    {},
  );

  React.useEffect(() => {
    if (dropdownWidth === DropdownWidth.SAME_WIDTH) {
      setSurfaceStyle({
        width: selectRef.current
          ? `${selectRef.current.offsetWidth}px`
          : undefined,
      });
    }
  }, [dropdownWidth, selectRef]);

  const isDisabled =
    disabled || formFieldContext.isDisabled() || !formFieldContext.isEditable();
  const isRequired = required || formFieldContext.isRequired();
  const isEditable$ = R.isNil(isEditable)
    ? formFieldContext.isEditable()
    : isEditable;

  const displayFieldController = React.useMemo(
    () => ({
      getValue: () => props.defaultValue || props.value,
      isEditable: () => isEditable$,
    }),
    [isEditable$],
  );

  if (!isEditable$) {
    if (R.isNil(displayFieldController.getValue())) {
      return <DisplayField value={<>&mdash;</>} />;
    }
    return (
      <SelectContext.Provider value={displayFieldController}>
        {props.children}
      </SelectContext.Provider>
    );
  }

  const onValueChange$: typeof onValueChange = (value: SelectValueType) => {
    onValueChange(value);
    setFormChanged();
  };

  return (
    <SelectContext.Provider value={displayFieldController}>
      <MaterialSelect
        {...props}
        ref={selectRef}
        surfaceStyle={surfaceStyle}
        onValueChange={onValueChange$}
        className={classnames(className, "mdc-form-field")}
        disabled={isDisabled}
        required={isRequired}
      />
    </SelectContext.Provider>
  );
};

export default Select;
