"use client";

import classnames from "classnames";
import { MenuController } from "@natera/material/lib/menu/controller";
import { IconProps } from "@natera/material/lib/icon";
import React, { ChangeEvent } from "react";
import {
  TypeAheadContextProps,
  TypeAheadController,
  TypeAheadInput,
  TypeAheadInputProps,
  TypeAheadMenu,
  TypeAheadProvider,
  getTypeAheadContext,
} from "../typeAhead";
import SearchbarFilters from "./searchbarFilters";
import {
  SearchbarResultList,
  SearchbarResultListProps,
} from "./searchbarResultList";

type ResultListProps<T> = Pick<
  SearchbarResultListProps<T>,
  "notFoundText" | "renderResult" | "renderRecent"
>;
type InputProps = Omit<TypeAheadInputProps, "onSelect" | "children">;
type MenuProps = {
  menuClassName?: string;
};

export interface SearchbarUIProps<T extends object>
  extends ResultListProps<T>,
    TypeAheadContextProps<T>,
    InputProps,
    MenuProps {
  inputRef: React.RefObject<HTMLInputElement>;
  anchorRef: React.RefObject<HTMLDivElement>;
  menuRef: React.RefObject<HTMLDivElement>;
  filters: React.ReactNode;
  rounded: boolean;
  minQuery: number;
  isInputFocused: boolean;
  menuCloseHandler: (
    menu: MenuController,
  ) => (event: React.FocusEvent<HTMLInputElement, Element>) => void;
  menuOpenHandler: (
    typeahead: TypeAheadController<T>,
    menu: MenuController,
  ) => () => void;
  onMenuFocus: (menu: MenuController) => () => void;
  changeHandler: (
    menu: MenuController,
  ) => (event: ChangeEvent<HTMLInputElement>) => void;
  maxResults?: number;
  floating?: boolean;
  fixed?: boolean;
  onMenuClose: () => void;
  materialExclamationIcon?: string | IconProps;
  materialInfoIcon?: string | IconProps;
  plain?: boolean;
}

const SearchbarUI = <T extends object>({
  id,
  className,
  selectedOption,
  displayValue,
  getOptions,
  delayTime,
  selectOnFocus,
  onSelect,
  clearOnSelect,
  autoSelect,
  menuClassName,
  notFoundText,
  filters,
  renderResult,
  renderRecent,
  menuCloseHandler,
  menuOpenHandler,
  onMenuFocus,
  changeHandler,
  disabled,
  isInputFocused,
  allowType,
  rounded,
  filled,
  plain,
  dense,
  maxResults,
  anchorRef,
  inputRef,
  menuRef,
  minQuery,
  floating,
  fixed,
  onMenuClose,
  materialExclamationIcon,
  materialInfoIcon,
  ...textfiledProps
}: SearchbarUIProps<T>) => {
  const TypeaheadContext = getTypeAheadContext<T>();

  const anchorElement = (menu: MenuController) => (
    <div
      ref={anchorRef}
      className={classnames(className, "searchbar", {
        "searchbar--allow-type": allowType,
        "searchbar--rounded": rounded,
        "searchbar--outlined": !filled && !plain,
        "searchbar--filled": filled,
        "searchbar--plain": plain,
        "searchbar--dense": dense,
      })}
    >
      <TypeaheadContext.Consumer>
        {(typeahead) => (
          <TypeAheadInput
            ref={inputRef}
            id={id}
            disabled={disabled}
            outline={!filled && !plain}
            allowType={allowType}
            onClear={menu.closeMenu}
            onClick={menuOpenHandler(typeahead, menu)}
            onFocus={onMenuFocus(menu)}
            onChange={changeHandler(menu)}
            onBlur={menuCloseHandler(menu)}
            isFocused={isInputFocused}
            selectable={false}
            minQuery={minQuery}
            {...textfiledProps}
          />
        )}
      </TypeaheadContext.Consumer>
    </div>
  );

  return (
    <TypeAheadProvider
      selectedOption={selectedOption}
      getOptions={getOptions}
      delayTime={delayTime}
      minQuery={minQuery}
      selectOnFocus={selectOnFocus}
      clearOnSelect={clearOnSelect}
      onSelect={onSelect}
      displayValue={displayValue}
      autoSelect={autoSelect}
    >
      <TypeAheadMenu
        ref={menuRef}
        floating={floating}
        fixed={fixed}
        menuButtonRef={inputRef}
        className={classnames("searchbar-menu", menuClassName, {
          "searchbar-menu--rounded": rounded,
        })}
        anchorClassName="searchbar-surface--anchor"
        anchorElement={anchorElement}
        onMenuClose={onMenuClose}
      >
        {filters && <SearchbarFilters>{filters}</SearchbarFilters>}
        <SearchbarResultList
          renderResult={renderResult}
          renderRecent={renderRecent}
          notFoundText={notFoundText}
          minQuery={minQuery}
          maxResults={maxResults}
          materialExclamationIcon={materialExclamationIcon}
          materialInfoIcon={materialInfoIcon}
        />
      </TypeAheadMenu>
    </TypeAheadProvider>
  );
};

export default SearchbarUI;
