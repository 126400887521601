"use client";

import * as React from "react";
import classnames from "classnames";
import { IconProps, IconRenderer } from "@natera/material/lib/icon";
import { MDCRipple } from "@material/ripple";

import "./segmentedButton.scss";

export interface SegmentProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  className?: string;
  singleSelect?: boolean;
  children?: React.ReactNode;
  checked?: boolean;
  icon?: string;
  materialIcon?: string | IconProps;
  ripple?: boolean;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
}

export interface SegmentedButtonProps extends React.HTMLProps<HTMLDivElement> {
  className?: string;
  singleSelect?: boolean;
  children: React.ReactNode;
}

const SegmentedButtonContex = React.createContext<{ disabled?: boolean }>({
  disabled: undefined,
});

export const Segment = React.forwardRef<HTMLButtonElement, SegmentProps>(
  (
    {
      className,
      singleSelect,
      children,
      checked,
      icon,
      ripple = true,
      materialIcon,
      onClick = () => void 0,
      ...props
    },
    ref,
  ) => {
    const { disabled } = React.useContext(SegmentedButtonContex);

    const buttonRef = React.useRef<HTMLButtonElement | null>(null);

    const createRef = React.useCallback(
      (element: HTMLButtonElement | null) => {
        buttonRef.current = element;
        if (ref) {
          if (typeof ref === "function") {
            ref(element);
          } else {
            (ref as React.MutableRefObject<HTMLButtonElement | null>).current =
              element;
          }
        }
      },
      [ref],
    );

    React.useEffect(() => {
      if (ripple && buttonRef.current) {
        const rippleInstance = new MDCRipple(buttonRef.current);
        return () => {
          rippleInstance.destroy();
        };
      }
    }, [ripple, checked]);

    const handleClick: React.MouseEventHandler<HTMLButtonElement> = (event) => {
      event.currentTarget.blur();

      if (onClick) {
        onClick(event);
      }
    };

    return (
      <button
        {...props}
        ref={createRef}
        onClick={handleClick}
        role={singleSelect ? "radio" : undefined}
        aria-pressed={singleSelect ? undefined : checked}
        aria-checked={singleSelect ? checked : undefined}
        className={classnames(className, "mdc-segmented-button__segment", {
          "mdc-segmented-button__segment--selected": checked,
          "mdc-segmented-button__segment--withIcon": icon || materialIcon,
          "mdc-segmented-button__segment--withLabel": children,
        })}
        disabled={props.disabled || disabled}
      >
        <div className="mdc-segmented-button__ripple"></div>
        <IconRenderer
          icon={icon}
          materialIcon={materialIcon}
          className="mdc-segmented-button__icon segmented-button__icon"
        />
        {children && (
          <div className="mdc-segmented-button__label">{children}</div>
        )}
      </button>
    );
  },
);

export const SegmentedButton: React.FC<SegmentedButtonProps> = ({
  className,
  singleSelect,
  children,
  disabled,
  ...rest
}) => {
  const controller = React.useMemo(() => ({ disabled }), [disabled]);

  return (
    <div
      {...rest}
      className={classnames(className, "mdc-segmented-button", {
        "mdc-segmented-button--single-select": singleSelect,
        "mdc-segmented-button--disabled": disabled,
      })}
      role={singleSelect ? "radiogroup" : "group"}
    >
      <SegmentedButtonContex.Provider value={controller}>
        {children}
      </SegmentedButtonContex.Provider>
    </div>
  );
};

export default SegmentedButton;
