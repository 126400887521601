"use client";

import { MenuController } from "@natera/platform/lib/components/menu";
import * as R from "ramda";
import * as React from "react";
import {
  getTypeAheadContext,
  TypeAheadMenu,
  TypeAheadMenuProps,
  getMultiSelectContext,
  MultiSelectController,
} from "@natera/form";

export interface MultiSelectMenuProps<T extends object>
  extends Omit<TypeAheadMenuProps, "anchorElement"> {
  children?: React.ReactNode;
  disableAutoOpen?: boolean;
  anchorElement?: (
    menu: MenuController,
    multiselect: MultiSelectController<T>,
  ) => React.ReactNode;
}

export const MultiSelectMenu = React.forwardRef(
  <T extends object>(
    {
      children,
      onMenuOpen = R.always(undefined),
      disableAutoOpen = true,
      anchorElement,
      ...props
    }: MultiSelectMenuProps<T>,
    ref: React.MutableRefObject<HTMLDivElement>,
  ) => {
    const typeahead = React.useContext(getTypeAheadContext<T>());
    const multiselect = React.useContext(getMultiSelectContext<T>());

    const onMenuOpenHandler = React.useCallback(() => {
      typeahead.reset();
      multiselect.reset();
      onMenuOpen();
    }, [typeahead, onMenuOpen]);

    const getAnchorElement = (menu: MenuController) =>
      anchorElement && anchorElement(menu, multiselect);

    const onKeydownHandlerMenu = (e: React.KeyboardEvent) => {
      if (e.key === "Tab") {
        e.stopPropagation();
      }
    };

    return (
      <TypeAheadMenu
        ref={ref}
        onMenuOpen={onMenuOpenHandler}
        anchorElement={getAnchorElement}
        onKeyDown={onKeydownHandlerMenu}
        disableAutoOpen={disableAutoOpen}
        {...props}
      >
        {children}
      </TypeAheadMenu>
    );
  },
);
