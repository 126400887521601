import HttpError from "./http";

export interface ValidationError {
  name: string;
  message?: string;
  fields?: ValidationError[];
}

export interface ValidationResponse {
  message: string;
  fields: ValidationError[];
}

class HttpValidationError extends HttpError {
  constructor(
    readonly message: string,
    readonly code: number,
    // eslint-disable-next-line
    readonly body: any,
  ) {
    super(message, code, body);
    // Set the prototype explicitly.
    Object.setPrototypeOf(this, HttpValidationError.prototype);
  }
}

export default HttpValidationError;
