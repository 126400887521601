"use client";

import {
  Checkbox as MaterialCheckbox,
  CheckboxProps,
} from "@natera/material/lib/checkbox";
import * as R from "ramda";
import * as React from "react";
import { v4 as uuidv4 } from "uuid";
import { CheckboxGroupContext, FormFieldContext } from "@natera/form";

export const Checkbox: React.FunctionComponent<CheckboxProps> = ({
  id,
  disabled,
  required,
  name,
  checked,
  onChange,
  ...props
}) => {
  const { isEditable, isDisabled, isRequired } =
    React.useContext(FormFieldContext);
  const checkboxId = React.useMemo(() => {
    return id || uuidv4();
  }, [id]);
  const groupContext = React.useContext(CheckboxGroupContext);

  const disabled$ = disabled || isDisabled() || !isEditable();
  const required$ = R.isNil(required) ? isRequired() : required;

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (onChange) {
      onChange(e);
    }
    if (groupContext.changeHandler) {
      groupContext.changeHandler(e);
    }
  };

  const name$ = groupContext.hasGroup ? name || groupContext.name : name;
  const checked$ = groupContext.hasGroup
    ? checked ||
      (props.value
        ? groupContext.value?.includes(props.value.toString())
        : false)
    : checked;

  return (
    <MaterialCheckbox
      id={checkboxId}
      disabled={disabled$}
      required={required$}
      onChange={handleOnChange}
      name={name$}
      checked={checked$}
      {...props}
    />
  );
};
