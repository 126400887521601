import * as R from "ramda";
import * as React from "react";

export type Page = number;
export type Limit = number;

type SetPage = (page: Page) => void;
type SetRecords<T> = (page: Page, records: T[]) => void;
type SetTotal = (total: number) => void;
type NextPage = () => void;

type GetPage = () => Page;
type GetLimit = () => Limit;
type GetRecords<T> = () => T[];
type GetTotal = () => number;
type Reset = () => void;
type HasNextPage = () => boolean;
type GetPaginator = () => Paginator;

export interface Paginator {
  nextPage: NextPage;
  getLimit: GetLimit;
  getPage: GetPage;
  getTotal: GetTotal;
  hasNextPage: HasNextPage;
  setPage: SetPage;
  reset: Reset;
}

export interface PaginationController<T> extends Paginator {
  setRecords: SetRecords<T>;
  getRecords: GetRecords<T>;
  setTotal: SetTotal;
  getPaginator: GetPaginator;
}

interface Props<T> {
  defaultPage?: Page;
  defaultLimit?: Limit;
  initialRecords?: T[];
}

export const usePagination = <T>({
  defaultPage = 0,
  defaultLimit = 100,
  initialRecords = [],
}: Props<T>): PaginationController<T> => {
  const [page$, setPage$] = React.useState<Page>(defaultPage);
  const [total$, setTotal$] = React.useState<number>(Infinity);

  const initial = React.useMemo(() => initialRecords, [initialRecords]);

  // TODO: replace it with the actual total later on
  const [maxPage$, setMaxPage$] = React.useState<number>(Infinity);
  const [records$, setRecords$] = React.useState<Record<Page, T[]>>({
    [defaultPage]: initial,
  });

  const setPage: SetPage = (page) => setPage$(page);

  const getPage: GetPage = React.useCallback(() => page$, [page$]);

  const getLimit: GetLimit = React.useCallback(() => defaultLimit, []);

  const nextPage: NextPage = () => {
    if (hasNextPage()) {
      setPage$(R.add(1));
    }
  };

  const hasNextPage: HasNextPage = React.useCallback(
    () => page$ < maxPage$,
    [page$, maxPage$],
  );

  const setRecords: SetRecords<T> = (page, records) => {
    if (!records.length) {
      setMaxPage$(page - 1);
    }

    setRecords$((state) => ({
      ...state,
      [page]: records,
    }));
  };

  const getRecords: GetRecords<T> = React.useCallback(
    () => R.unnest(R.values(records$)),
    [records$],
  );

  const reset: Reset = () => {
    setMaxPage$(Infinity);
    setPage$(defaultPage);
    setRecords$({
      [defaultPage]: initialRecords,
    });
    setTotal$(Infinity);
  };

  const setTotal: SetTotal = (total) => setTotal$(total);
  const getTotal: GetTotal = React.useCallback(() => total$, [total$]);

  const paginator: Paginator = React.useMemo(
    () => ({
      nextPage,
      getLimit,
      getPage,
      getTotal,
      hasNextPage,
      setPage,
      reset,
    }),
    [getLimit, getPage, getTotal, hasNextPage],
  );

  return React.useMemo<PaginationController<T>>(
    () => ({
      reset,
      setPage,
      setRecords,
      nextPage,
      getPage,
      getLimit,
      getRecords,
      hasNextPage,
      setTotal,
      getTotal,
      getPaginator: R.always(paginator),
    }),
    [paginator, getRecords],
  );
};
