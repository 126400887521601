import classnames from "classnames";
import * as React from "react";

export interface DisplayFieldProps
  extends Omit<React.HTMLProps<HTMLDivElement>, "value" | "action"> {
  className?: string;
  value: React.ReactNode;
  title?: string;
}

export const DisplayField: React.FunctionComponent<DisplayFieldProps> = ({
  className,
  value,
  title,
  ...rest
}) => {
  return value ? (
    // TODO: add singleline/multiline behaviour with ellipsis/clipping
    <div
      {...rest}
      className={classnames(className, "display-field")}
      title={title}
    >
      {value}
    </div>
  ) : null;
};
