"use client";

import React from "react";
import classnames from "classnames";
import { v4 as uuidv4 } from "uuid";
import { ClientPortal } from "@natera/material/lib/portal";
import { TooltipComponent } from "./tooltipComponent";
import { TooltipContent } from "./tooltipContent";
import { TooltipProps } from "./types";

import "./tooltip.scss";

export const Tooltip: React.FC<TooltipProps> = ({
  id,
  title,
  content,
  actions,
  children,
  position = { xPos: 0, yPos: 0 },
  opened = false,
  className,
  timeOut,
  container,
  rich,
  persistent,
  inverse,
  showCloseIcon,
  materialCloseIcon,
  hideOnScroll = true,
  onClose,
  ...props
}) => {
  const tooltipRef = React.useRef<HTMLDivElement>();
  const childRef = React.useRef<HTMLElement | null>(null);
  const tooltipComponentRef = React.useRef<TooltipComponent>();
  const [tooltipId, setTooltipId] = React.useState<string>();
  const [tooltipContent, setTooltipContent] =
    React.useState<React.ReactNode>(content);
  const [prevWidth, setPrevWidth] = React.useState<number>(0);
  const [initialized, setInitialized] = React.useState<boolean>(false);
  const [shouldShowTooltip, setShouldShowTooltip] = React.useState(false);
  const [initialScrollTop, setInitialScrollTop] = React.useState<number | null>(
    null,
  );

  React.useEffect(() => {
    setTooltipId(id || uuidv4());
  }, [id]);

  React.useEffect(() => {
    setTooltipContent(content);
  }, [content]);

  const destroyTooltipComponent = React.useCallback(() => {
    tooltipComponentRef.current?.removeScrollHandler((event, handler) =>
      container?.removeEventListener(event, handler),
    );
    tooltipComponentRef.current?.destroy();
    tooltipComponentRef.current = undefined;
  }, []);

  const initTooltipComponent = React.useCallback(() => {
    if (!tooltipRef.current) {
      return;
    }

    destroyTooltipComponent();

    const tooltipComponent = new TooltipComponent(tooltipRef.current);
    tooltipComponentRef.current = tooltipComponent;

    tooltipComponent.setTooltipPosition(position);

    if (timeOut) {
      tooltipComponent.setHideDelay(timeOut);
    }

    if (opened) {
      tooltipComponent.show();
    }

    if (container) {
      tooltipComponent.attachScrollHandler((event, handler) =>
        container.addEventListener(event, handler),
      );
    }

    if (onClose && persistent && rich) {
      tooltipComponent.setOnClose(onClose);
    }

    const handleClickOutside = (event: MouseEvent) => {
      if (
        tooltipRef.current &&
        !tooltipRef.current.contains(event.target as Node)
      ) {
        if (tooltipComponent.isShown()) {
          if (persistent && rich) {
            setTooltipContent(null);
            close();
          }
        }
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [rich, persistent, container]);

  React.useEffect(() => {
    if (timeOut) {
      tooltipComponentRef.current?.setHideDelay(timeOut);
    }
  }, [timeOut]);

  React.useEffect(() => {
    tooltipComponentRef.current?.setTooltipPosition(position);
    recalculateTooltipPosition();
  }, [JSON.stringify(position)]);

  React.useEffect(() => {
    initTooltipComponent();

    return () => {
      destroyTooltipComponent();
    };
  }, [rich, persistent, container]);

  React.useEffect(() => {
    recalculateTooltipPosition();
  }, [tooltipContent]);

  const close = () => {
    tooltipComponentRef.current?.hide();
    setPrevWidth(0);
    setInitialized(false);
    setShouldShowTooltip(false);
  };

  const recalculateTooltipPosition = () => {
    const tooltipRect = tooltipRef.current?.getBoundingClientRect();

    if (
      position.xPos === 0 &&
      tooltipRect &&
      (tooltipRect.left + tooltipRect.width > document.body.clientWidth ||
        tooltipRect.right - tooltipRect.width < 0)
    ) {
      tooltipComponentRef.current?.reopen();
    }

    if (
      position.yPos === 0 &&
      tooltipRect &&
      (tooltipRect.top + tooltipRect.height > document.body.clientHeight ||
        tooltipRect.bottom - tooltipRect.height < 0)
    ) {
      tooltipComponentRef.current?.reopen();
    }
  };

  React.useEffect(() => {
    const currentWidth = tooltipRef.current?.getBoundingClientRect().width || 0;

    if (currentWidth > 0) {
      if (!initialized) {
        setInitialized(true);
      } else if (currentWidth !== prevWidth) {
        if (tooltipComponentRef.current?.isShown()) {
          tooltipComponentRef.current?.reopen();
          setInitialScrollTop(container?.scrollTop || 0);
        }
      }

      setPrevWidth(currentWidth);
    }
  }, [
    tooltipContent,
    tooltipRef.current?.getBoundingClientRect().width,
    container,
  ]);

  const createTooltipRef = React.useCallback(
    (element: HTMLDivElement) => {
      tooltipRef.current = element;
      if (children.ref) {
        if (children.ref instanceof Function) {
          children.ref(element);
        } else {
          children.ref.current = element;
        }
      }

      initTooltipComponent();
      recalculateTooltipPosition();
    },
    [rich, container],
  );

  React.useEffect(() => {
    const observedElement = childRef.current;

    if (!observedElement || !container) {
      return;
    }

    const observer = new IntersectionObserver(
      (entries) => {
        const entry = entries[0];
        const isElementVisible = entry.isIntersecting;

        if (isElementVisible === false) {
          tooltipComponentRef.current?.hide();
        }
      },
      {
        root: container || null,
        threshold: 0.1,
      },
    );

    observer.observe(observedElement);

    return () => {
      observer.disconnect();
    };
  }, [container]);

  const handleChildClick = React.useCallback(() => {
    if (!tooltipContent) {
      setTooltipContent(content);
    }
    setInitialScrollTop(container?.scrollTop || 0);
    setShouldShowTooltip(true);
  }, [tooltipContent, content, container]);

  React.useEffect(() => {
    if (shouldShowTooltip && tooltipContent) {
      tooltipComponentRef.current?.show();
    }
  }, [shouldShowTooltip, tooltipContent]);

  React.useEffect(() => {
    if (!persistent || !rich) {
      return;
    }

    const childElement = childRef.current;

    if (childElement) {
      childElement.addEventListener("click", handleChildClick);
    }

    return () => {
      if (childElement) {
        childElement.removeEventListener("click", handleChildClick);
      }
    };
  }, [persistent, rich, handleChildClick]);

  React.useEffect(() => {
    if (!hideOnScroll || !container) return;

    const handleScroll = () => {
      if (tooltipComponentRef.current?.isShown()) {
        const currentScrollTop = container.scrollTop || 0;

        if (initialScrollTop === null) return;

        const scrollDistance = Math.abs(currentScrollTop - initialScrollTop);

        if (scrollDistance >= 110) {
          close();
          setTooltipContent(null);
          setInitialScrollTop(null);
        }
      }
    };

    container.addEventListener("scroll", handleScroll);

    return () => container.removeEventListener("scroll", handleScroll);
  }, [hideOnScroll, container, initialScrollTop]);

  return (
    <>
      <children.type
        {...children.props}
        aria-describedby={tooltipId}
        className={classnames(children.props.className)}
        aria-haspopup="dialog"
        ref={childRef}
      />

      <ClientPortal container={container}>
        {tooltipContent && (
          <TooltipContent
            {...props}
            id={tooltipId}
            ref={createTooltipRef}
            className={className}
            rich={rich}
            persistent={persistent}
            inverse={inverse}
            showCloseIcon={showCloseIcon}
            materialCloseIcon={materialCloseIcon}
            close={close}
            title={title}
            content={tooltipContent}
            actions={actions}
          />
        )}
      </ClientPortal>
    </>
  );
};

Tooltip.displayName = "Tooltip";

export default Tooltip;
