import parsePhoneNumber, { CountryCode } from "libphonenumber-js";
import * as React from "react";

const DEFAULT_COUNTRY = "US";

type ValidatePhone = (phone?: string, country?: CountryCode) => boolean;

export interface PhoneValidation {
  isValid: boolean;
  validatePhone: ValidatePhone;
}

export const usePhoneValidation = (phoneNumber?: string): PhoneValidation => {
  const [isValid, setIsValid] = React.useState(false);

  React.useEffect(() => {
    setIsValid(validatePhone(phoneNumber));
  }, [phoneNumber]);

  const validatePhone: ValidatePhone = React.useCallback((phone, country) => {
    if (!phone) {
      return false;
    }

    const parsedPhone = parsePhoneNumber(phone, country || DEFAULT_COUNTRY);
    return Boolean(parsedPhone && parsedPhone.isValid());
  }, []);

  const phoneValidation: PhoneValidation = React.useMemo(
    () => ({
      isValid,
      validatePhone,
    }),
    [isValid, validatePhone],
  );

  return phoneValidation;
};
