"use client";

import {
  Corner,
  Menu,
  MenuController,
  MenuItem,
} from "@natera/material/lib/menu";
import { Textfield, TextfieldProps } from "@natera/material/lib/textfield";
import React, {
  forwardRef,
  ReactElement,
  RefObject,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import InputMask from "react-input-mask";
import { FormattedTime, useIntl } from "react-intl";

const MAX_TIME_STRING_LENGTH = 6;

export interface TimePickerProps extends TextfieldProps {
  minutesStep?: number;
  from?: Date;
  to?: Date;
  timeValues?: Date[];
  timeValue?: Date;
  onTimeValueChange: (value?: Date) => void;
  floating?: boolean;
}

const getTimePickerValues = (
  minutesStep: number,
  from: Date,
  to: Date,
): Date[] => {
  const result: Date[] = [];

  for (
    let i = from.getHours() * 60 + from.getMinutes();
    i <= to.getHours() * 60 + to.getMinutes();
    i += minutesStep
  ) {
    const date = new Date();
    date.setHours(i / 60);
    date.setMinutes(i % 60);
    date.setSeconds(0);
    date.setMilliseconds(0);

    result.push(date);
  }

  return result;
};

const defaultFrom = new Date();
defaultFrom.setHours(0);
defaultFrom.setMinutes(0);

const defaultTo = new Date();
defaultTo.setHours(23);
defaultTo.setMinutes(59);

export const TimePicker = forwardRef<HTMLInputElement, TimePickerProps>(
  (
    {
      minutesStep = 30,
      timeValues,
      from = defaultFrom,
      to = defaultTo,
      timeValue,
      disabled,
      onTimeValueChange,
      floating,
      ...textFieldProps
    },
    ref: RefObject<HTMLInputElement>,
  ) => {
    const intl = useIntl();

    const textFieldRef = ref || React.useRef<HTMLInputElement>(null);
    const anchorRef = React.useRef<HTMLDivElement>(null);
    const surfaceRef = React.useRef<HTMLDivElement>(null);

    const [internalTextFieldValue, setInternalTextFieldValue] = useState("");

    const internalTimeValues = useMemo(
      () =>
        timeValues && timeValues.length > 0
          ? timeValues
          : getTimePickerValues(minutesStep, from, to),
      [minutesStep, from, to, timeValues],
    );

    useEffect(() => {
      if (timeValue) {
        setInternalTextFieldValue(
          intl.formatTime(timeValue, { hour: "2-digit", minute: "2-digit" }),
        );
      }
    }, [timeValue]);

    const handleInputMaskChange = ({
      currentTarget: { value: textFieldValue },
    }: React.ChangeEvent<HTMLInputElement>) => {
      setInternalTextFieldValue(textFieldValue.toLocaleUpperCase());
    };

    const handleTimeValueChange = (value: string) => {
      const reducedValue = value
        .replace(/_/g, "")
        .replace(":", "")
        .replace(" ", "");

      const reducedTimeValue = timeValue
        ? intl
            .formatTime(timeValue, { hour: "2-digit", minute: "2-digit" })
            .replace(":", "")
            .replace(" ", "")
        : "";

      if (
        reducedValue.length === MAX_TIME_STRING_LENGTH &&
        reducedTimeValue !== reducedValue &&
        reducedTimeValue.length <= reducedValue.length
      ) {
        const hour = Number(reducedValue.substring(0, 2));
        const minute = Number(reducedValue.substring(2, 4));
        const ampm = reducedValue.substring(4).toLocaleUpperCase();

        const date = new Date();

        if (ampm === "AM") {
          date.setHours(hour === 12 ? 0 : hour);
        } else {
          date.setHours(hour >= 12 ? hour : hour + 12);
        }

        date.setMinutes(minute);
        date.setSeconds(0);
        date.setMilliseconds(0);

        onTimeValueChange(date);
      } else {
        if (
          (reducedValue.length === 1
            ? reducedValue.replace("M", "")
            : reducedValue) !== reducedTimeValue &&
          reducedTimeValue
        ) {
          onTimeValueChange();
        }
      }
    };

    const handleInputMaskBlur = (e: React.FocusEvent<HTMLInputElement>) => {
      handleTimeValueChange(e.currentTarget.value);
    };

    const handleMenuItemSelect = useCallback(
      (menuItemValue: Date) => () => {
        onTimeValueChange(menuItemValue);
      },
      [onTimeValueChange],
    );

    const handleTextFieldKeyDown =
      (controller: MenuController) =>
      (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (textFieldProps.onKeyDown) {
          textFieldProps.onKeyDown(e);
        }

        if (e.key === "Enter") {
          handleTimeValueChange(e.currentTarget.value);
        }

        if (e.key === "Backspace" && timeValue) {
          onTimeValueChange();
        }

        if (e.key === "ArrowDown") {
          controller.focus();
        } else {
          controller.closeMenu();
        }
      };

    const handleTextFieldClear = () => {
      onTimeValueChange();

      setInternalTextFieldValue("");
    };

    const menuOpenHandler = useCallback(() => {
      if (surfaceRef.current && textFieldRef.current?.parentElement) {
        surfaceRef.current.style.width = `${textFieldRef.current?.parentElement.offsetWidth}px`;
      }
    }, []);

    const renderTextField = (controller: MenuController) => () => (
      <Textfield
        {...textFieldProps}
        disabled={disabled}
        ref={textFieldRef}
        clearable={true}
        placeholder="HH:MM TM"
        onClear={handleTextFieldClear}
        onClick={controller.toggleMenu}
        onKeyUp={handleTextFieldKeyDown(controller)}
      />
    );

    const renderMenuItem = (timePickerValue: Date): ReactElement => (
      <MenuItem
        key={timePickerValue.getTime()}
        value={timePickerValue.toString()}
        onItemSelect={handleMenuItemSelect(timePickerValue)}
      >
        <FormattedTime
          value={timePickerValue}
          hour="2-digit"
          minute="2-digit"
        />
      </MenuItem>
    );

    const renderInputMask = (controller: MenuController): ReactElement => (
      <div ref={anchorRef}>
        <InputMask
          value={internalTextFieldValue}
          disabled={disabled}
          mask="hH:mM x\M"
          formatChars={{
            h: "[0-1]",
            H: "[0-9]",
            m: "[0-5]",
            M: "[0-9]",
            x: "(a|A|p|P)",
          }}
          onChange={handleInputMaskChange}
          onBlur={handleInputMaskBlur}
        >
          {
            // @ts-ignore
            renderTextField(controller)
          }
        </InputMask>
      </div>
    );

    return (
      <Menu
        menu={internalTimeValues.map(renderMenuItem)}
        anchorRef={anchorRef}
        corner={Corner.BOTTOM_LEFT}
        ref={surfaceRef}
        onOpen={menuOpenHandler}
        autoFocus={false}
        floating={floating}
      >
        {(controller) => renderInputMask(controller)}
      </Menu>
    );
  },
);
