import * as React from "react";
import classnames from "classnames";
import { IconProps, IconRenderer } from "@natera/material/lib/icon";

import "./empty.scss";

interface Props extends React.HTMLProps<HTMLDivElement> {
  children: React.ReactNode;
  isEmpty: boolean;
  title: string;
  description?: string | React.ReactNode;
  actions?: React.ReactNode;
  icon?: string;
  materialIcon?: string | IconProps;
  dense?: boolean;
  component?: React.ElementType;
}

export const EmptyContext: React.FunctionComponent<Props> = ({
  children,
  title,
  description,
  actions,
  icon,
  materialIcon,
  dense,
  isEmpty,
  component: Component = "div",
  className,
  ...rest
}) => {
  return (
    <>
      {isEmpty ? (
        <Component
          {...rest}
          className={classnames(className, "empty-content", {
            "empty-content--dense": dense,
          })}
        >
          {(icon || materialIcon) && (
            <div className="empty-icon">
              <IconRenderer icon={icon} materialIcon={materialIcon} />
            </div>
          )}
          <h2 className="empty-title">{title}</h2>
          {description && (
            <div className="empty-description">{description}</div>
          )}
          {actions && <div className="empty-actions">{actions}</div>}
        </Component>
      ) : (
        children
      )}
    </>
  );
};

export default EmptyContext;
