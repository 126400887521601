"use client";

import React, { FC } from "react";
import { DateRange, SlotTime } from "../../definitions";
import {
  addDays,
  formatSlot,
  isDisabled,
  isSelected,
  setDayTime,
} from "../../helpers";
import { SlotChip } from "../slot-chip";
import { useTimeslots } from "../timeslots/TimeslotsContext";
import { useArrowNavigation } from "./ArrowNavigationProvider";

interface SlotChipProps {
  day: Date;
  disabledSlots: DateRange[];
  onChange: (steps: DateRange[]) => void;
  availableFrom: Date;
  slotTimes: SlotTime[];
  selectedSlots: DateRange[];
  maxSlots: number;
  oneSlotPerDay?: boolean;
  mobile?: boolean;
  index: number;
}

const SlotChips: FC<SlotChipProps> = ({
  slotTimes,
  day,
  availableFrom,
  disabledSlots,
  onChange,
  selectedSlots,
  maxSlots,
  oneSlotPerDay,
  mobile,
  index,
}) => {
  const { currentFocusWeek, currentFocusSlot } = useArrowNavigation();

  const { updateDay, focusWeek, updateWeek } = useTimeslots();

  const isAnySelected = () => {
    const selectedSlotTime = slotTimes.find((slotTime) => {
      const slot = {
        start: setDayTime(day, slotTime.start),
        end: setDayTime(day, slotTime.end),
      };
      return isSelected(selectedSlots, slot);
    });

    return Boolean(selectedSlotTime);
  };

  const preselectNextDay = (newSelectedSlots: DateRange[]) => {
    if (oneSlotPerDay && newSelectedSlots.length < maxSlots) {
      const newDay = addDays(day, +1);
      updateDay(newDay);
      if (newDay > addDays(focusWeek, 6)) {
        updateWeek(addDays(focusWeek, 7));
      }
    }
  };

  return (
    <div className="timeslots__chips">
      {slotTimes.map((slotTime, indexSlot) => {
        const slot = {
          start: setDayTime(day, slotTime.start),
          end: setDayTime(day, slotTime.end),
        };
        const disabled =
          isDisabled(
            slot,
            selectedSlots.length,
            maxSlots,
            availableFrom,
            disabledSlots,
          ) || (oneSlotPerDay ? isAnySelected() : false);
        const selected = isSelected(selectedSlots, slot);

        const handleClick = () => {
          let newSelectedSlots: DateRange[];

          if (selected) {
            newSelectedSlots = selectedSlots.filter(
              (sl) =>
                sl.start.getTime() !== slot.start.getTime() &&
                sl.end.getTime() !== slot.end.getTime(),
            );
          } else {
            if (disabled) {
              return;
            }
            newSelectedSlots = [
              ...selectedSlots,
              formatSlot(day, slotTime.start, slotTime.end),
            ];

            preselectNextDay(newSelectedSlots);
          }
          onChange(newSelectedSlots);
        };

        const onKeydownHandler = (e: React.KeyboardEvent) => {
          if (e.key === "Enter" || e.key === " " || e.key === "Spacebar") {
            handleClick();
          }
        };

        return (
          <div className="timeslots__grid-chip" key={slot.start.getTime()}>
            <SlotChip
              slot={slot}
              onClick={handleClick}
              onKeyDown={onKeydownHandler}
              selected={selected}
              disabled={disabled}
              selectedSlots={selectedSlots}
              maxSlots={maxSlots}
              focus={
                currentFocusSlot() === indexSlot && currentFocusWeek() === index
              }
              mobile={mobile}
              oneSlotPerDay={oneSlotPerDay}
            />
          </div>
        );
      })}
    </div>
  );
};

export default SlotChips;
