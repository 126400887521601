"use client";

import * as R from "ramda";
import React, {
  useEffect,
  useRef,
  useState,
  useCallback,
  ChangeEvent,
  useContext,
} from "react";
import { FormContext } from "@natera/form";
import { MenuController } from "@natera/platform/lib/components/menu";
import {
  TypeAheadContextProps,
  TypeAheadController,
  getTypeAheadContext,
} from "../typeAhead/typeAheadContext";
import { TypeAheadInputProps } from "../typeAhead";
import "../typeAhead/typeAhead.scss";
import { SearchbarResultListProps } from "./searchbarResultList";
import SearchbarUI from "./searchbarUI";
import { IconProps } from "@natera/material/lib/icon";

type ResultListProps<T> = Pick<
  SearchbarResultListProps<T>,
  "notFoundText" | "renderResult" | "renderRecent"
>;
type InputProps = Omit<TypeAheadInputProps, "onSelect" | "children">;
type MenuProps = {
  menuClassName?: string;
};

export interface SearchbarProps<T extends object>
  extends ResultListProps<T>,
    TypeAheadContextProps<T>,
    InputProps,
    MenuProps {
  searchEmptyValue?: boolean;
  openOnFocus?: boolean;
  filters?: React.ReactNode;
  rounded?: boolean;
  hintDelayTime?: number;
  minQuery?: number;
  maxResults?: number;
  floating?: boolean;
  fixed?: boolean;
  onMenuClose?: () => void;
  materialExclamationIcon?: string | IconProps;
  materialInfoIcon?: string | IconProps;
  plain?: boolean;
}

type TimeoutId = NodeJS.Timeout;

export const Searchbar = <T extends object>({
  id,
  className,
  selectedOption,
  displayValue,
  getOptions,
  onChange,
  delayTime,
  selectOnFocus,
  onSelect = R.always(undefined),
  onMenuClose,
  clearOnSelect,
  autoSelect,
  openOnFocus = true,
  menuClassName,
  notFoundText,
  filters,
  renderResult,
  renderRecent,
  disabled,
  allowType = true,
  searchEmptyValue = false,
  rounded = false,
  filled,
  plain,
  dense,
  hintDelayTime = 3000,
  maxResults,
  floating,
  fixed,
  minQuery = 2,
  materialExclamationIcon,
  materialInfoIcon,
  ...textfiledProps
}: SearchbarProps<T>): React.ReactElement => {
  const anchorRef = useRef<HTMLDivElement>(null);
  const menuRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isInputFocused, setInputFocused] = useState(false);

  const timeoutIdRef = useRef<TimeoutId | number | null>(null);
  const typeahead = useContext(getTypeAheadContext<T>());

  const { setFormChanged } = useContext(FormContext);

  useEffect(() => {
    const handleInteraction = (event: MouseEvent | KeyboardEvent) => {
      if (isInputFocused) {
        const isInteractionInside =
          anchorRef.current?.contains(event.target as Node) ||
          anchorRef.current === event.target;
        setInputFocused(isInteractionInside);
      }
    };

    document.addEventListener("mousedown", handleInteraction);
    document.addEventListener("keydown", handleInteraction);

    return () => {
      document.removeEventListener("mousedown", handleInteraction);
      document.removeEventListener("keydown", handleInteraction);
    };
  }, [isInputFocused]);

  const optionSelectHandler = useCallback(
    (option: T) => {
      if (timeoutIdRef.current) {
        clearTimeout(timeoutIdRef.current);
      }
      if (onSelect) {
        onSelect(option);
      }
      setFormChanged();
      inputRef.current?.focus();
      setInputFocused(true);
    },
    [onSelect],
  );

  const menuCloseHandler = useCallback(
    (menu: MenuController) =>
      (event: React.FocusEvent<HTMLInputElement, Element>) => {
        if (timeoutIdRef.current) {
          clearTimeout(timeoutIdRef.current);
        }

        const isFocusOutsideMenu = !menuRef.current?.contains(
          document.activeElement,
        );
        const isClickOutsideMenu = !menuRef.current?.contains(
          event.relatedTarget,
        );
        const isNextTargetMenu = event.relatedTarget?.tagName === "UL";

        if (isFocusOutsideMenu && isClickOutsideMenu && !isNextTargetMenu) {
          if (onMenuClose) {
            onMenuClose();
          }
          menu.closeMenu();
          setIsMenuOpen(false);
        }
      },
    [],
  );

  const menuOpenHandler = useCallback(
    (typeahead: TypeAheadController<T>, menu: MenuController) => () => {
      setInputFocused(true);

      const timeoutValue =
        typeahead.getInputValue().length >= minQuery ? 0 : hintDelayTime;
      if (timeoutIdRef.current) {
        clearTimeout(timeoutIdRef.current);
      }
      timeoutIdRef.current = setTimeout(() => {
        if (searchEmptyValue) {
          menu.openMenu();
        }

        if (selectOnFocus) {
          inputRef.current?.select();
        }
      }, timeoutValue);
    },
    [searchEmptyValue, selectOnFocus],
  );

  const onMenuFocus = useCallback(
    (menu: MenuController) => () => {
      setInputFocused(true);
      if (openOnFocus && !isMenuOpen) {
        const openMenu = menuOpenHandler(typeahead, menu);
        openMenu();
        setIsMenuOpen(true);
      }
    },
    [openOnFocus, isMenuOpen, typeahead],
  );

  const onMenuClose$ = () => {
    if (onMenuClose) {
      onMenuClose();
    }
    setInputFocused(true);
  };

  const changeHandler = useCallback(
    (menu: MenuController) => (event: ChangeEvent<HTMLInputElement>) => {
      onChange && onChange(event);
      if (event.target.value) {
        if (event.target.value.length < minQuery) {
          if (timeoutIdRef.current) {
            clearTimeout(timeoutIdRef.current);
          }
          timeoutIdRef.current = setTimeout(() => {
            menu.openMenu();
          }, hintDelayTime);
        } else {
          menu.openMenu();
        }
      }
    },
    [],
  );

  return (
    <SearchbarUI
      id={id}
      className={className}
      selectedOption={selectedOption}
      displayValue={displayValue}
      getOptions={getOptions}
      delayTime={delayTime}
      selectOnFocus={selectOnFocus}
      onSelect={optionSelectHandler}
      clearOnSelect={clearOnSelect}
      autoSelect={autoSelect}
      menuClassName={menuClassName}
      notFoundText={notFoundText}
      filters={filters}
      renderResult={renderResult}
      renderRecent={renderRecent}
      menuCloseHandler={menuCloseHandler}
      menuOpenHandler={menuOpenHandler}
      onMenuFocus={onMenuFocus}
      changeHandler={changeHandler}
      disabled={disabled}
      isInputFocused={isInputFocused}
      allowType={allowType}
      rounded={rounded}
      maxResults={maxResults}
      anchorRef={anchorRef}
      inputRef={inputRef}
      menuRef={menuRef}
      floating={floating}
      fixed={fixed}
      minQuery={minQuery}
      onMenuClose={onMenuClose$}
      materialExclamationIcon={materialExclamationIcon}
      materialInfoIcon={materialInfoIcon}
      filled={filled}
      plain={plain}
      dense={dense}
      {...textfiledProps}
    />
  );
};

export default Searchbar;
