import * as R from "ramda";
import * as React from "react";
import { BehaviorSubject, EMPTY, from, merge, Observable, Subject } from "rxjs";
import {
  debounceTime,
  finalize,
  map,
  scan,
  startWith,
  switchMap,
  takeUntil,
  withLatestFrom,
} from "rxjs/operators";
import { HttpCollection } from "@natera/platform/lib/service/httpCollection";
import {
  defaultErrorController,
  ErrorController,
  useErrorController,
} from "../useError/useError";
import { Index, StorageController } from "../useStorage";

type GetResource<T> = () => T | undefined;
type HasResource = () => boolean;
type MaybeResource<T> = <Q>(fallback: Q, mapper: (resource: T) => Q) => Q;
type LoadResource = () => void;
type IsLoading = () => boolean;
type Clear = () => void;
type GetErrorController = () => ErrorController;
type AsObservable<T> = () => Observable<T>;
type DelayTime = number;

interface ResourceActions {
  load: LoadResource;
  clear: Clear;
  delayTime?: DelayTime;
}

interface ResourceSelectors<T> {
  getErrorController: GetErrorController;
  isLoading: IsLoading;
  getResource: GetResource<T>;
  hasResource: HasResource;
  maybe: MaybeResource<T>;
  asObservable: AsObservable<T>;
}

export interface ResourceController<T>
  extends ResourceActions,
    ResourceSelectors<T> {}

export const defaultResourceController: ResourceController<
  HttpCollection<unknown>
> = {
  getErrorController: R.always(defaultErrorController),
  isLoading: R.always(false),
  getResource: R.always(undefined),
  hasResource: R.always(false),
  maybe: R.identity,
  load: R.always(undefined),
  clear: R.always(undefined),
  asObservable: R.always(EMPTY),
  delayTime: 75,
};

type Load<T> = () => Promise<T>;

interface Props<T> {
  load: Load<T>;
  storage?: StorageController<T>;
  delayTime?: DelayTime;
  initialValue?: T;
}

export class Action<T> {
  constructor(
    readonly reducer: (state: T | undefined) => T | undefined,
    readonly effect: React.EffectCallback,
  ) {}
}

// Resource encapsulates logic of loading, error and storage handling of date fetched from server side
export const useResource = <T>({
  load,
  storage,
  delayTime = 75,
  initialValue,
}: Props<T>): ResourceController<T> => {
  interface State {
    index: Index | undefined;
    resource: T | undefined;
  }

  const stateReader = React.useCallback(
    (resource: T): State => ({
      index: storage ? storage.recordIndex(resource) : undefined,
      resource: storage ? undefined : resource,
    }),
    [],
  );

  const inputs = React.useMemo(
    () => ({
      load: new Subject<void>(),
      clear: new Subject<void>(),
      resource: new Subject<T>(),
    }),
    [],
  );

  const props = React.useMemo(
    () => ({
      load: new BehaviorSubject<Load<T>>(load),
      storage: new BehaviorSubject<StorageController<T> | undefined>(storage),
    }),
    [],
  );

  React.useLayoutEffect(() => {
    props.load.next(load);
  }, [load]);

  React.useLayoutEffect(() => {
    props.storage.next(storage);
  }, [storage]);

  const errorController = useErrorController();
  const [isLoading$, setLoading$] = React.useState(false);
  const [state, setState] = React.useState<State | undefined>(
    initialValue && stateReader(initialValue),
  );

  // TODO: segregate this pattern in useNormalizedState
  const resource$ = React.useMemo((): T | undefined => {
    if (!state) {
      return undefined;
    }

    if (storage && state.index) {
      return storage.getRecord(state.index);
    }

    return state.resource;
  }, [state, storage?.getRecord]);

  const clear = new Action<State>(R.always(undefined), () => {
    errorController.clearErrors();
    setLoading$(false);
  });

  const startLoading = new Action<State>(R.identity, () => setLoading$(true));

  const readResource =
    (storage$: StorageController<T> | undefined) => (resource: T) =>
      new Action<State>(R.always(stateReader(resource)), () => {
        inputs.resource.next(resource);
        if (storage$) {
          storage$.addRecord(resource);
        }
      });

  React.useLayoutEffect(() => {
    const subscription = merge<Array<Action<State>>>(
      inputs.clear.pipe(map(() => clear)),
      inputs.load.pipe(
        debounceTime(delayTime),
        withLatestFrom(props.load, props.storage),
        switchMap(([, load$, storage$]) =>
          from(load$()).pipe(
            map(readResource(storage$)),
            startWith(startLoading),
            finalize(() => {
              setLoading$(false);
            }),
            takeUntil(inputs.clear),
          ),
        ),
      ),
    )
      .pipe(
        scan((resource, action) => {
          action.effect();
          return action.reducer(resource);
        }, state),
      )
      .subscribe(setState, errorController.setError);

    return () => subscription.unsubscribe();
  }, []);

  const hasError = errorController.hasError;

  const getResource: GetResource<T> = React.useCallback(() => {
    if (hasError()) {
      return undefined;
    }

    return resource$;
  }, [hasError, resource$]);

  const hasResource: HasResource = React.useCallback(
    () => !R.isNil(getResource()),
    [getResource],
  );

  const maybe: MaybeResource<T> = React.useCallback(
    (fallback, mapper) => {
      const resource = getResource();

      return R.isNil(resource) ? fallback : mapper(resource);
    },
    [getResource],
  );

  const isLoading = React.useCallback(() => isLoading$, [isLoading$]);

  const getErrorController = React.useCallback(
    () => errorController,
    [errorController],
  );

  return React.useMemo<ResourceController<T>>(
    () => ({
      asObservable: inputs.resource.asObservable.bind(inputs.resource),
      clear: inputs.clear.next.bind(inputs.clear),
      load: inputs.load.next.bind(inputs.load),
      getErrorController,
      getResource,
      hasResource,
      maybe,
      isLoading,
      delayTime,
    }),
    [isLoading, getResource, maybe],
  );
};
