"use client";

import React from "react";
import Svg from "@natera/material/lib/svg";
import { MenuController } from "@natera/material/lib/menu";

interface Props {
  trailingIcon?: React.ReactNode;
  menu: MenuController;
}

const TrailingIcon: React.FC<Props> = ({ trailingIcon, menu }) => {
  const handleClick = (
    event: React.MouseEvent<HTMLInputElement, MouseEvent>,
  ) => {
    if (!menu.isOpen()) {
      menu.openMenu();
    } else {
      menu.closeMenu();
      event.currentTarget.blur();
    }
  };

  if (!trailingIcon) return null;

  if (typeof trailingIcon === "string") {
    return (
      <Svg
        className="mdc-text-field__icon-button"
        tabIndex={0}
        role="button"
        content={trailingIcon}
        onClick={handleClick}
      />
    );
  }

  return <>{trailingIcon}</>;
};

export default TrailingIcon;
