"use client";

import { closest } from "@material/dom/ponyfill";
import {
  MDCMenuDistance,
  MDCMenuSurfaceAdapter,
  MDCMenuSurfaceFoundation,
} from "@material/menu-surface";
import { getCorrectPropertyName } from "@material/animation/util";
import { Corner } from "@material/menu-surface/constants";
export { Corner };
import { ClientPortal } from "@natera/material/lib/portal";

import classnames from "classnames";
import * as React from "react";

const getSurfaceLevel = (el: Element) => {
  const parentSurface = closest(
    el,
    `.${MDCMenuSurfaceFoundation.cssClasses.ROOT}`,
  ) as HTMLDivElement;
  return Number(parentSurface?.dataset?.level) || 0;
};

const calcSurfaceMaxWidth = (
  root?: HTMLElement | null,
  anchor?: HTMLElement | null,
): string => {
  const rootRect = root?.getBoundingClientRect();
  const anchorRect = anchor?.getBoundingClientRect();
  if (!rootRect || !anchorRect) {
    return "";
  }

  const { MARGIN_TO_EDGE } = MDCMenuSurfaceFoundation.numbers;

  const isLeftAligned = rootRect.right <= anchorRect.left + MARGIN_TO_EDGE;
  const isRightAligned = rootRect.left >= anchorRect.right - MARGIN_TO_EDGE;

  const maxWidth = isLeftAligned
    ? anchorRect.left - MARGIN_TO_EDGE
    : isRightAligned
      ? window.innerWidth - anchorRect.right - MARGIN_TO_EDGE
      : 0;

  return maxWidth > 0 ? maxWidth + "px" : "";
};

export interface MenuSurfaceController {
  maxHeight: number | undefined;
  parentLevel: number;
}

export const defaultMenuSurfaceController: MenuSurfaceController = {
  maxHeight: undefined,
  parentLevel: 0,
};

export const MenuSurfaceContext = React.createContext<MenuSurfaceController>(
  defaultMenuSurfaceController,
);

export enum MenuWidth {
  SAME_WIDTH = "SAME_WIDTH",
  FLOATING_WIDTH = "FLOATING_WIDTH",
}

export interface MenuSurfaceProps extends React.HTMLProps<HTMLDivElement> {
  className?: string;
  corner?: Corner;
  floating?: boolean;
  fixed?: boolean;
  quickOpen?: boolean;
  defaultOpen?: boolean;
  anchorRef?: React.RefObject<HTMLElement>;
  children: React.ReactNode;
  onOpen?: () => void;
  onClose?: () => void;
  surfaceFoundationRef?: React.ForwardedRef<
    MDCMenuSurfaceFoundation | undefined
  >;
  container?: HTMLElement;
  menuWidth?: MenuWidth;
}

export const MenuSurface = React.forwardRef<HTMLDivElement, MenuSurfaceProps>(
  (
    {
      children,
      anchorRef,
      className,
      corner,
      onOpen = () => undefined,
      onClose = () => undefined,
      surfaceFoundationRef,
      floating = false,
      quickOpen = false,
      defaultOpen = false,
      fixed = false,
      container,
      menuWidth,
      ...props
    },
    ref: React.MutableRefObject<HTMLDivElement>,
  ) => {
    const surfaceRef = React.useRef<HTMLDivElement>();
    const [prevFocus, setPrevFocus] = React.useState<
      HTMLElement | SVGElement | null
    >(null);
    const foundationRef = React.useRef<MDCMenuSurfaceFoundation>();
    const { parentLevel } = React.useContext(MenuSurfaceContext);

    const [maxHeight, setMaxHeight] = React.useState<number | undefined>(
      undefined,
    );

    const bodyClickHandler = React.useCallback((event: MouseEvent) => {
      if (
        event.target &&
        anchorRef?.current?.contains(event.target as Element)
      ) {
        return;
      }

      foundationRef.current?.handleBodyClick(event);
    }, []);

    const registerBodyClickListener = React.useCallback(() => {
      // add capture because the event is aborted when there is a stopPropagation in the click handler
      document.body.addEventListener("click", bodyClickHandler, true);
    }, []);

    const deregisterBodyClickListener = React.useCallback(() => {
      document.body.removeEventListener("click", bodyClickHandler, true);
    }, []);

    const surfaceOpenHandler = React.useCallback(() => {
      registerBodyClickListener();
      onOpen();
    }, [onOpen]);

    const surfaceCloseHandler = React.useCallback(() => {
      deregisterBodyClickListener();
      onClose();
    }, [onClose]);

    const surfaceKeydownHandler: React.KeyboardEventHandler<HTMLDivElement> =
      React.useCallback((event) => {
        foundationRef.current?.handleKeydown(event.nativeEvent);
      }, []);

    const adapterHandlers = React.useRef({
      surfaceOpenHandler,
      surfaceCloseHandler,
    });

    React.useEffect(() => {
      adapterHandlers.current = {
        surfaceOpenHandler,
        surfaceCloseHandler,
      };
    }, [surfaceOpenHandler, surfaceCloseHandler]);

    const createAdapter = React.useCallback(
      (root: HTMLElement) => ({
        addClass: (className$: string) => {
          root.classList.add(className$);
        },
        removeClass: (className$: string) => root.classList.remove(className$),
        hasClass: (className$: string) => root.classList.contains(className$),
        hasAnchor: () => Boolean(anchorRef?.current),
        notifyClose: () => {
          if (surfaceRef.current) {
            adapterHandlers.current.surfaceCloseHandler();
          }
        },
        notifyClosing: () => undefined,
        notifyOpen: () => adapterHandlers.current.surfaceOpenHandler(),
        isElementInContainer: (el: Element) => {
          if (root.contains(el)) {
            return true;
          }

          // check if child surface level is bigger than the current level
          return getSurfaceLevel(el) > getSurfaceLevel(root);
        },
        isRtl: () =>
          getComputedStyle(root).getPropertyValue("direction") === "rtl",
        setTransformOrigin: (origin: string) => {
          const propertyName = `${getCorrectPropertyName(
            window,
            "transform",
          )}-origin`;
          root.style.setProperty(propertyName, origin);
        },
        isFocused: () => document.activeElement === root,
        saveFocus: () => {
          setPrevFocus(
            document.activeElement as HTMLElement | SVGElement | null,
          );
        },
        restoreFocus: () => {
          if (root.contains(document.activeElement)) {
            if (prevFocus && prevFocus.focus) {
              prevFocus.focus();
            }
          }
        },
        getInnerDimensions: () => ({
          width: root.offsetWidth,
          height: root.offsetHeight,
        }),
        getAnchorDimensions: () =>
          anchorRef?.current?.getBoundingClientRect() || null,
        getWindowDimensions: () => ({
          width: window.innerWidth,
          height: window.innerHeight,
        }),
        getBodyDimensions: () => ({
          width: document.body.clientWidth,
          height: document.body.clientHeight,
        }),
        getWindowScroll: () => ({
          x: window.pageXOffset,
          y: window.pageYOffset,
        }),
        setPosition: (position: Partial<MDCMenuDistance>) => {
          root.style.left = "left" in position ? `${position.left}px` : "";
          root.style.right = "right" in position ? `${position.right}px` : "";
          root.style.top = "top" in position ? `${position.top}px` : "";
          root.style.bottom =
            "bottom" in position ? `${position.bottom}px` : "";

          // try to limit max-width of the popup window
          // not the best place to execute but only available
          root.style.maxWidth = calcSurfaceMaxWidth(root, anchorRef?.current);

          if (anchorRef?.current && menuWidth) {
            root.style[
              menuWidth === MenuWidth.FLOATING_WIDTH ? "minWidth" : "width"
            ] = `${anchorRef?.current.offsetWidth}px`;
          }
        },
        setMaxHeight: (height: string) => {
          root.style.maxHeight = height;
          setMaxHeight(parseInt(height, 10));
        },
      }),
      [],
    );

    const initFoundation = () => {
      if (!surfaceRef.current) {
        return undefined;
      }

      const adapter: MDCMenuSurfaceAdapter = createAdapter(surfaceRef.current);

      const foundation = new MDCMenuSurfaceFoundation(adapter);

      updateFoundationRef(foundation);
      foundation.init();

      foundation.setQuickOpen(quickOpen);
      foundation.setIsHoisted(floating);
      foundation.setFixedPosition(fixed);

      if (corner) {
        foundation.setAnchorCorner(corner);
      }

      if (defaultOpen) {
        foundation.open();
      }
    };

    React.useEffect(() => {
      initFoundation();

      return () => {
        deregisterBodyClickListener();
        updateFoundationRef(undefined);
      };
    }, []);

    React.useEffect(() => {
      foundationRef.current?.setIsHoisted(floating);
    }, [floating]);

    React.useEffect(() => {
      foundationRef.current?.setIsHoisted(floating);
    }, [fixed]);

    React.useEffect(() => {
      foundationRef.current?.setQuickOpen(quickOpen);
    }, [quickOpen]);

    const createSurfaceRef = React.useCallback((element: HTMLDivElement) => {
      surfaceRef.current = element;
      if (ref) {
        if (ref instanceof Function) {
          ref(element);
        } else {
          ref.current = element;
        }
      }
      initFoundation();
    }, []);

    const updateFoundationRef = React.useCallback(
      (foundation: MDCMenuSurfaceFoundation | undefined) => {
        foundationRef.current?.destroy();

        foundationRef.current = foundation;

        if (surfaceFoundationRef) {
          if (surfaceFoundationRef instanceof Function) {
            surfaceFoundationRef(foundation);
          } else {
            surfaceFoundationRef.current = foundation;
          }
        }
      },
      [],
    );

    const surface = () => (
      <MenuSurfaceContext.Consumer>
        {(context) => (
          <div
            className={classnames(
              MDCMenuSurfaceFoundation.cssClasses.ROOT,
              className,
            )}
            data-level={context.parentLevel}
            ref={createSurfaceRef}
            onKeyDown={surfaceKeydownHandler}
            {...props}
          >
            {children}
          </div>
        )}
      </MenuSurfaceContext.Consumer>
    );

    return (
      <MenuSurfaceContext.Provider
        value={{
          maxHeight,
          parentLevel: parentLevel + 1,
        }}
      >
        {floating ? (
          <ClientPortal container={container}>{surface()}</ClientPortal>
        ) : (
          surface()
        )}
      </MenuSurfaceContext.Provider>
    );
  },
);

export default MenuSurface;
