"use client";

import { IconProps } from "@natera/material/lib/icon";
import cn from "classnames";
import React, { FC } from "react";
import { DateRange, SlotTime } from "../../definitions";
import { addDays, isDisabledDay } from "../../helpers";
import { useTimeslots } from "../timeslots/TimeslotsContext";
import {
  ArrowNavigationProvider,
  ArrowNavigationContext,
  ArrowNavigationController,
} from "./ArrowNavigationProvider";
import { DayLabel } from "./day-label";
import SlotChips from "./slotChips";

interface WeekSlotsProps {
  disabledSlots: DateRange[];
  onChange: (steps: DateRange[]) => void;
  currentDate: Date;
  availableFrom: Date;
  slotTimes: SlotTime[];
  selectedSlots: DateRange[];
  maxSlots: number;
  mobile?: boolean;
  oneSlotPerDay?: boolean;
  materialCurrentDayIcon?: string | IconProps;
}

export const WeekSlots: FC<WeekSlotsProps> = ({
  currentDate,
  availableFrom,
  disabledSlots,
  onChange,
  selectedSlots,
  maxSlots,
  slotTimes,
  oneSlotPerDay,
  mobile,
  materialCurrentDayIcon,
}) => {
  const { focusDay, focusWeek } = useTimeslots();
  const weekDays = [];

  for (let i = 0; i < 7; i++) {
    weekDays.push(addDays(focusWeek, i));
  }

  const elements = weekDays.map((day, index) => {
    const dayDisabled = isDisabledDay({
      day,
      slotTimes,
      availableFrom,
      disabledSlots,
    });

    return (
      <div
        key={day.getTime()}
        className={cn("timeslots__date-row", {
          "timeslots__date-row--mobile": mobile,
        })}
      >
        <div
          className={cn("timeslots__day", { "timeslots__day--mobile": mobile })}
        >
          <DayLabel
            day={day}
            current={currentDate.getTime() === day.getTime()}
            mobile={mobile}
            disabled={dayDisabled}
            materialCurrentDayIcon={materialCurrentDayIcon}
          />
        </div>
        {!mobile && (
          <SlotChips
            index={index}
            slotTimes={slotTimes}
            day={day}
            availableFrom={availableFrom}
            disabledSlots={disabledSlots}
            onChange={onChange}
            selectedSlots={selectedSlots}
            maxSlots={maxSlots}
            oneSlotPerDay={oneSlotPerDay}
          />
        )}
      </div>
    );
  });
  return (
    <ArrowNavigationProvider
      weekDaysLength={mobile ? 1 : weekDays.length}
      slotTimesLength={slotTimes.length}
    >
      <ArrowNavigationContext.Consumer>
        {({ onKeydownHandlerArrowNavigation }: ArrowNavigationController) => (
          <div className="timeslots__grid">
            <div
              onKeyDown={!mobile ? onKeydownHandlerArrowNavigation : undefined}
              className={cn("timestots__dates", {
                "timeslots__dates--mobile": mobile,
              })}
            >
              {elements}
            </div>
            {mobile && (
              <div onKeyDown={onKeydownHandlerArrowNavigation}>
                <SlotChips
                  index={0}
                  slotTimes={slotTimes}
                  day={focusDay}
                  availableFrom={availableFrom}
                  disabledSlots={disabledSlots}
                  onChange={onChange}
                  selectedSlots={selectedSlots}
                  maxSlots={maxSlots}
                  oneSlotPerDay={oneSlotPerDay}
                  mobile={mobile}
                />
              </div>
            )}
          </div>
        )}
      </ArrowNavigationContext.Consumer>
    </ArrowNavigationProvider>
  );
};
