import classnames from "classnames";
import * as React from "react";
import { Button } from "@natera/form";

interface Props extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
  to: string;
  renderLink: (options: {
    to: string;
    className: string;
    children: React.ReactNode;
  }) => React.ReactElement;
  raised?: boolean;
  unelevated?: boolean;
  outlined?: boolean;
  icon?: string;
  disabled?: boolean;
}

// TODO: do we need this component within form?
export const LinkButton: React.FunctionComponent<Props> = ({
  to,
  children,
  raised,
  unelevated,
  outlined,
  icon,
  disabled,
  className,
  renderLink,
}) =>
  renderLink({
    to,
    className: classnames("link-button", className as string),
    children: (
      <Button
        type="button"
        {...{ raised, unelevated, outlined, icon, disabled }}
      >
        {children}
      </Button>
    ),
  });

export default LinkButton;
